import { DatePicker } from '@components/ui/DatePicker';
import { FilterProvider, useFilter } from '@components/filters/Filter.context';
import { FilterPopover } from './FilterPopover';
import { EuiPopoverTitle } from '@elastic/eui';

type Props = {
    label: string;
};

type FilterProps = {
  name: string;
  value?: [];
} & Props;

const Filter = ({label}: Props) => {
  const { value, setValue } = useFilter<string[] | null>();

  const defaultStartDate = value && value[0];
  const defaultEndDate = value && value[1];

  return <FilterPopover label={label}>
    <EuiPopoverTitle paddingSize="s">
      <DatePicker
          onDateChange={setValue}
          defaultStartDate={defaultStartDate}
          defaultEndDate={defaultEndDate}
          hideClearButton={true}
      />
    </EuiPopoverTitle>
  </FilterPopover>
}

export const FilterDatePickerPopover = ({ name, value = [], label }: FilterProps) => {
  const onChange = (values) => {
    const format = 'YYYY-MM-DDTHH:mm:ssZ';
    const start = values && values[0];
    const end = values && values[1];
    const range = [start?.format(format), end?.format(format)].filter(Boolean);
    return range.length ? range : null;
  };

  return <FilterProvider name={name} defaultValue={value} onChange={onChange}>
    <Filter label={label} />
  </FilterProvider>
};
