import { useApiUrl, useCustomMutation } from "@refinedev/core";

export const useSwitchOwner = () => {
  const apiUrl = useApiUrl();
  const mutation = useCustomMutation();

  const switchOwner = (owner_id) => mutation.mutate({
    method: 'put',
    url: `${apiUrl}/user/switch_owner`,
    values: {
      owner_id
    },
  });

  return {
    ...mutation,
    switchOwner
  };
};
