import { useInvalidate, useOne, } from '@refinedev/core';
import { notifications } from '@mantine/notifications';
import { openRequestDrawer } from '@components/ui/drawers/Requests/RequestDrawer';
import { RequestAction } from '@components/table/actions/RequestAction';
import { IconPlus } from '@tabler/icons-react';
import { useTableResource } from '@components/table/TableResource.context';
import { hashQueryKey } from '@tanstack/query-core';
import { useActionMenu } from '@components/table/actions/ActionMenu.context';
import { IVoiceMessageRecord } from '@interfaces';

type Props = {
  type: 'help' | 'refund' | 'link';
  label: string;
  disabled?: boolean;
};

export const CreateRequestAction = ({ type, label, disabled }: Props) => {
  const { closeMenu, id } = useActionMenu();
  const invalidate = useInvalidate();
  const { resource } = useTableResource();

  const { isFetching, refetch } = useOne({
    resource, id, queryOptions: {
      enabled: false,
      queryKeyHashFn: (queryKey) => hashQueryKey(queryKey.concat([id, label])), // Stops the button from spinning when the row is opened
    }
  });

  const handleClick = async () => {
    const { data } = await refetch();

    if (type === 'help' && data?.data.request?.help) {
      return errorAndInvalidate('Request already has an existing Help request.');
    } else if (type === 'refund' && data?.data.request?.refund) {
      return errorAndInvalidate('Request already has an existing Refund request.');
    } else if (type === 'link' && data?.data.request?.request_link) {
      return errorAndInvalidate('Request already has an existing Request Link.');
    }

    openRequestDrawer({
      type,
      request: data?.data.request,
      help: data?.data.request?.help,
      refund: data?.data.request?.refund,
      voiceMessage: resource === 'voice_message' ? data?.data as IVoiceMessageRecord : null,
      ...(resource !== 'voice_message' ? { [resource]: data?.data } : {}),
    });

    closeMenu();
  };

  const errorAndInvalidate = (message: string) => {
    notifications.show({ color: 'red', message });

    invalidate({ resource, invalidates: ['all'] })
  }

  return <RequestAction label={label} disabled={disabled} onClick={handleClick} isLoading={isFetching}
                        icon={<IconPlus size={18} stroke={1.5} />}
  />;
}
