import { useState } from 'react';
import { Button, Text, Tooltip, Group, TypographyStylesProvider } from '@mantine/core';
import { EuiAvatar, EuiButtonIcon, EuiComment } from '@elastic/eui';
import dayjs from 'dayjs';
import { INoteRecord } from '@interfaces';
import { useForm } from '@mantine/form';
import RichTextEditor from '@components/ui/editors/RichTextEditor';
import { useIdentity } from '@components/data/Identity.context';
import { confirmDeletionModal } from '@components/ui/modals/ConfirmDeletionModal';

interface Props {
  note: INoteRecord;
  onUpdate: (id, values) => void;
  onDelete: (id) => void;
}

export const NoteItem = ({ note, onUpdate, onDelete }: Props) => {
  const [isEditing, setIsEditing] = useState(false);
  const { identity } = useIdentity();

  const form = useForm({
    validateInputOnChange: true,
    initialValues: {
      html: note.html,
    },
    validate: {
      html: (value) => {
        return !value || value === '<p><br></p>';
      }
    }
  });

  const date = dayjs(note.created_at);
  const dateDisplay = <Tooltip label={date.format('ddd, MMM D YYYY [at] h:mm A')} openDelay={300} withArrow>
    <div>{date.format('MMM DD, YYYY')}</div>
  </Tooltip>

  const actions = <>
    <EuiButtonIcon title="Edit note"
                   aria-label="Edit"
                   color="text"
                   iconType="documentEdit"
                   onClick={() => setIsEditing(true)}
    />
    <EuiButtonIcon title="Delete note"
                   aria-label="Delete"
                   color="danger"
                   iconType="trash"
                   onClick={() => confirmDeletionModal(() => onDelete(note.id))}
    />
  </>;

  const renderEditForm = () => {
    return <form onSubmit={form.onSubmit(async (values) => {
      form.setValues(values);
      onUpdate(note.id, values);
      setIsEditing(false);
    })}>
      <RichTextEditor {...form.getInputProps('html')}
                      placeholder="Compose a note..."
                      simpleControls={true}
      />

      <Group mt="sm" gap="sm">
        <Button variant="outline" onClick={() => {
          setIsEditing(false);
          form.setValues({ html: note.html });
        }}>
          Cancel
        </Button>
        <Button type="submit" disabled={!form.isValid()}>Save</Button>
      </Group>
    </form>
  }

  return <>
    <EuiComment
      username={note.user?.name ?? 'Unknown'}
      event="added on"
      timestamp={dateDisplay}
      actions={identity.id === note.user?.id && actions}
      timelineAvatar={<EuiAvatar size="m" name={note.user?.name ?? 'Unknown'}/>}
    >
      {
        isEditing ?
          renderEditForm() :
          <TypographyStylesProvider>
            <Text size="sm" dangerouslySetInnerHTML={{ __html: form.values.html }}></Text>
          </TypographyStylesProvider>
      }
    </EuiComment>
  </>;
}
