import { IAuditRecord } from '@interfaces';
import { Grid, Stack, Text } from '@mantine/core';
import { DateTime } from '@components/ui/DateTime';
import { EuiBasicTableColumn, EuiImage } from '@elastic/eui';
import { MemoryTable } from '@components/table/MemoryTable';

interface Props {
  audits: IAuditRecord[];
}

export const AuditTable = ({ audits }: Props) => {
  if (audits.length < 1) {
    return <Text ta="center" my="sm">No logs have been created yet.</Text>;
  }

  const columns = [
    {
      field: 'old_values',
      name: 'Field',
      sortable: true,
      render: (old_values) => {
        const labels = Object.keys(old_values);
        return <Stack gap={0}>
          {labels.map((l, i) => <Text key={i}>{l.replaceAll('_', ' ')}</Text>)}
        </Stack>;
      }
    },
    {
      field: 'old_values',
      name: 'Before',
      sortable: false,
      truncateText: true,
      render: (old_values) => {
        const values = Object.values(old_values);
        return <Stack gap={0}>
          { formatAuditValues(values, 'red') }
        </Stack>;
      }
    },
    {
      field: 'new_values',
      name: 'After',
      sortable: false,
      truncateText: true,
      render: (new_values) => {
        const values = Object.values(new_values);
        return <Stack gap={0}>
          { formatAuditValues(values, 'green') }
        </Stack>;
      }
    },
    {
      field: 'user',
      name: 'By',
      sortable: true,
      truncateText: true,
      render: (user) => user?.name ?? '-',
    },
    {
      field: 'created_at',
      name: 'Created',
      sortable: true,
      width: '180',
      render: (date) => <DateTime value={date}/>
    },
  ] as EuiBasicTableColumn<IAuditRecord>[];


  const formatAuditValues = (values, color) => {
    return values.map((v: string, i) => {
      let text = v || '—';

      if (`${v}`.includes('http')) {
        return <EuiImage key={i} allowFullScreen alt="" src={v} />
      } else if (`${v}`.startsWith('{"')) {
        try {
          text = Object.entries(JSON.parse(v))
            .map(([k, v]) => `${k.replaceAll('_', ' ')}: ${v}`)
            .join('<br />');
        } catch (e) {
          text = '—';
        }
      }

      return <Text key={i} c={color} dangerouslySetInnerHTML={{ __html: text }} />
    });
  }

  return <Grid>
    <Grid.Col span={{ sm: 12, md: 10 }}>
      <MemoryTable<IAuditRecord>
        items={audits}
        columns={columns}
      />
    </Grid.Col>
  </Grid>;
}
