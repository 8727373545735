import { Badge, Box, Button, Drawer, Grid, Stack, Tooltip, useMantineTheme } from '@mantine/core';
import { IRefundListItem, ITransactionListItem, ITransactionRecord } from '@interfaces';
import { TableRow } from '@components/table/TableRow';
import { SimpleReadOnlyForm } from '@components/data/forms/SimpleReadOnlyForm';
import { CollapsableCard } from '@components/ui';
import Link from 'next/link';
import { IconExternalLink } from '@tabler/icons-react';
import { useDisclosure, useMediaQuery } from '@mantine/hooks';
import RefundTableExpandedRow from '../../../refund/table/RefundTableExpandedRow';
import { useTableExpandedRowResource } from '@components/table/TableExpandedRowResource.context';

type Props = {
  item: ITransactionListItem;
}

type TabContentProps = {
  record: ITransactionRecord;
}

const TabContent = ({ record }: TabContentProps) => {
  const theme = useMantineTheme();
  const { parent: parentResource } = useTableExpandedRowResource();
  const isMobile = useMediaQuery(`(max-width: ${theme.breakpoints.sm})`, false);
  const [opened, { open, close }] = useDisclosure(false);

  const isDeposit = record.type === 'deposit';

  const money = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });

  return <>
    <Box w="100%">
      <Grid>
        <Grid.Col span={{ lg: 6 }}>
          { isDeposit && <>
            <CollapsableCard label="Invoice" collapsable={false}>
              <Stack>
                <SimpleReadOnlyForm fields={[
                  { label: 'Invoice #', value: record.meta.invoice_number },
                ]} />

                <Stack align="flex-end">
                  <Link href={record.meta.invoice_url} target="_blank">
                    <Button rightSection={<IconExternalLink size={14} />}>View invoice</Button>
                  </Link>
                </Stack>
              </Stack>
            </CollapsableCard>
          </>}

          { !isDeposit && <>
            <CollapsableCard label="Details"
                             collapsable={false}
                             rightSection={
                               <Tooltip label="Refund reference for this transaction">
                                <Badge color="gray" variant="outline">{ record.meta.refund_reference }</Badge>
                               </Tooltip>
                            }
            >
              <Stack>
                <SimpleReadOnlyForm fields={[
                  { label: 'Amount', value: money.format(record.meta.amount) },
                  { label: 'Fee', value: record.meta.fee ? money.format(record.meta.fee) : 'N/A' },
                ]} />

                { !parentResource && <>
                  <Stack align="flex-end">
                    <Button onClick={open}>View refund</Button>
                  </Stack>
                </>}
              </Stack>
            </CollapsableCard>
          </>}
        </Grid.Col>
      </Grid>
    </Box>

    <Drawer opened={opened} onClose={close} position="right" size={ isMobile ? '100%' : '75%' }>
      <RefundTableExpandedRow item={{ id: record.payable_id } as IRefundListItem} />
    </Drawer>
  </>
}

export default ({ item }: Props) => {
  return <TableRow<ITransactionRecord> resource="transaction" id={item.id}>
    {(record) => <TabContent record={record} />}
  </TableRow>;
}
