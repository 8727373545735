import { Button, Divider, Group, PasswordInput, Stack, Switch, Card, TextInput } from '@mantine/core';
import { IIntegrationRecord, IIntegrationVoPayRecord } from '@interfaces';
import { useConnectionGuard } from './useConnectionGuard';
import { useForm, yupResolver } from '@mantine/form';
import * as Yup from 'yup';
import { ResourceForm } from '@components/ui/form/ResourceForm';
import { useResourceForm } from '@components/ui/form/ResourceForm.context';
import { useApiUrl, useCustomMutation } from '@refinedev/core';

interface Props {
  record?: IIntegrationVoPayRecord;
}

const schema = Yup.object().shape({
  credentials: Yup.object().required().shape({
    account_id: Yup.string().required('Enter an account ID'),
    api_key: Yup.string().required('Enter an api key'),
    api_token: Yup.string().required('Enter a token'),
  }),
  is_active: Yup.bool(),
});

export const VoPayDetails = ({ record }: Props) => {
  const { form: parentForm } = useResourceForm<IIntegrationRecord>();
  const apiUrl = useApiUrl();
  const { mutate: createWebhook, isLoading: isCreatingWebhook } = useCustomMutation();
  const { checkConnection, isCheckingConnection, hasConnection } = useConnectionGuard();

  const form = useForm({
    validate: yupResolver(schema),
    initialValues: {
      is_active: record?.is_active ?? true,
      credentials: {
        account_id: record?.credentials.account_id ?? '',
        api_key: record?.credentials.api_key ?? '',
        api_token: record?.credentials.api_token ?? '',
      },
    },
    transformValues: (values) => {
      values.owner_id = parentForm.values.owner_id;
      values.platform = parentForm.values.platform;

      return values;
    }
  });

  const configureWebhook = () => {
    createWebhook({
      url: `${apiUrl}/integration/vopay/configure/webhook`,
      method: 'post',
      values: {
        ...form.values,
        platform: parentForm.values.platform,
        owner_id: parentForm.values.owner_id,
      },
      successNotification: { type: 'success', message: 'Webhook successfully configured.' },
    });
  }

  return <ResourceForm form={form} showBackButton={false} mx={0}>
    <Stack>
      <TextInput label="Account ID" {...form.getInputProps('credentials.account_id')} />

      <PasswordInput label="API Key" {...form.getInputProps('credentials.api_key')} defaultVisible={!record} />

      <PasswordInput label="API Secret" {...form.getInputProps('credentials.api_token')} defaultVisible={!record} />

      <Group>
        <Button variant="outline" loading={isCheckingConnection}
                disabled={!form.isDirty() && (hasConnection || !form.values.credentials.account_id || !form.values.credentials.api_key || !form.values.credentials.api_token)}
                onClick={() => checkConnection(form.values)}>
          Check Connection
        </Button>

        { hasConnection &&
          <Button variant="outline" loading={isCreatingWebhook}
                  onClick={() => configureWebhook()}
          >
            Configure Webhook
          </Button>
        }
      </Group>

      <Divider my="xs" />

      <Switch label="Enabled"
              description="Marks the integration as Active or Inactive"
              offLabel="No" onLabel="Yes"
              { ...form.getInputProps('is_active', { type: 'checkbox' }) }
      />

      <Card.Section withBorder px="md" py="sm">
        <Group justify="right">
          <ResourceForm.CancelButton />
          <ResourceForm.SubmitButton />
        </Group>
      </Card.Section>
    </Stack>
  </ResourceForm>;
}
