import { AccessControlProvider } from '@refinedev/core';
import { IProfileRecord } from '@interfaces';
import { featureFlagControlProvider } from './featureFlagControlProvider';
import { identityData } from '@components/data/Identity.context';

type AccessFnReturnType = (
  profile?: IProfileRecord
) => AccessControlProvider;

const PERMISSIONS = {
  dashboard: {
    permissions: ['dashboard'],
  },
  help: {
    permissions: ['help.view'],
  },
  refund: {
    permissions: ['refund.view'],
  },
  feedback: {
    permissions: ['feedback.view'],
  },
  voice_message: {
    permissions: ['voice_message.view'],
  },
  customer: {
    permissions: ['customer.view'],
  },
  request_link: {},
  tag_system: {
    permissions: [
      'tag.view',
      'tag_group.view',
    ]
  },
  tag: {
    permissions: ['tag.view'],
  },
  tag_group: {
    features: ['can_upload'],
    // permissions: ['tag_group.view'],
  },
  tag_import: {
    features: ['can_upload'],
  },
  tag_account: {
    permissions: ['tag.view'],
  },
  app_settings: {
    permissions: [
      'settings.automations',
      'request_type.view',
      'machine_type.view',
      'payment_method.view',
      'refund_method.view',
    ]
  },
  app_content: {
    roles: ['Owner'],
  },
  automations: {
    permissions: ['settings.automations'],
  },
  request_type: {
    permissions: ['request_type.view'],
  },
  machine_type: {
    permissions: ['machine_type.view'],
  },
  payment_method: {
    permissions: ['payment_method.view'],
  },
  refund_method: {
    permissions: ['refund_method.view'],
  },
  settings: {
    permissions: [
      'user.view',
      'integration.view',
      'webhook.view',
      'api_key.view',
      'settings.voice_messages',
    ],
  },
  organization: {
    roles: ['Owner']
  },
  wallet: {
    roles: ['Owner'],
    features: ['wallet.has_no_credit'],
    permissions: ['wallet.update'],
  },
  transactions: {
    roles: ['Owner'],
    features: ['wallet'],
  },
  demo_data: {
    roles: ['Admin'],
    features: ['demo']
  },
  integration: {
    permissions: ['integration.view'],
  },
  user: {
    permissions: ['user.view'],
  },
  voice_messages: {
    permissions: ['voice_message.view'],
  },
  webhook: {
    permissions: ['webhook.view'],
  },
  api_key: {
    permissions: ['api_key.view'],
  },
  webhook_logs: {
    permissions: ['webhook.view'],
  },
  admin: {
    roles: ['Admin'],
  },
  playground: {
    roles: ['Admin'],
  },
  owner: {
    roles: ['Admin'],
    permissions: ['owner.view'],
  },
};

export const accessControlProvider: AccessFnReturnType = (profile) => {
  return {
    can: async ({ resource, action, params }) => {
      // As this function is not tsx and can not access `useIdentity, but profile's access can change between organisations.
      // The latest identity data is shared via the window object, the setter can be found in the `refreshProfile` call.
      profile = identityData ?? profile;

      const roles = profile?.roles || []
      const permissions = profile?.permissions || []
      const features = featureFlagControlProvider(profile);

      const page = PERMISSIONS[resource];

      if (page) {
        if (
          (page?.roles?.some(r => roles.includes(r)) ?? true) &&
          (page?.permissions?.some(p => permissions.includes(p)) ?? true) &&
          (page?.features?.some(f => features[f]) ?? true)
        ) {
          return Promise.resolve({ can: true });
        }
      }

      return Promise.resolve({
        can: false,
        reason: 'Unauthorized',
      });
    },
  };
};
