import { createSafeContext } from '@mantine/utils';

interface TabsContext {
  onTabChange(tab: string | number | null): void;
  errorTabs: object;
  setErrorTabs(state: any): void;
}

export const [TabsContextProvider, useTabsContext] = createSafeContext<TabsContext>(
  'Tabs component was not found in the tree'
);
