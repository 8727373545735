import { NotificationProvider } from "@refinedev/core";
import { showNotification, hideNotification } from '@mantine/notifications';

export const notificationProvider: NotificationProvider = {
  open: ({ key, type, message, description }) => {
    let color = 'green'
    switch (type) {
      case 'error':
        color = 'red';
        break;
      case 'progress':
        color = 'blue';
        break;
    }

    showNotification({ id: key, color, message: description || message, autoClose: 5000 });
  },

  close: (key) => {
    hideNotification(key);
  }
};