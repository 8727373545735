import { IRequestLinkListItem } from '@interfaces';
import { ResourceTable, ResourceTableProps } from '@components/table/ResourceTable';

import RequestLinksTableColumns from './RequestLinksTableColumns';

export const RequestLinksTable = (props: ResourceTableProps<IRequestLinkListItem>) => {
  return <>
    <ResourceTable<IRequestLinkListItem>
      resource="request_link"
      columns={RequestLinksTableColumns}
      {...props}
    />
  </>;
}
