import { IResourceComponentsProps } from "@refinedev/core";
import { IPaymentMethodResponse } from '@interfaces';
import { Card, Divider, Grid, Group, Input, Loader, NumberInput, Select, SimpleGrid, Stack, Switch, TextInput, Title } from '@mantine/core';
import * as Yup from 'yup';
import { useForm, yupResolver } from '@mantine/form';
import { Feature } from 'flagged';
import { useIdentity } from '@components/data/Identity.context';
import { useOwnerOptions } from '@components/hooks';
import { ResourceForm } from '@components/ui/form/ResourceForm';
import { useDidUpdate } from '@mantine/hooks';
import { Translatable } from '@components/ui';

export function PaymentMethodEdit({ initialData: record }: IResourceComponentsProps<IPaymentMethodResponse>) {
  const { identity } = useIdentity();
  const { data: owners, isLoading: isOwnersLoading } = useOwnerOptions();

  const languages = identity?.owner?.supported_languages ?? [];

  const translations = record?.data.translations || {
    name: {
      en: '',
      es: '',
      fr: ''
    }
  };

  const schema = Yup.object().shape({
    roles: Yup.array(),
    owner_id: Yup.mixed().nullable().when('roles', {
      is: (roles) => roles.includes('Admin'),
      then: (schema) => schema.required('Assign an owner')
    }),
    type: Yup.string().required('Select a type'),
    name: Yup.string().when('type', {
      is: (type) => type === 'VendorCard',
      then: (schema) => schema.required('Enter a name')
    }),
    pre_auth_refund_method_type: Yup.string().when('pre_auth_enabled', {
      is: true,
      then: (schema) => schema.required('Select a type')
    }),
    pre_auth_follow_up_delay: Yup.string().when(['pre_auth_enabled', 'pre_auth_refund_method_type'], {
      is: (enabled, refund_method) => enabled && refund_method === 'Reversal',
      then: (schema) => schema.required('Enter a delay')
    }),
    pre_auth_enabled: Yup.bool(),
    is_active: Yup.bool(),
    settings: Yup.object().when('type', ([type], schema) => {
      return schema.shape({
        'vendorcard_maxlength': Yup.number().min(1).max(100),
        'vendorcard_account-identifier': Yup.string().nullable().when({
          is: () => type === 'VendorCard',
          then: (schema) => schema.required('Select an account identifier')
        }),
        'collect_all_refund-details': Yup.bool()
      });
    }),
    translations: Yup.lazy((_, { parent }) => Yup.object({
      name: Yup.lazy(() => {
        const rules = (identity?.owner?.supported_languages || []).reduce((rules, lang) => {
          rules[lang] = Yup.string().nullable().when({
            is: () => parent.type === 'VendorCard' && identity?.owner?.supported_languages.length > 1,
            then: (schema) => schema.required('Text is required'),
          });

          return rules;
        }, {});

        return Yup.object(rules).required();
      }),
    })),
  });

  const form = useForm({
    validate: yupResolver(schema),
    initialValues: {
      roles: identity.roles,
      owner_id: record?.data.owner_id.toString() ?? null,
      type: record?.data.type ?? '',
      name: record?.data.name ?? '',
      pre_auth_refund_method_type: record?.data.pre_auth_refund_method_type ?? 'Refund',
      pre_auth_follow_up_delay: record?.data.pre_auth_follow_up_delay ?? 72,
      pre_auth_enabled: record?.data.pre_auth_enabled ?? false,
      is_active: record?.data.is_active ?? true,
      settings: {
        'vendorcard_maxlength': record?.data.settings['vendorcard_maxlength'] ?? 16,
        'vendorcard_account-identifier': record?.data.settings['vendorcard_account-identifier'] ?? null,
        'collect_all_refund-details': record?.data.settings['collect_all_refund-details'] ?? false,
      },
      translations: {
        name: {
          en: translations.name?.en ?? '',
          es: translations.name?.es ?? '',
          fr: translations.name?.fr ?? ''
        }
      },
    },
  });

  const typeOptions = [
    { value: 'Cash', label: 'Cash' },
    { value: 'Card', label: 'Credit or debit card' },
    { value: 'PayRange', label: 'PayRange' },
    { value: 'ApplePay', label: 'ApplePay®' },
    { value: 'AppleCard', label: 'Apple Card' },
    { value: 'GooglePay', label: 'GooglePay®' },
    { value: 'Other', label: 'Other' },
    { value: 'VendorCard', label: 'Vendor Card' },
  ];

  const refundMethodTypeOptions = [
    { value: 'Refund', label: 'Refund' },
    { value: 'Reversal', label: 'Reversal' },
  ];

  const setTranslation = () => {
    const language = languages[0];

    if (form.values.name && !form.values.translations?.name[language]) {
      form.setFieldValue('translations.name', { ...form.values.translations.name, [language]: form.values.name });
    }
  };

  useDidUpdate(() => {
    form.setFieldValue('name', '');

    if (['Cash', 'Other'].includes(form.values.type)) {
      form.setFieldValue('pre_auth_refund_method_type', 'Refund');
    }
  }, [form.values.type]);

  return <ResourceForm form={form}>
    <Group mb="lg">
      <Title order={2}>Payment Method</Title>
    </Group>

    <Card shadow="sm" radius="sm">
      <Card.Section withBorder p="md">
        <Stack>
          <Grid>
            <Feature name="admin">
              <Grid.Col span={{ md: 6 }}>
                <Select
                  label="Assign to owner"
                  placeholder="Select an owner..."
                  searchable
                  leftSection={isOwnersLoading && <Loader size="xs" />}
                  data={owners?.data || []}
                  { ...form.getInputProps('owner_id') }
                />
              </Grid.Col>
            </Feature>

            <Grid.Col span={{ md: 6 }}>
              <Select data={typeOptions}
                      label="Type"
                      disabled={!!record?.data.type}
                      {...form.getInputProps('type')} />
            </Grid.Col>

            { form.values.type === 'VendorCard' && <>
              <Grid.Col span={{ md: 6 }}>
                <TextInput label="Name"
                           description="Provide a name that will be shown in the dashboard"
                           maxLength={35}
                           { ...form.getInputProps('name') }
                           onBlur={setTranslation} />
              </Grid.Col>

              { languages.length > 1 && <>
                <Grid.Col span={{ md: 12 }}>
                  <Input.Label mb={0}>Name Translations</Input.Label>
                  <Input.Description>Provide a name that will be shown to the customer</Input.Description>
                  <Translatable field="translations.name" limit={35} />
                </Grid.Col>
              </>}

              <Grid.Col span={{ md: 6 }}>
                <Select
                  label="Account Identifier"
                  description="How will the customer identify their account?"
                  placeholder="Select a format..."
                  searchable
                  data={[
                    { value: 'CardNumber', label: 'Card Number' },
                    { value: 'Email', label: 'Email' },
                    { value: 'PhoneNumber', label: 'Phone Number' },
                  ]}
                  { ...form.getInputProps('settings.vendorcard_account-identifier') }
                />
              </Grid.Col>

              { form.values.settings['vendorcard_account-identifier'] === 'CardNumber' && <>
                <Grid.Col span={{ md: 6 }}>
                  <NumberInput label="Number of digits"
                               description="What is the length of the card number?"
                               { ...form.getInputProps('settings.vendorcard_maxlength') } />
                </Grid.Col>
              </>}
            </>}

            <Grid.Col span={{ md: 6 }}>
              <Select data={refundMethodTypeOptions}
                      disabled={['Cash', 'Other'].includes(form.values.type)}
                      label="Refund type"
                      {...form.getInputProps('pre_auth_refund_method_type')} />
            </Grid.Col>

            { !!form.values.pre_auth_enabled && <>
              <Grid.Col span={{ md: 6 }}>
                <NumberInput label="Notification follow-up delay"
                             description="Hours between transaction and follow-up notification sent."
                             min={0}
                             max={72}
                             {...form.getInputProps('pre_auth_follow_up_delay')} />
              </Grid.Col>
            </>}
          </Grid>

          <Divider />

          <SimpleGrid cols={{ sm: 2 }} spacing="sm">
            <Switch label="Pre-authorization for non-cash payments"
                    description="A temporary hold on funds occurs when using non-cash payments at this item."
                    offLabel="Off" onLabel="On"
                    {...form.getInputProps('pre_auth_enabled', { type: 'checkbox' })}
            />

            { ['Card', 'ApplePay', 'AppleCard', 'GooglePay'].includes(form.values.type) && <>
              <Switch label="Collect all refund and payment information"
                      description={`Collects the ${form.values.type} information and the customers preferred refund option.`}
                      offLabel="No" onLabel="Yes"
                      { ...form.getInputProps('settings.collect_all_refund-details', { type: 'checkbox' }) }
              />
            </>}

            <Switch label="Enabled"
                    description="Marks this method as enabled or disabled."
                    offLabel="No" onLabel="Yes"
                    { ...form.getInputProps('is_active', { type: 'checkbox' }) }
            />
          </SimpleGrid>

        </Stack>
      </Card.Section>

      <Card.Section px="md" py="sm">
        <Group justify="right">
          <ResourceForm.CancelButton />
          <ResourceForm.SubmitButton />
        </Group>
      </Card.Section>
    </Card>
  </ResourceForm>
}
