import { Loader, Menu } from '@mantine/core';
import { IconTrash } from '@tabler/icons-react';
import { useActionMenu } from '@components/table/actions/ActionMenu.context';
import { ConfirmAction } from '@components/actions/ConfirmAction';
import { useTableResource } from '@components/table/TableResource.context';
import { useDelete, useInvalidate } from '@refinedev/core';
import { IRequestLinkRecord } from '@interfaces';

interface Props {
  id: string;
  disabled?: boolean;
}

export const DeleteRequestLinkAction = ({ id, disabled = false }: Props) => {
  const { closeMenu } = useActionMenu();
  const invalidate = useInvalidate();
  const { mutate: destroy, isLoading } = useDelete<IRequestLinkRecord>();

  return <ConfirmAction onConfirm={async () =>{
    destroy({ resource: 'request_link', id }, {
      onSuccess: async () => {
        await invalidate({ resource: 'request_link', invalidates: ['all'] });
        closeMenu();
      },
    });
  }}>
    {(onConfirm) => <Menu.Item
      color="dark.4"
      disabled={disabled}
      onClick={onConfirm}
      leftSection={isLoading ? <Loader size={16} /> : <IconTrash size={18} stroke={1.5} />}
    >
      Delete request link
    </Menu.Item>}
  </ConfirmAction>
}
