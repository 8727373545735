import { useState } from 'react';
import { Input, ColorInput} from '@mantine/core';
import chroma from 'chroma-js';
import { ColorPreview } from '@components/ui/colorGenerator/ColorPreview';
import { useDebouncedValue, useDidUpdate } from '@mantine/hooks';

export type ColorSwatch = {
  index: number;
  isActive: boolean;
  title: string;
  color: string;
  hex: string;
}

export function createSwatch(hex: string): ColorSwatch[] {
  const LIGHTNESS_MAP = [0.95, 0.85, 0.75, 0.65, 0.55, 0.45, 0.35, 0.25, 0.15, 0.05];
  const SATURATION_MAP = [0.32, 0.16, 0.08, 0.04, 0, 0, 0.04, 0.08, 0.16, 0.32];

  if (!chroma.valid(hex)) {
    hex = '#000000';
  }

  const colorChroma = chroma(hex);

  const lightnessGoal = colorChroma.get('hsl.l');
  const closestLightness = LIGHTNESS_MAP.reduce((prev, curr) =>
    Math.abs(curr - lightnessGoal) < Math.abs(prev - lightnessGoal) ? curr : prev,
  );

  const baseColorIndex = LIGHTNESS_MAP.findIndex((l) => l === closestLightness);

  const colors = LIGHTNESS_MAP
    .map((l) => colorChroma.set('hsl.l', l))
    .map((color) => chroma(color))
    .map((color, i) => {
      const saturationDelta = SATURATION_MAP[i] - SATURATION_MAP[baseColorIndex];
      return saturationDelta >= 0
        ? color.saturate(saturationDelta)
        : color.desaturate(saturationDelta * -1);
    });

  return colors.map((color, index) => {
    return {
      index: index,
      isActive: baseColorIndex === index,
      title: `${index}`,
      color: chroma.contrast(color, 'white') < 4.5 ? 'black' : 'white',
      hex: color.hex(),
    };
  });
}

export const ColorSwatchInput = ({ label, onChange, value: inputValue = null, ...props }) => {
  const [value, setValue] = useState(inputValue);
  const [debouncedValue] = useDebouncedValue(value, 200);

  useDidUpdate(() => {
    onChange(value);
  }, [debouncedValue]);

  useDidUpdate(() => {
    setValue(inputValue);
  }, [inputValue]);

  const onChangeHandler = (value: string) => {
    setValue(value);
  }

  const colors = createSwatch(value);

  return <>
    <Input.Wrapper label={label}>
      <ColorInput value={value} onChange={onChangeHandler} {...props} />
    </Input.Wrapper>

    <ColorPreview colors={colors} />
  </>;
};
