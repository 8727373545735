import { RichTextEditor, Link } from '@mantine/tiptap';
import { useEditor } from '@tiptap/react';
import Highlight from '@tiptap/extension-highlight';
import StarterKit from '@tiptap/starter-kit';
import Underline from '@tiptap/extension-underline';
import TextAlign from '@tiptap/extension-text-align';
import Superscript from '@tiptap/extension-superscript';
import SubScript from '@tiptap/extension-subscript';
import Placeholder from '@tiptap/extension-placeholder';
import { useState } from 'react';
import { useDebouncedValue, useDidUpdate } from '@mantine/hooks';
import { UndoControl } from '@components/ui/editors/controls/UndoControl';
import { RedoControl } from '@components/ui/editors/controls/RedoControl';

interface Props {
  placeholder?: string;
  value?: any;
  onChange: any;
  simpleControls?: boolean;
}

export default ({ simpleControls = false, ...props }: Props) => {
  const [value, setValue] = useState(props.value);
  const [debouncedValue] = useDebouncedValue(value, 200);

  useDidUpdate(() => {
    props.onChange(value);
  }, [debouncedValue]);

  const editor = useEditor({
    extensions: [
      StarterKit,
      Underline,
      Link,
      Superscript,
      SubScript,
      Highlight,
      TextAlign.configure({ types: ['heading', 'paragraph'] }),
      Placeholder.configure({
        placeholder: () => props.placeholder || ''
      })
    ],
    content: value,
    onUpdate: ({ editor }) => {
      const content = editor.getHTML();
      setValue(content === '<p></p>' ? '' : content);
    }
  });

  useDidUpdate(() => {
    if (!props.value && editor) {
      editor.commands.clearContent(true);
    }

    if (props.value !== value) {
      setValue(props.value);
      editor?.commands?.setContent(props.value);
    }
  }, [props.value])

  return (
    <RichTextEditor editor={editor}>
      <RichTextEditor.Toolbar sticky>
        <RichTextEditor.ControlsGroup>
          <UndoControl />
          <RedoControl />
        </RichTextEditor.ControlsGroup>
        {
          simpleControls && <>
            <RichTextEditor.ControlsGroup>
              <RichTextEditor.Bold />
              <RichTextEditor.Italic />
              <RichTextEditor.Underline />
              <RichTextEditor.Strikethrough />
            </RichTextEditor.ControlsGroup>

            <RichTextEditor.ControlsGroup>
              <RichTextEditor.OrderedList />
              <RichTextEditor.BulletList />
            </RichTextEditor.ControlsGroup>
          </>
        }
        {
          !simpleControls && <>
            <RichTextEditor.ControlsGroup>
              <RichTextEditor.Bold />
              <RichTextEditor.Italic />
              <RichTextEditor.Underline />
              <RichTextEditor.Strikethrough />
              <RichTextEditor.ClearFormatting />
              <RichTextEditor.Highlight />
              <RichTextEditor.Code />
            </RichTextEditor.ControlsGroup>

            <RichTextEditor.ControlsGroup>
              <RichTextEditor.H1 />
              <RichTextEditor.H2 />
              <RichTextEditor.H3 />
              <RichTextEditor.H4 />
            </RichTextEditor.ControlsGroup>

            <RichTextEditor.ControlsGroup>
              <RichTextEditor.Blockquote />
              <RichTextEditor.Hr />
              <RichTextEditor.OrderedList />
              <RichTextEditor.BulletList />
              <RichTextEditor.Subscript />
              <RichTextEditor.Superscript />
            </RichTextEditor.ControlsGroup>

            <RichTextEditor.ControlsGroup>
              <RichTextEditor.Link />
              <RichTextEditor.Unlink />
            </RichTextEditor.ControlsGroup>

            <RichTextEditor.ControlsGroup>
              <RichTextEditor.AlignLeft />
              <RichTextEditor.AlignCenter />
              <RichTextEditor.AlignJustify />
              <RichTextEditor.AlignRight />
            </RichTextEditor.ControlsGroup>
          </>
        }
      </RichTextEditor.Toolbar>

      <RichTextEditor.Content />
    </RichTextEditor>
  );
}
