import { Badge, Grid, Group, Loader, Stack, Text } from '@mantine/core';
import { IHelpListItem, IHelpRecord, IRefundRecord, IVoiceMessageRecord } from '@interfaces';
import { TableRow } from '@components/table/TableRow';
import { CustomerCard } from '@components/data/cards/CustomerCard';
import { NotesCard } from '@components/data/cards/NotesCard';
import { ReasonCard } from '@components/data/cards/ReasonCard';
import { LocationCard } from '@components/data/cards/LocationCard';
import { AuditTable } from '@components/data/tables/AuditTable';
import { EquipmentCard } from '@components/data/cards/EquipmentCard';
import { Tabs } from '@components/ui/tabs/Tabs';
import { RefundRequestCard } from '@components/data/cards/RefundRequestCard/RefundRequestCard';
import { RefundHistoryCard } from '@components/data/cards/RefundHistoryCard';
import { PostalAddressCard } from '@components/data/cards/PostalAddressCard';
import { useOne } from "@refinedev/core";
import { PayoutDetailsCard } from '@components/data/cards/PayoutDetailsCard';
import { PaymentDetailsCard } from '@components/data/cards/PaymentDetailsCard';
import { PreAuthFollowUpCard } from '@components/data/cards/PreAuthFollowUpCard';
import { VoiceMessageSummaryCard } from '@components/data/cards/VoiceMessage/VoiceMessageSummaryCard';

type Props = {
  item: IHelpListItem;
}

type TabContentProps = {
  record: IHelpRecord;
}

const TabContent = ({ record }: TabContentProps) => {
  const { data: refund, isLoading: isRefundLoading } = useOne<IRefundRecord>({
    resource: 'refund', id: record.request.refund?.id, queryOptions: { enabled: !!record.request.refund }
  });

  const { data: voiceMessage, isLoading: isVoiceMessageLoading } = useOne<IVoiceMessageRecord>({
    resource: 'voice_message', id: record.request.voice_message?.id, queryOptions: { enabled: !!record.request.voice_message }
  });

  return <Tabs variant="outline" defaultValue="details">
    <Tabs.List>
      <Tabs.Tab value="details">
        <Text size="sm" fw="500">Details</Text>
      </Tabs.Tab>

      {record.request.refund && <>
        <Tabs.Tab value="refund">
          <Group gap="xs">
            <Text size="sm" fw="500">Refund</Text>
            { isRefundLoading && <Loader size={16} /> }
          </Group>
        </Tabs.Tab>

        <Tabs.Tab value="refund_logs">
          <Group gap="xs">
            <Text size="sm" fw="500">Refund logs</Text>
            { isRefundLoading ? <Loader size={16} /> : <Badge>{refund.data.audits.length}</Badge>}
          </Group>
        </Tabs.Tab>
      </>}

      {record.request.voice_message && <>
        <Tabs.Tab value="voice_message">
          <Group gap="xs">
            <Text size="sm" fw="500">Call</Text>
            { isVoiceMessageLoading && <Loader size={16} /> }
          </Group>
        </Tabs.Tab>
      </>}

      <Tabs.Tab value="logs">
        <Group gap="xs">
          <Text size="sm" fw="500">Logs</Text>
          <Badge>{record.audits.length}</Badge>
        </Group>
      </Tabs.Tab>
    </Tabs.List>

    <Tabs.Panel value="details" pt="xs">
      <Grid>
        <Grid.Col span={{ lg: 6 }}>
          <Stack>
            <CustomerCard customer={record.customer} />
            <ReasonCard help={record} />
            <EquipmentCard machine_type={record.machine_type}
                           machine_code={record.machine_code}
                           tag={record.tag} />
            <LocationCard address={record.request?.address || record.tag?.address} />
          </Stack>
        </Grid.Col>

        <Grid.Col span={{ lg: 6 }}>
          <NotesCard request={record.request} />
        </Grid.Col>
      </Grid>
    </Tabs.Panel>

    { refund?.data && <>
      <Tabs.Panel value="refund" pt="xs">
        <Grid>
          <Grid.Col span={{ lg: 6 }}>
            <Stack>
              <RefundRequestCard refund={refund.data} />
              <RefundHistoryCard refund={refund.data} />
            </Stack>
          </Grid.Col>

          <Grid.Col span={{ lg: 6 }}>
            <Stack>
              { refund.data.status === 'Draft' && refund.data.meta.follow_up_scheduled_at && <PreAuthFollowUpCard refund={refund.data} /> }
              { refund.data.payment_method && <PaymentDetailsCard refund={refund.data} /> }
              { refund.data.status !== 'Draft' && refund.data.payout && <PayoutDetailsCard refund={refund.data} /> }
              { refund.data.address && <>
                <PostalAddressCard address={refund.data.address}/>
                <LocationCard address={refund.data.address?.address} label="Check address"/>
              </>}
              <NotesCard request={refund.data.request} />
            </Stack>
          </Grid.Col>
        </Grid>
      </Tabs.Panel>
      <Tabs.Panel value="refund_logs" pt="xs">
        <AuditTable audits={refund.data.audits} />
      </Tabs.Panel>
    </>}

    { voiceMessage?.data && <>
      <Tabs.Panel value="voice_message" pt="xs">
        <Grid>
          <Grid.Col span={{ lg: 6 }}>
            <Stack>
              <VoiceMessageSummaryCard message={voiceMessage.data} />
            </Stack>
          </Grid.Col>
        </Grid>
      </Tabs.Panel>
    </>}

    <Tabs.Panel value="logs" pt="xs">
      <AuditTable audits={record.audits} />
    </Tabs.Panel>
  </Tabs>;
}

export default ({ item }: Props) => {
  return <TableRow<IHelpRecord> resource="help" id={item.id}>
    {(record) => <TabContent record={record} />}
  </TableRow>;
}
