import { forwardRef, useState, ReactNode } from 'react';
import {
  Text, Collapse, Group, Paper, Box, Divider, PaperProps, createPolymorphicComponent, useProps
} from '@mantine/core';
import cx from 'clsx';
import { IconChevronRight } from '@tabler/icons-react';

import classes from './CollpasableCard.module.css';

export interface CollapsableCardProps extends PaperProps {
  children: ReactNode;
  label: ReactNode;
  leftSection?: ReactNode;
  rightSection?: ReactNode;
  variant?: 'default' | 'light'
  collapsable?: boolean;
  collapsed?: boolean;
}

const defaultProps: Partial<CollapsableCardProps> = {
  p: 'md',
  variant: 'default',
};

export const _CollapsableCard = forwardRef<HTMLDivElement, CollapsableCardProps>((props, ref) => {
    const {
      label,
      leftSection,
      rightSection,
      variant = 'default',
      collapsable = true,
      collapsed = true,
      className, p, radius, children, classNames, styles, ...others
    } = useProps('CollapsableCard', defaultProps, props);
    const [opened, setOpen] = useState(collapsed);

    return (
      <Paper
        className={cx(classes.root, classes[variant], className)}
        variant={variant}
        radius={radius}
        p={0}
        ref={ref}
        {...others}
      >
        <Group justify="space-between"
               px={p}
               py="xs"
               className={cx(classes.title, {[classes.collapsable]: collapsable})}
               onClick={() => collapsable && setOpen(o => !o)}>
          <Group>
            <Text fw="bold" size="md">{label}</Text>
            { leftSection }
          </Group>
          <Group>
            { rightSection }
            { collapsable && <IconChevronRight size={25}
                          strokeWidth={1.5}
                          style={{
                            transition: 'transform 200ms ease',
                            transform: opened ? `rotate(90deg)` : 'none',
                          }} /> }
          </Group>
        </Group>
        <Collapse in={opened}>
          <Divider />
          <Box p={p}>
            {children}
          </Box>
        </Collapse>
      </Paper>
    );
  }
) as any;

export const CollapsableCard = createPolymorphicComponent<'div', CollapsableCardProps>(_CollapsableCard);
