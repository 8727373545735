import { createUseExternalEvents } from '@mantine/core';
import {
  MantineDrawer,
  MantineDrawers,
  DrawerSettings,
  OpenContextDrawer,
} from './context';

type DrawersEvents = {
  openDrawer: (payload: DrawerSettings) => void;
  closeDrawer: (id: string) => void;
  closeContextDrawer: <TKey extends MantineDrawer>(id: TKey) => void;
  closeAllDrawers: () => void;
  openContextDrawer: <TKey extends MantineDrawer>(
    payload: OpenContextDrawer<Parameters<MantineDrawers[TKey]>[0]['innerProps']> & { drawer: TKey }
  ) => void;
};

export const [useDrawersEvents, createEvent] =
  createUseExternalEvents<DrawersEvents>('mantine-drawers');

export const openDrawer = createEvent('openDrawer');
export const closeDrawer = createEvent('closeDrawer');
export const closeContextDrawer: DrawersEvents['closeContextDrawer'] = <TKey extends MantineDrawer>(
  id: TKey
) => createEvent('closeContextDrawer')(id);
export const closeAllDrawers = createEvent('closeAllDrawers');
export const openContextDrawer: DrawersEvents['openContextDrawer'] = <TKey extends MantineDrawer>(
  payload: OpenContextDrawer<Parameters<MantineDrawers[TKey]>[0]['innerProps']> & { drawer: TKey }
) => createEvent('openContextDrawer')(payload);

export const drawers: {
  open: DrawersEvents['openDrawer'];
  close: DrawersEvents['closeDrawer'];
  closeAll: DrawersEvents['closeAllDrawers'];
  openContextDrawer: DrawersEvents['openContextDrawer'];
} = {
  open: openDrawer,
  close: closeDrawer,
  closeAll: closeAllDrawers,
  openContextDrawer,
};
