import { useResourceForm } from '@components/ui/form/ResourceForm.context';
import { ITagRecord } from '@interfaces';
import { ActionIcon, Button, Group, Popover, TextInput, Tooltip } from '@mantine/core';
import { IconPlus } from '@tabler/icons-react';
import { useCreate } from '@refinedev/core';
import { useForm, yupResolver } from '@mantine/form';
import * as Yup from 'yup';
import { useDisclosure } from '@mantine/hooks';

interface Props {
  onCreated: (id: string) => void;
}

const schema = Yup.object().shape({
  owner_id: Yup.number().nullable(),
  type: Yup.string().required('Enter a value'),
});

export const TagTypePopover = ({ onCreated }: Props) => {
  const { form: parentForm } = useResourceForm<ITagRecord>();
  const { mutate: create, isLoading } = useCreate();
  const [opened, { close, toggle }] = useDisclosure(false);

  const form = useForm({
    validate: yupResolver(schema),
    initialValues: {
      owner_id: parentForm.values.owner_id,
      type: '',
    },
  });

  const handleSubmit = () => {
    form.validate();

    if (form.isValid()) {
      create({ resource: `tag_type`, values: form.values }, {
        onSuccess: ({ data }) => {
          close();
          form.reset();
          onCreated(`${data.id}`);
        }
      });
    }
  };

  return <>
    <Popover trapFocus
             position="bottom"
             withArrow
             shadow="md"
             offset={{ mainAxis: 8, crossAxis: -126 }}
             opened={opened}
             onChange={toggle}
    >
      <Popover.Target>
        <Tooltip label="Add a new type" withArrow>
          <ActionIcon size="lg" variant="default" mb={1} onClick={toggle}>
            <IconPlus size={20} stroke={1.5} />
          </ActionIcon>
        </Tooltip>
      </Popover.Target>
      <Popover.Dropdown p="xs">
        <Group gap="xs" align="start">
          <TextInput placeholder="New type"
                     size="sm"
                     flex={1}
                     {...form.getInputProps('type')}
          />

          <Button size="sm"
                  variant="light"
                  loading={isLoading}
                  disabled={isLoading}
                  onClick={() => handleSubmit()}
          >
            Save
          </Button>
        </Group>
      </Popover.Dropdown>
    </Popover>
  </>;
}
