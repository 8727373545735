import { IResourceComponentsProps } from "@refinedev/core";
import { ITransactionListResponse } from '@interfaces';
import { FilterBar } from '@components/filters/FilterBar';
import { TableFilterProvider } from '@components/table/TableFilter.context';
import { FilterSelect } from '@components/filters/FilterSelect';
import { TransactionTable } from './table/TransactionTable';

export function TransactionList({ initialData }: IResourceComponentsProps<ITransactionListResponse>) {
  const filters = <>
    <FilterSelect name="type" label="Type" route="transaction_types" />
  </>;

  return <TableFilterProvider>
    <FilterBar filters={filters} />
    <TransactionTable initialData={initialData} syncWithLocation={true} />
  </TableFilterProvider>;
}
