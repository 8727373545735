import { Grid, Stack, Text } from '@mantine/core';
import { IFeedbackListItem, IFeedbackRecord } from '@interfaces';
import { TableRow } from '@components/table/TableRow';
import { NotesCard } from '@components/data/cards/NotesCard';
import { CustomerCard } from '@components/data/cards/CustomerCard';
import { LocationCard } from '@components/data/cards/LocationCard';
import { DescriptionCard } from '@components/data/cards/DescriptionCard';
import { Tabs } from '@components/ui/tabs/Tabs';
import { EquipmentCard } from '@components/data/cards/EquipmentCard';

type Props = {
  item: IFeedbackListItem;
}

type TabContentProps = {
  record: IFeedbackRecord;
}

const TabContent = ({ record }: TabContentProps) => {
  return <Tabs variant="outline" defaultValue="details">
    <Tabs.List>
      <Tabs.Tab value="details">
        <Text size="sm" fw="500">Details</Text>
      </Tabs.Tab>
    </Tabs.List>

    <Tabs.Panel value="details" pt="xs">
      <Grid>
        <Grid.Col span={{ lg: 6 }}>
          <Stack>
            <CustomerCard customer={record.customer} />
            <DescriptionCard feedback={record} />
            { record.tag && <EquipmentCard tag={record.tag} /> }
            <LocationCard address={record.request?.address || record.tag?.address} />
          </Stack>
        </Grid.Col>

        <Grid.Col span={{ lg: 6 }}>
          <NotesCard request={record.request} />
        </Grid.Col>
      </Grid>
    </Tabs.Panel>
  </Tabs>;
}

export default ({ item }: Props) => {
  return <TableRow<IFeedbackRecord> resource="feedback" id={item.id}>
    {(record) => <TabContent record={record} />}
  </TableRow>;
}
