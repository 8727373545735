import { useState } from 'react';
import { Button, Menu } from '@mantine/core';
import { IconChevronDown, IconChevronRight } from '@tabler/icons-react';
import { useTableFilters } from '@components/table/TableFilter.context';
import { useApiUrl, useCustom } from "@refinedev/core";
import { downloadAsFile } from '../../libraries/downloadAsFile';
import { showNotification, updateNotification } from '@mantine/notifications';

type Props = {
  resource: string;
};

export const ExportAction = ({ resource }: Props) => {
  const { filters } = useTableFilters();
  const [opened, setOpened] = useState(false);
  const [queryParam, setQueryParam] = useState({});

  const apiUrl = useApiUrl();
  const { refetch, isFetching } = useCustom({
    url: `${apiUrl}/${resource}/export`,
    method: 'get',
    config:{ filters, query: queryParam },
    queryOptions: { enabled: false, retry: false }
  });

  const onExport = (type: string) => {
    setQueryParam({ extension: type });

    showNotification({ id: `resource-export-${resource}`, loading: true, color: 'blue', message: 'Exporting records...' });

    // For some reason "queryParam" is not up to date when the refetch happens.
    setTimeout(async() => {
      const { data } = await refetch();
      downloadAsFile(data.data);

      updateNotification({ id: `resource-export-${resource}`, color: 'green', message: 'Your export is ready!' });
    }, 100);
  };

  return (
    <Menu shadow="md" width={100} opened={opened} onChange={setOpened} withArrow>
      <Menu.Target>
        <Button size="xs"
                variant="light"
                loading={isFetching}
                rightSection={opened ? <IconChevronDown size={14} /> : <IconChevronRight size={14} />}
        >
          Export as
        </Button>
      </Menu.Target>

      <Menu.Dropdown>
        <Menu.Item onClick={() => onExport('xlsx')}>Excel</Menu.Item>
        <Menu.Item onClick={() => onExport('csv')}>CSV</Menu.Item>
      </Menu.Dropdown>
    </Menu>
  );
}
