import { createContext, useContext } from 'react';

type TableResourceContextProps = {
  resource: string,
};

export const TableResourceContext = createContext<TableResourceContextProps>(null);

export function useTableResource() {
  return useContext(TableResourceContext);
}
