import { PropsWithChildren, useState } from 'react';
import { EuiFilterButton, EuiFilterGroup, EuiPopover, EuiPopoverFooter, EuiFilterButtonProps } from '@elastic/eui';
import { Button } from '@mantine/core';
import { useFilter } from '@components/filters/Filter.context';
import cx from 'clsx';

import classes from './Filter.module.css';

type Props = {
  label: string;
  buttonProps?: EuiFilterButtonProps;
};

export const FilterPopover = ({ label, buttonProps, children }: PropsWithChildren<Props>) => {
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const { value, clear } = useFilter();

  const openPopover = () => {
    setIsPopoverOpen(!isPopoverOpen);
  };

  const closePopover = () => {
    setIsPopoverOpen(false);
  };

  const onHandleReset = () => {
    clear();
    closePopover();
  };

  const isValueEmpty = Array.isArray(value) ? !!value.length : !!value;
  const isFilterActive = buttonProps?.hasActiveFilters ?? isValueEmpty;

  const button = (
    <EuiFilterButton
      iconType="arrowDown"
      iconSize="s"
      className={classes.button}
      onClick={openPopover}
      isSelected={isPopoverOpen}
      hasActiveFilters={isFilterActive}
      { ...buttonProps }
    >
      { label }
    </EuiFilterButton>
  );

  return <EuiFilterGroup className={cx(classes.filter, {[classes.filterActive]: isFilterActive })}>
    <EuiPopover
      button={button}
      isOpen={isPopoverOpen}
      closePopover={closePopover}
      panelPaddingSize="none"
    >
      <div style={{ width: 300 }}>
        { children }
        { isFilterActive && <EuiPopoverFooter paddingSize="s" style={{ border: 0 }}>
          <Button size="xs"
                  fullWidth
                  variant="default"
                  onClick={onHandleReset}
          >
            Clear
          </Button>
        </EuiPopoverFooter> }
      </div>
    </EuiPopover>
  </EuiFilterGroup>;
}
