import { IResourceComponentsProps, useNavigation } from '@refinedev/core';
import { IWebhookLogListResponse } from '@interfaces';
import { FilterBar } from '@components/filters/FilterBar';
import { FilterSelect } from '@components/filters/FilterSelect';
import { WebhookLogTable } from './table/WebhookLogTable';
import { TableFilterProvider } from '@components/table/TableFilter.context';
import { useFeature } from 'flagged';
import { Button } from '@mantine/core';

export function WebhookLogList({ initialData }: IResourceComponentsProps<IWebhookLogListResponse>) {
  const { list } = useNavigation();

  const isAdmin = useFeature('admin');

  const actions = <>
    <Button onClick={() => list('webhook')}>
      View webhooks
    </Button>
  </>;

  const filters = isAdmin ? <FilterSelect name="owners" label="Owner" route="owners" /> : null;

  return <TableFilterProvider>
    <FilterBar filters={filters} actions={actions} />
    <WebhookLogTable initialData={initialData}
                     allowExports={false}
                     syncWithLocation={true}
    />
  </TableFilterProvider>;
}
