import { Button } from '@mantine/core';
import { FilterProvider, useFilter } from '@components/filters/Filter.context';
import { isEqual } from 'lodash';

import classes from './FilterButtonGroup.module.css';

type Props = {
  options: { label: string, value: unknown }[];
};

type FilterProps = {
  name: string;
  value?: unknown;
} & Props;

const Filter = ({ options }: Props) => {
  const { value, setValue } = useFilter();

  const buttons = options.map((o, i) => {
    const variant = isEqual(o.value, value) ? 'filled' : 'default';

    return <Button key={i}
                   variant={variant}
                   className={classes.button}
                   styles={{
                     root: { height: 38, flex: 'auto' },
                     label: { fontSize: 14, fontWeight: 500 },
                   }}
                   onClick={() => setValue(o.value)}
    >{ o.label }</Button>;
  });

  return <Button.Group className={classes.group}>{ buttons }</Button.Group>
}

export const FilterButtonGroup = ({ name, options, value = null }: FilterProps) => {
  return <FilterProvider name={name} defaultValue={value}>
    <Filter options={options} />
  </FilterProvider>
};
