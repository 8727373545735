import { useState } from 'react';
import { Menu, ScrollArea, NavLink, useMantineTheme, Divider, Box, Drawer } from '@mantine/core';
import { IconChevronRight, IconSwitchHorizontal } from '@tabler/icons-react';
import { useIdentity } from '@components/data/Identity.context';
import { useAssignedOwnerOptions } from '@components/hooks';
import { useMediaQuery } from '@mantine/hooks';

interface Props {
  setMenu: (opened: boolean) => void;
}

export function SidebarSwitchOwnerSection({ setMenu }: Props) {
  const theme = useMantineTheme();
  const { identity, switchOrganisation } = useIdentity();
  const [opened, setOpened] = useState(false);
  const isMobile = useMediaQuery(`(max-width: ${theme.breakpoints.sm})`, false);

  const { data: owners } = useAssignedOwnerOptions({ queryOptions: { refetchOnMount: false } });

  const MenuItems = () => <>
    {(owners?.data ?? []).map(o =>
      <NavLink component="span"
               key={o.value}
               label={o.label}
               active={identity?.owner?.id === Number(o.value)}
               onClick={() => {
                 switchOrganisation(o.value);
                 setMenu(false);
               }}
      />
    )}
  </>;

  return <>
    { identity?.owners?.length > 1 && <Box my="xs">
      <Divider />

      <Menu
        width={280}
        trigger={ isMobile ? 'click' : 'hover' }
        withinPortal={false}
        withArrow
        opened={!isMobile && opened}
        onChange={setOpened}
        position={ isMobile ? 'bottom-start' : 'right-start'}
        shadow="md"
      >
        <Menu.Target>
          <NavLink component="span"
                   leftSection={<IconSwitchHorizontal size={16} />}
                   rightSection={<IconChevronRight size={16} />}
                   label="Switch Organization" />
        </Menu.Target>
        <Menu.Dropdown>
          <Menu.Label>Organizations</Menu.Label>

          <Menu.Divider />

          <ScrollArea.Autosize mah={344}>
            <MenuItems />
          </ScrollArea.Autosize>
        </Menu.Dropdown>
      </Menu>

      <Divider />
    </Box>}

    <Drawer opened={isMobile && opened}
            onClose={() => setOpened(!opened)}
            position="bottom"
            title="Organizations"
            size="60%"
            zIndex={2000}
            trapFocus
    >
      <MenuItems />
    </Drawer>
  </>;
}
