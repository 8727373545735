import * as Yup from 'yup';
import { NumberInput } from '@mantine/core';

export default Yup.object().shape({
  name: Yup.string().required('Enter a name'),
  timezone: Yup.string().required('Select a timezone'),
  logo_path: Yup.string(),
  theme_color: Yup.string().required('Select a color'),
  country_code: Yup.string().required('Select a country code'),
  company_url_slug: Yup.string().matches(/^[a-zA-Z0-9\-\_]+$/ , 'Wrong format').required('Enter a url slug'),
  require_machine_id_title_flg: Yup.boolean(),
  require_description_flg: Yup.boolean(),
  require_image_flg: Yup.boolean(),
  require_machine_id_in_refund_flg: Yup.boolean(),
  require_photo_in_refund_flg: Yup.boolean(),
  phone_numbers: Yup.array().min(1, 'Enter at least one').test(
    'phone_numbers_valid',
    'Please ensure phone numbers are digits only and begin with a +',
    (item) => {
      try {
        const schema = Yup.array().of(Yup.string().matches(/^\+\d+$/ , 'All must start with a +'));
        schema.validateSync(item);
        return true;
      } catch(e) {
        return false;
      }
    }
  ),
  settings: Yup.object({
    'request_email_include-customer-in-reply-to': Yup.bool(),
    'request_email_additional-subject-line-references': Yup.array(),
    'machine-location_field_active': Yup.bool(),
    customers_confirmation_active: Yup.bool(),
    refunds_expiration_active: Yup.bool(),
    refunds_expiration_period: Yup.number().min(7).max(60),
    'refunds_expiration_auto-close-help': Yup.bool(),
  }),
})
