import { Box } from '@mantine/core';
import { CollapsableCard } from '@components/ui';

interface Props {
  json: string;
}

export const JsonCard = ({ json }: Props) => {
  return <CollapsableCard label="Payload" collapsable={false} style={{ width: '100%' }}>
    <Box style={{ overflow: 'hidden', overflowX: 'auto' }}>
      <pre style={{ fontSize: 12, lineHeight: 1.35 }}>{json}</pre>
    </Box>
  </CollapsableCard>;
}
