import { ITransactionListItem } from '@interfaces';
import { ResourceTable, ResourceTableProps } from '@components/table/ResourceTable';

import TransactionTableColumns from './TransactionTableColumns';
import TransactionTableExpandedRow from './TransactionTableExpandedRow';

export const TransactionTable = (props: ResourceTableProps<ITransactionListItem>) => {
  return <ResourceTable<ITransactionListItem>
    resource="transaction"
    columns={TransactionTableColumns}
    onRenderExpanded={TransactionTableExpandedRow}
    allowExports={false}
    {...props}
  />;
}
