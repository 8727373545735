import { ReactNode } from 'react';
import { ActionIcon, Box, Button, Flex, FocusTrap, Group, Transition } from '@mantine/core';
import { getHotkeyHandler, useClickOutside, useDisclosure } from '@mantine/hooks';
import { IconX } from '@tabler/icons-react';

type Props = {
  onConfirm: () => void;
  children: (onOpen: () => void) => ReactNode;
};

export const ConfirmAction = ({ onConfirm, children }: Props) => {
  const [opened, handlers] = useDisclosure(false);
  const ref = useClickOutside(() => handlers.close());

  const handleConfirm = () => {
    handlers.close();
    onConfirm();
  };

  return <Box ref={ref} style={{ position: 'relative', overflow: 'hidden' }}>
    <Box onClick={() => opened && handlers.close()}>
      { children(handlers.open) }
    </Box>

    <Transition
      mounted={opened}
      transition="slide-left"
      duration={400}
      timingFunction="ease"
    >
      {(styles) => <>
        <Flex style={{
          position: 'absolute',
          top: 0,
          right: 0,
          background: '#fff',
          height: '100%',
          alignItems: 'center',
          paddingLeft: 6,
          boxShadow: '0px 0px 25px 15px #fff',
          ...styles
        }}>
          <FocusTrap active={opened}>
            <Group wrap="nowrap" gap={5}>
              <Button variant="filled"
                      size="compact-xs"
                      onClick={handleConfirm}
                      onKeyDown={getHotkeyHandler([
                        ['Enter', handleConfirm],
                      ])}
              >
                Confirm
              </Button>

              <ActionIcon variant="default" size="sm">
                <IconX size={20} strokeWidth={1.5} onClick={handlers.close} />
              </ActionIcon>
            </Group>
          </FocusTrap>
        </Flex>
      </>}
    </Transition>
  </Box>;
}
