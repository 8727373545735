import { useEffect } from 'react';
import { useRouter } from 'next/router';
import {
  startNavigationProgress,
  resetNavigationProgress,
  NavigationProgress,
} from '@mantine/nprogress';

export function RouterTransition() {
  const router = useRouter();

  useEffect(() => {
    const handleStart = (url: string) => {
      try {
        if (typeof window !== 'undefined') {
          // Used for better go back functionality
          window.sessionStorage.setItem('previous_url', router.asPath);
        }
      } catch (e) {}

      return url !== router.asPath && startNavigationProgress();
    }
    const handleComplete = () => {
      // Needs to be delayed a little for some reason, otherwise subsequent tries doesn't reset the progress bar
      setTimeout(() => {
        resetNavigationProgress();
      }, 1);
    }

    router.events.on('routeChangeStart', handleStart);
    router.events.on('routeChangeComplete', handleComplete);
    router.events.on('routeChangeError', handleComplete);

    return () => {
      router.events.off('routeChangeStart', handleStart);
      router.events.off('routeChangeComplete', handleComplete);
      router.events.off('routeChangeError', handleComplete);
    };
  }, [router.asPath]);

  return <NavigationProgress />;
}
