import Link from 'next/link';
import { useNavigation } from "@refinedev/core";
import { Menu } from '@mantine/core';
import { IconEdit } from '@tabler/icons-react';
import { useActionMenu } from '@components/table/actions/ActionMenu.context';
import { useTableResource } from '@components/table/TableResource.context';

export const EditAction = () => {
  const { editUrl } = useNavigation();
  const { id } = useActionMenu();
  const { resource } = useTableResource();

  return <Link href={editUrl(resource, id)}>
    <Menu.Item
      color="blue"
      leftSection={<IconEdit size={18} stroke={1.5} />}
      closeMenuOnClick={true}
    >Edit</Menu.Item>
  </Link>;
}
