import { useNavigation } from "@refinedev/core";
import { useRouter } from 'next/router';

export const useGoBackToList = () => {
  const { route } = useRouter();
  const { list, listUrl, push } = useNavigation();

  const goBack = (resource: string) => {
    try {
      const previousUrl = window.sessionStorage.getItem('previous_url');

      if (previousUrl && previousUrl.includes(`resource=${resource}`)) {
        return push(previousUrl);
      }
    } catch (e) {}

    return list(resource);
  };

  const canGoBack = (resource: string) => {
    return listUrl(resource) !== route;
  };

  return {
    goBack,
    canGoBack,
  }
};
