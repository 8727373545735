import { RichTextEditor, useRichTextEditorContext } from '@mantine/tiptap';
import { IconArrowBackUp } from '@tabler/icons-react';

export function UndoControl() {
  const { editor } = useRichTextEditorContext();

  return (
    <RichTextEditor.Control title="Undo">
      <IconArrowBackUp strokeWidth={1} size={18} onClick={() => editor.commands.undo()} />
    </RichTextEditor.Control>
  );
}
