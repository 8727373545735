import { EuiInMemoryTable, EuiInMemoryTableProps } from '@elastic/eui';
import { WrapperTable } from '@components/table/WrapperTable';
import { ReactNode } from 'react';

export interface MemoryTableProps<T> {}

type Props<T> = {
  onRenderExpanded?: ({ item }) => ReactNode;
} & MemoryTableProps<T> & EuiInMemoryTableProps<T>;

export function MemoryTable<T>({ items, columns, ...props }: Props<T>) {
  return (
    <WrapperTable items={items} columns={columns} {...props}>
      {(options) =>
        <EuiInMemoryTable
            sorting={true}
            { ...options }
          />
      }
    </WrapperTable>
  );
}
