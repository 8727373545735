import { IWebhookLogListItem } from '@interfaces';
import { ResourceTable, ResourceTableProps } from '@components/table/ResourceTable';

import WebhookLogTableColumns from './WebhookLogTableColumns';
import WebhookLogTableExpandedRow from './WebhookLogTableExpandedRow';

export const WebhookLogTable = (props: ResourceTableProps<IWebhookLogListItem>) => {
  return <>
    <ResourceTable<IWebhookLogListItem>
      resource="webhook_log"
      columns={WebhookLogTableColumns}
      onRenderExpanded={WebhookLogTableExpandedRow}
      {...props}
    />
  </>;
}