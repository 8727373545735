import { Node } from '@tiptap/pm/model';
import { Decoration, DecorationSet } from '@tiptap/pm/view';

export default function (doc: Node, tokens: string[]): DecorationSet {
  const tokensRegex = new RegExp(tokens.join('|'), 'gi');
  const decorations: Decoration[] = [];

  doc.descendants((node, position) => {
    if (!node.text) {
      return;
    }

    Array.from(node.text.matchAll(tokensRegex)).forEach(match => {
      const token = match[0];
      const index = match.index || 0;
      const from = position + index;
      const to = from + token.length;

      const decoration = Decoration.inline(from, to, {
        nodeName: 'span',
        style: `border: 1px solid #dee2e6; border-radius: 5px; background-color: #f8f9fa; padding: 3px; font-size: 0.95em`,
      });

      decorations.push(decoration);
    })
  });

  return DecorationSet.create(doc, decorations);
}
