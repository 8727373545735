import { useApiUrl, useCustomMutation, useInvalidate } from '@refinedev/core';

export const useUnarchive = (resource: string) => {
  const apiUrl = useApiUrl();
  const invalidate = useInvalidate();
  const mutation = useCustomMutation();

  const unarchive = (id: number | string) => {
    return new Promise<void>((resolve, reject) => {
      mutation.mutate({
        method: 'put',
        url: `${apiUrl}/${resource}/unarchive/${id}`,
        values: { id },
      }, {
        onSuccess: () => {
          invalidate({ resource, invalidates: ['all'] })
          resolve();
        },
        onError: () => reject,
      });
    });
  };

  return {
    ...mutation,
    unarchive
  };
};
