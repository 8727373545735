import { Select, useMantineTheme } from '@mantine/core';
import { useEffect, useState } from 'react';
import { StatusIndicator } from '@components/ui/StatusIndicator';

interface Props {
  value: string;
  options: {
    value: string;
    label: string;
  }[];
  onChange?: (value: string) => void;
}

export const StatusSelect = ({ value, options, onChange }: Props) => {
  const [selected, setSelected] = useState(value);
  const theme = useMantineTheme();

  const handleOnChange = (value) => {
    setSelected(value);
    onChange && onChange(value);
  };

  useEffect(() => {
    setSelected(value);
  }, [value])

  return <Select
    value={selected}
    onChange={handleOnChange}
    leftSection={<StatusIndicator status={selected} includeWord={false} />}
    leftSectionWidth={32}
    styles={{
      input: { height: 35, minHeight: 35, borderColor: theme.colors.gray[2], textOverflow: 'ellipsis' },
    }}
    data={options}
  />
};
