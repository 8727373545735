import { useEffect, useState } from 'react';
import { EuiPopoverTitle, EuiSelectable, EuiSelectableOption } from '@elastic/eui';
import { useList } from '@refinedev/core';
import { FilterPopover } from '@components/filters/FilterPopover';
import { FilterProvider, useFilter } from '@components/filters/Filter.context';
import { IFilterOptionItem } from '@interfaces';

type Props = {
  label: string;
  options?: IFilterOptionItem[],
  route?: 'owners' | 'request_types' | 'machine_types' | 'refund_method_names' | 'tag_types'  | 'transaction_types' | 'tag_groups';
};

type FilterProps = {
  name: string;
  value?: string[]
} & Props;

const Filter = ({ label, options, route }: Props) => {
  const [items, setItems] = useState<EuiSelectableOption[]>([]);
  const { value, setValue } = useFilter<string[] | string>();

  const { data, isLoading } = useList<IFilterOptionItem>({
    resource: `filter/options/${route}`,
    queryOptions: {
      placeholderData: { data: options, total: options.length },
      enabled: !!route,
    }
  });

  useEffect(() => {
    const formattedOptions = (data?.data || []).map((v) => {
      const hasValue = [].concat(value)?.find(p => {
        return Number(value) ? Number(p) === Number(v.value) : String(p) === `${v.value}`;
      });

      return {
        label: v.label,
        data: v,
        checked: (hasValue ? 'on' : null) as any
      };
    });
    setItems(formattedOptions);
  }, [data, value]);

  const firstActiveItem = items.find((item) => item.checked === 'on');
  const isActive = !!firstActiveItem;
  const numActive = items.filter((item) => item.checked === 'on').length

  return <FilterPopover
    label={numActive === 1 ? firstActiveItem.label : label}
    buttonProps={{
      hasActiveFilters: isActive,
      numActiveFilters: numActive,
    }}
  >
    <EuiSelectable
        searchable
        searchProps={{
          placeholder: 'Filter list',
          compressed: true,
        }}
        aria-label={label}
        options={items}
        onChange={(newOptions) => {
          const selectedItems = newOptions.filter((item) => item.checked === 'on');
          const selectedValues = selectedItems.map(v => v.data.value);
          setValue(selectedValues.length ? selectedValues : null);
        }}
        isLoading={isLoading}
        loadingMessage="Loading options"
        noMatchesMessage="No options found"
      >
        {(list, search) => <>
          <EuiPopoverTitle paddingSize="s">{search}</EuiPopoverTitle>
          {list}
        </>}
      </EuiSelectable>
  </FilterPopover>
}

export const FilterSelect = ({ label, name, options = [], value = [], route }: FilterProps) => {
  return <FilterProvider name={name} defaultValue={value}>
    <Filter label={label} options={options} route={route} />
  </FilterProvider>
};
