import { useState } from 'react';
import { PillsInput, Pill, Combobox, useCombobox, MultiSelectProps, OptionsDropdown, getParsedComboboxData, useProps } from '@mantine/core';
import { useDidUpdate, useId } from '@mantine/hooks';
import { arrayMove, SortableContext, useSortable } from '@dnd-kit/sortable';
import { DndContext, DragOverlay, PointerSensor, useSensor } from '@dnd-kit/core';
import { filterPickedValues } from './filter-picked-values';

export const SortableMultiSelect = (_props: MultiSelectProps) => {
  const props = useProps('MultiSelect', {
    hidePickedOptions: true,
    searchable: false,
  }, _props);
  const _id = useId(props.id);

  const combobox = useCombobox({
    onDropdownClose: () => combobox.resetSelectedOption(),
    onDropdownOpen: () => combobox.updateSelectedOptionIndex('active'),
  });

  const [search, setSearch] = useState('');
  const [value, setValue] = useState<string[]>(props.value || []);
  const [activeOption, setActiveOption] = useState(null);

  useDidUpdate(() => {
    props.onChange(value);
  }, [value]);

  const handleDragStart = (event) => {
    setActiveOption(event.active.id);
  };

  const handleDragEnd = (event) => {
    const {active, over} = event;

    if (!active || !over) return;

    const oldIndex = value.indexOf(active?.id);
    const newIndex = value.indexOf(over?.id);

    setValue(arrayMove(value, oldIndex, newIndex));
    setActiveOption(null);
  };

  const pointerSensor = useSensor(PointerSensor, {
    activationConstraint: {
      distance: 5
    }
  });

  const DraggableValue = ({ value, children }) => {
    const { attributes, listeners, setNodeRef, transform, transition } = useSortable({id: value});

    const style = transform ? {
      transform: `translate3d(${transform.x}px, ${transform.y}px, 0)`,
      transition,
    } : undefined;

    const onMouseDown = (e) => {
      e.preventDefault();
      e.stopPropagation();
    };

    return <div ref={setNodeRef} style={style} {...attributes} {...listeners} onMouseDown={onMouseDown}>
      { children }
    </div>;
  };

  const handleValueSelect = (val: string) =>
    setValue((current) =>
      current.includes(val) ? current.filter((v) => v !== val) : [...current, val]
    );

  const handleValueRemove = (val: string) =>
    setValue((current) => current.filter((v) => v !== val));

  const values = value.map((item) => (
    <DraggableValue key={item} value={item}>
      <Pill withRemoveButton onRemove={() => handleValueRemove(item)}>
        {item}
      </Pill>
    </DraggableValue>
  ));

  const parsedData = getParsedComboboxData(props.data);
  const filteredData = filterPickedValues({ data: parsedData, value });

  return (
    <Combobox store={combobox} onOptionSubmit={handleValueSelect}>
      <Combobox.DropdownTarget>
        <PillsInput
          label={props.label}
          description={props.description}
          onClick={() => combobox.openDropdown()}
          rightSection={
            <Combobox.Chevron size={props.size} error={props.error} unstyled={props.unstyled} />
          }
        >
          <DndContext onDragStart={handleDragStart} onDragEnd={handleDragEnd} sensors={[pointerSensor]}>
            <DragOverlay>
              <Pill withRemoveButton>{ activeOption }</Pill>
            </DragOverlay>

            <Pill.Group>
              <SortableContext items={value}>
                {values}
              </SortableContext>

              <Combobox.EventsTarget>
                <PillsInput.Field
                  onFocus={() => combobox.openDropdown()}
                  onBlur={() => combobox.closeDropdown()}
                  value={search}
                  placeholder={props.placeholder}
                  onChange={(event) => {
                    combobox.updateSelectedOptionIndex();
                    setSearch(event.currentTarget.value);
                  }}
                  onKeyDown={(event) => {
                    if (event.key === 'Backspace' && search.length === 0) {
                      event.preventDefault();
                      handleValueRemove(value[value.length - 1]);
                    }
                  }}
                  readOnly={props.readOnly || !props.searchable}
                  pointer={!props.searchable}
                />
              </Combobox.EventsTarget>
            </Pill.Group>
          </DndContext>
        </PillsInput>
      </Combobox.DropdownTarget>

      <OptionsDropdown data={filteredData}
                       filter={props.filter}
                       filterOptions={props.searchable}
                       scrollAreaProps={props.scrollAreaProps}
                       search={search}
                       limit={props.limit}
                       withScrollArea={props.withScrollArea}
                       maxDropdownHeight={props.maxDropdownHeight}
                       unstyled={props.unstyled}
                       nothingFoundMessage={<Combobox.Empty>Nothing found...</Combobox.Empty>}
                       labelId={`${_id}-label`}
                       aria-label={props.label ? undefined : props['aria-label']}
      />
    </Combobox>
  );
};
