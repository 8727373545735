import { useState } from 'react';
import { Button, Menu } from '@mantine/core';
import { IconArrowsLeftRight } from '@tabler/icons-react';
import { useApiUrl, useCustom } from "@refinedev/core";
import { useFeature, withFeature } from 'flagged';

const equipmentSyncAction = () => {
  const hasVMSSync = useFeature('has_vms_sync');
  const [opened, setOpened] = useState(false);
  const [queryParam, setQueryParam] = useState({});

  const apiUrl = useApiUrl();
  const { refetch, isFetching } = useCustom({
    url: `${apiUrl}/integration/vms/trigger/sync`,
    method: 'get',
    config:{ query: queryParam },
    queryOptions: { enabled: false, retry: false }
  });

  const onSync = (type: string) => {
    setQueryParam({ type });

    // For some reason "queryParam" is not up to date when the refetch happens.
    setTimeout(async() => {
      await refetch();
    }, 100);
  };

  if (!hasVMSSync) {
    return <></>;
  }

  return (
    <Menu shadow="md" width={120} opened={opened} onChange={setOpened} withArrow>
      <Menu.Target>
        <Button loading={isFetching} leftSection={<IconArrowsLeftRight size={16} />}>Sync</Button>
      </Menu.Target>

      <Menu.Dropdown>
        <Menu.Item onClick={() => onSync('latest')}>Sync Latest</Menu.Item>
        <Menu.Divider />
        <Menu.Item onClick={() => onSync('all')}>Sync All</Menu.Item>
      </Menu.Dropdown>
    </Menu>
  );
}

export const EquipmentSyncAction = withFeature('owner')(equipmentSyncAction);
