import { Badge, BadgeProps, Tooltip } from '@mantine/core';

interface Props {
  locale: string;
  badgeProps?: BadgeProps
}

export const LanguageBadge = ({ locale, badgeProps }: Props) => {
  const preferredLanguage = () => {
    switch (locale) {
      case 'en': return 'English';
      case 'es': return 'Español';
      case 'fr': return 'French';
      default: return locale;
    }
  }

  const languageColor = () => {
    switch (locale) {
      case 'en': return 'blue';
      case 'es': return 'red';
      case 'fr': return 'green';
      default: return 'blue';
    }
  }

  if (!locale) {
    return <></>;
  }

  return <Tooltip label={`Customers preferred language is ${preferredLanguage()}`} withArrow
                  events={{ hover: true, focus: false, touch: true }}
  >
    <Badge variant="outline"
           c={languageColor()}
           size="sm" radius="sm" ml={4}
           style={{ verticalAlign: 'text-bottom' }}
           { ...badgeProps }
    >
      { preferredLanguage() }
    </Badge>
  </Tooltip>;
}
