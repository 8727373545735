import { IconPointFilled } from '@tabler/icons-react';

interface Props {
  color: string;
  size?: number;
}

export const Point = ({ color, size = 28 }: Props) => {
  return <IconPointFilled color={color} size={size} style={{ verticalAlign: 'middle' }} />
}
