import React, { forwardRef, useRef, useState } from 'react';
import { useDidUpdate } from '@mantine/hooks';
import { Dropzone, FileWithPath, IMAGE_MIME_TYPE } from '@mantine/dropzone';
import {
  Button,
  Input,
  InputProps,
  Stack,
  useMantineTheme,
  Box,
  Group,
  Image,
  ImageProps,
  Text,
  rem
} from '@mantine/core';
import convertFileToBase64 from '@libraries/convertFileToBase64';
import { IconPhoto } from '@tabler/icons-react';

interface Props extends InputProps {
  label?: string;
  value?: string;
  imageProps?: ImageProps;
  onChange: (value: string) => void;
}

export const ImageSelectorInput = forwardRef<HTMLImageElement, Props>((
  { label, value, onChange, error, imageProps = {}}, ref
) => {
  const theme = useMantineTheme();
  const openRef = useRef<() => void>(null);
  const [image, setImage] = useState<FileWithPath | string>(value);
  const [imageUrl, setImageUrl] = useState<string>(value);

  useDidUpdate(() => {
    if (image instanceof File) {
      setImageUrl(URL.createObjectURL(image));
    } else {
      setImageUrl(image);
    }
  }, [image]);

  const handleOnDrop = async (file: File) => {
    if (!file) return;

    setImage(file);

    if (file instanceof File) {
      onChange(await convertFileToBase64(file));
    }
  };

  const handleRemove = () => {
    setImage('');
    onChange('');
  };

  return <Box>
    { label && <Input.Label>{label}</Input.Label> }

    <Stack gap="xs">
      <Dropzone accept={IMAGE_MIME_TYPE}
                openRef={openRef}
                onDrop={(files) => handleOnDrop(files[0])}
                multiple={false}
                activateOnClick={true}
                p="xs"
                styles={{ inner: { pointerEvents: 'all' } }}
                style={{
                  borderColor: error ? theme.colors.red[5] : theme.colors.gray[4],
                  background: error ? theme.colors.red[0] : null,
                }}
      >
        <Stack align="center" gap="xs">
          { imageUrl && <Box>
            <Image radius={0} src={imageUrl} ref={ref} fit="contain" {...imageProps} />
          </Box>}


          {!imageUrl && <Group>
            <IconPhoto
              style={{ width: rem(36), height: rem(36), color: error ? 'var(--mantine-color-red-5)': 'var(--mantine-color-dimmed)' }}
              stroke={1.5}
            />
            <Text c={ error ? 'red.5' : 'dimmed'} inline>
              Drag image or click to select
            </Text>
          </Group>}

          { imageUrl && <Group>
            <Button variant="outline" size="sm">Change</Button>
            <Button variant="outline" size="sm" color="red" onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              handleRemove();
            }}>Remove</Button>
          </Group>}
        </Stack>
      </Dropzone>

      {error && <Input.Error>{error}</Input.Error>}
    </Stack>
  </Box>;
});
