import { useIdentity } from '@components/data/Identity.context';
import { useApiUrl, useCustomMutation } from '@refinedev/core';

export function useCheckZelleAlias() {
  const { owner } = useIdentity();
  const apiUrl = useApiUrl();
  const mutation = useCustomMutation();

  const checkZelleAlias = (enrollment_method, alias) => mutation.mutate({
    method: 'post',
    url: `${apiUrl}/owner/${owner.company_url_slug}/zelle/check_alias`,
    values: {enrollment_method, alias},
  });

  return {
    ...mutation,
    checkZelleAlias
  };
}
