import axios, { AxiosInstance } from 'axios';
import dataProvider from "@refinedev/simple-rest";
import { DataProvider, HttpError } from "@refinedev/core";
import { Query } from 'src/libraries/cogent';
import { IPaginatedResults } from '@interfaces';
import { NextApiRequestCookies } from 'next/dist/server/api-utils';

export const zippyDataProvider = (
  apiUrl: string,
  cookies: NextApiRequestCookies = null,
  httpClient?: AxiosInstance,
): DataProvider => {
  const axiosInstance = axios.create();

  httpClient = httpClient || axiosInstance;
  httpClient.defaults.withCredentials = true;
  httpClient.interceptors.request.use(
    async (request) => {
      // @ts-ignore
      request.headers = request?.headers || {};

      if (cookies) {
        request.headers['Referer'] = `${process.env.NEXT_PUBLIC_BASE_URL}`;
        request.headers['Cookie'] = `za_session=${cookies['za_session']};`;
      }

      return request;
    },
  );

  httpClient.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      const customError: HttpError = {
        ...error,
        message: error.response?.data?.message,
        statusCode: error.response?.status,
      };

      return Promise.reject(customError);
    },
  );

  return {
    ...dataProvider(apiUrl, httpClient),

    getMany: async ({ resource, ids }) => {
      const [endpoint, queryParams] = resource.split('?');
      const qs = new URLSearchParams(queryParams);
      let params = {};

      const query = new Query({ base_url: apiUrl }).for(endpoint);

      if (ids?.length) {
        params['id'] = ids;
      }

      if (qs.toString()) {
        params = {...Object.fromEntries(qs.entries()), ...params };
      }

      query.params(params);

      const { data } = await httpClient.get(query.url());

      return {
        data,
      };
    },

    getList: async ({ resource, pagination, filters, sorters }) => {
      const query = new Query({ base_url: apiUrl }).for(resource);

      query.page(pagination?.current || 1)
        .limit(pagination?.pageSize || 15);

      if (sorters?.length) {
        const sortDesc = sorters[0].order === 'desc' ? '-' : '';
        query.sort(sortDesc + sorters[0].field);
      }

      if (filters?.length) {
        filters.forEach((filter) => {
          if (Array.isArray(filter.value)) {
            query.whereIn(filter['field'], filter.value.map(encodeURIComponent));
          } else {
            query.where(filter['field'], encodeURIComponent(filter.value));
          }
        });
      }

      const { data: results } = await httpClient.get<IPaginatedResults<any>>(query.url());
      const { data, ...meta } = results;

      return {
        data: data ?? results as any,
        meta: data ? meta : null,
        total: data ? meta?.total : null,
      };
    },

    custom: async ({ url, method, filters, sorters, payload, query: params, headers }) => {
      const query = new Query({ base_url: url }).for('');

      if (filters?.length) {
        filters.forEach((filter) => {
          if (Array.isArray(filter.value)) {
            query.whereIn(filter['field'], filter.value.map(encodeURIComponent));
          } else {
            query.where(filter['field'], encodeURIComponent(filter.value));
          }
        });
      }

      if (params) {
        query.params(params);
      }

      if (headers) {
        // @ts-ignore
        httpClient.defaults.headers = {
          ...httpClient.defaults.headers,
          ...headers,
        };
      }

      let axiosResponse;
      switch (method) {
        case "put":
        case "post":
        case "patch":
          url = query.url().replace(/\/$/, '');
          axiosResponse = await httpClient[method](url, payload);
          break;
        case "delete":
          axiosResponse = await httpClient.delete(url);
          break;
        default:
          url = query.url().replace(/\/$/, '');
          axiosResponse = await httpClient.get(url);
          break;
      }

      const { data } = axiosResponse;

      return Promise.resolve({ data });
    },
  };
};
