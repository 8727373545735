import { CollapsableCard } from '@components/ui';
import { Text, Grid, SimpleGrid, TextInput, Anchor } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { useInvalidate, useUpdate } from '@refinedev/core';
import { IHelpRecord, IMachineTypeRecord, ITagListItem, ITagRecord } from '@interfaces';
import { useTableExpandedRowResource } from '@components/table/TableExpandedRowResource.context';
import { useFeatures } from 'flagged';
import { EquipmentLookupModal } from '@components/ui/modals/EquipmentSelectModal';
import { TagDetails } from '@components/data/TagDetails';

interface Props {
  tag?: ITagRecord;
  machine_code?: string;
  machine_type?: IMachineTypeRecord;
  collapsed?: boolean;
}

export const EquipmentCard = ({ tag, machine_code, machine_type, collapsed }: Props) => {
  const [opened, { open, close }] = useDisclosure(false);
  const invalidate = useInvalidate();
  const features = useFeatures();

  const { record, resource } = useTableExpandedRowResource<IHelpRecord>();
  const { mutate } = useUpdate();

  const updateTag = (tag: ITagListItem) => {
    if (!tag) return;

    mutate({ resource: 'request', id: record.request.id, values: { tag_id: tag.id } }, {
      onSuccess: () => {
        invalidate({ resource, invalidates: ['all'] });
      }
    });
  };

  let content = <Grid gutter="xs">
    <Grid.Col span={{ md: 6 }}>
      <TextInput label="Type" value={machine_type?.type} readOnly />
    </Grid.Col>

    <Grid.Col span={{ md: 6 }}>
      <TextInput label="Code" value={machine_code} readOnly />
    </Grid.Col>

    {features['request.update'] && record?.request && <>
      <Grid.Col span={{ md: 12 }}>
        <Text>This request is not currently linked to an equipment item in ZippyAssist. To link, <Anchor onClick={open}>click here</Anchor>.</Text>
      </Grid.Col>
    </>}
  </Grid>

  if (tag) {
    content = <TagDetails tag={tag} />
  }

  return <>
    <EquipmentLookupModal title="Select an Equipment"
                          opened={opened}
                          close={close}
                          onChange={updateTag} />

    <CollapsableCard label="Equipment information" collapsed={collapsed}>
      <SimpleGrid spacing="sm">
        {content}
      </SimpleGrid>
    </CollapsableCard>
  </>;
}
