import { IResourceComponentsProps, useNavigation } from '@refinedev/core';
import { IRequestLinkListResponse } from '@interfaces';
import { FilterBar } from '@components/filters/FilterBar';
import { FilterSelect } from '@components/filters/FilterSelect';
import { RequestLinksTable } from './table/RequestLinksTable';
import { TableFilterProvider } from '@components/table/TableFilter.context';
import { Feature } from 'flagged';
import { Button } from '@mantine/core';
import { FilterText } from '@components/filters/FilterText';

export function RequestLinkList({ initialData }: IResourceComponentsProps<IRequestLinkListResponse>) {
  const { list } = useNavigation();

  const actions = <>
    <Button onClick={() => list('customer')}>
      View customers
    </Button>
  </>;

  const filters = <>
    <Feature name="admin">
      <FilterSelect name="owners" label="Owners" route="owners" />
    </Feature>
    <FilterSelect name="type" label="Type" options={[
      { value: 'Help', label: 'Help' },
      { value: 'Refund', label: 'Refund' },
    ]} />
    <FilterText name="tag_identifier" label="Tag ID" />
  </>;

  return <TableFilterProvider>
    <FilterBar filters={filters} actions={actions} />
    <RequestLinksTable initialData={initialData}
                       allowExports={false}
                       syncWithLocation={true}
    />
  </TableFilterProvider>;
}
