const MESSAGE_MAP = {
  'sync': {
    failed: {
      color: 'red',
      autoClose: true,
      message: 'Sync failed.',
    },
    finished: {
      color: 'green',
      autoClose: true,
      message: 'Sync completed successfully',
    },
    queued: {
      color: 'gray',
      autoClose: false,
      message: 'Sync queued and starting soon.',
    },
    started: {
      color: 'blue',
      autoClose: false,
      loading: true,
      message: 'Sync started and in progress.'
    },
    updated: {
      color: 'blue',
      autoClose: false,
      loading: true,
      withCloseButton: false,
      message: (payload) => `Sync progress: ${payload.progress || 0} of ${payload.total}`
    },
  },
  'voice-reprocessing': {
    finished: {
      autoClose: 2000,
      message: 'Voice message reprocessing successful',
    },
    queued: {
      message: 'Voice message reprocessing queued and starting soon.',
    },
    started: {
      loading: true,
      message: 'Reprocessing voice message...'
    },
  },
  'demo-create': {
    failed: {
      message: 'Creating demo data failed.',
    },
    finished: {
      message: 'Created demo data successfully',
    },
    queued: {
      message: 'Demo create queued and starting soon.',
    },
    started: {
      loading: true,
      message: 'Creating demo data...'
    },
  },
  'demo-clear': {
    failed: {
      message: 'Clearing demo data failed.',
    },
    finished: {
      message: 'Cleared demo data successfully',
    },
    queued: {
      message: 'Demo clear queued and starting soon.',
    },
    started: {
      loading: true,
      message: 'Clearing demo data...'
    },
  }
}

export const notificationMessageOptions = (payload) => {
  const eventVerb = payload.verb;
  const eventType = payload.type;

  const notification = MESSAGE_MAP[eventType][eventVerb];
  const message = typeof notification.message === 'function' ? notification.message(payload) : notification.message;

  return { ...notification, message };
};
