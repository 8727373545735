import { ActionIcon, Burger, Group, Image, useMantineTheme } from '@mantine/core';
import { IconLogout } from '@tabler/icons-react';
import { useIdentity } from '@components/data/Identity.context';
import { useDidUpdate, useMediaQuery } from '@mantine/hooks';

type Props = {
  opened: boolean;
  setOpened: (v: any) => void
}

export function MobileHeader({ opened, setOpened }: Props) {
  const theme = useMantineTheme();
  const isMobile = useMediaQuery(`(max-width: ${theme.breakpoints.sm})`, false);
  const { logout } = useIdentity();

  useDidUpdate(() => {
    document.body.style.overflow = isMobile && opened ? 'hidden' : 'unset';
  }, [opened]);

  return <Group justify="space-between">
    <Burger
      opened={opened}
      onClick={() => setOpened((o) => !o)}
      size="sm"
      color={theme.colors.gray[6]}
    />

    <Image src="/images/zippy-logo.png" width={120} height={30} fit="contain" />

    <ActionIcon variant="default" size={30} onClick={() => logout()}>
      <IconLogout size={16} />
    </ActionIcon>
  </Group>;
}
