import { ReactNode } from 'react';
import { LogicalFilter, useOne } from "@refinedev/core";
import { Center, Loader } from '@mantine/core';
import { TableFilterContext, useTableFilters } from '@components/table/TableFilter.context';
import { ErrorBoundary } from '@components/ui/ErrorBoundary';
import { TableExpandedRowResourceContext, useTableExpandedRowResource } from '@components/table/TableExpandedRowResource.context';

type Props<T> = {
  resource: string;
  id: number;
  children: (record: T) => ReactNode
};

export function TableRow<T>({ resource, id, children }: Props<T>) {
  const { data, isLoading } = useOne<T>({ resource, id });
  const { filters: parentFilters } = useTableFilters();
  const parentResource = useTableExpandedRowResource();

  const dateFilter = parentFilters.filter(f => f.field === 'created_at');

  if (isLoading) {
    return <Center style={{ width: '100%' }}>
      <Loader />
    </Center>;
  }

  const filters = [{
    field: `${resource}_id`,
    value: id
  }, ...dateFilter] as LogicalFilter[];

  return <ErrorBoundary>
    <TableFilterContext.Provider value={{ filters, excludeDefaultFilters: true }}>
      <TableExpandedRowResourceContext.Provider value={{ resource, record: data?.data, parent: parentResource }}>
        {children(data?.data)}
      </TableExpandedRowResourceContext.Provider>
    </TableFilterContext.Provider>
  </ErrorBoundary>;
}
