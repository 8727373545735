import { CollapsableCard } from '@components/ui';
import { IRefundRecord } from '@interfaces';
import { SimpleReadOnlyForm } from '@components/data/forms/SimpleReadOnlyForm';

type Props = {
  refund: IRefundRecord;
}

export const PayoutDetailsCard = ({ refund }: Props) => {
  const fields = [];

  if (refund.payout?.first_name) {
    fields.push({ label: 'First Name', value: refund.payout.first_name });
  }

  if (refund.payout?.last_name) {
    fields.push({ label: 'Last Name', value: refund.payout.last_name });
  }

  if (refund.payout?.oan_card_number) {
    fields.push({ label: 'Card Number', value: refund.payout.oan_card_number });
  }

  if (refund.payout?.phone_number) {
    fields.push({ label: 'Mobile', value: refund.payout.phone_number });
  }

  if (refund.payout?.email) {
    fields.push({ label: 'Email', value: refund.payout.email });
  }

  if (refund.payout?.security_question) {
    fields.push({ label: 'Security Question', value: refund.payout.security_question });
  }

  if (refund.payout?.security_answer) {
    fields.push({ label: 'Security Answer', value: refund.payout.security_answer });
  }

  const isReversal = refund.method === 'Reversal';

  return <CollapsableCard label={`${isReversal ? (refund.payment_method.name || refund.refund_name) : refund.refund_name} details`}>
    <SimpleReadOnlyForm fields={fields} />
  </CollapsableCard>;
}
