import { EuiFieldSearch, EuiPopoverTitle } from '@elastic/eui';
import { FilterPopover } from './FilterPopover';
import { FilterProvider, useFilter } from '@components/filters/Filter.context';
import { DebounceInput } from 'react-debounce-input';

type Props = {
  label: string;
};

type FilterProps = {
  name: string;
  value?: string;
} & Props;

const Filter = ({ label }: Props) => {
  const { value, setValue } = useFilter<string>();

  return <FilterPopover label={label}>
    <EuiPopoverTitle paddingSize="s">
      <DebounceInput
        // @ts-ignore
        element={EuiFieldSearch}
        debounceTimeout={400}
        placeholder="Search..."
        isClearable={false}
        compressed={true}
        value={value}
        autoFocus={true}
        onChange={(e) => {
          setValue(e.target.value);
        }}
      />
    </EuiPopoverTitle>
  </FilterPopover>
}

export const FilterText = ({ label, name, value = '' }: FilterProps) => {
  return <FilterProvider name={name} defaultValue={value}>
    <Filter label={label} />
  </FilterProvider>
};
