import { Grid, Group, Loader, Stack, Text } from '@mantine/core';
import { IHelpRecord, IRefundRecord, IVoiceMessageListItem, IVoiceMessageRecord } from '@interfaces';
import { TableRow } from '@components/table/TableRow';
import { EquipmentCard } from '@components/data/cards/EquipmentCard';
import { Tabs } from '@components/ui/tabs/Tabs';
import { VoiceMessageSummaryCard } from '@components/data/cards/VoiceMessage/VoiceMessageSummaryCard';
import { CustomerCard } from '@components/data/cards/CustomerCard';
import { useOne } from '@refinedev/core';
import { ReasonCard } from '@components/data/cards/ReasonCard';
import { LocationCard } from '@components/data/cards/LocationCard';
import { NotesCard } from '@components/data/cards/NotesCard';
import { RefundRequestCard } from '@components/data/cards/RefundRequestCard/RefundRequestCard';
import { RefundHistoryCard } from '@components/data/cards/RefundHistoryCard';
import { PreAuthFollowUpCard } from '@components/data/cards/PreAuthFollowUpCard';
import { PaymentDetailsCard } from '@components/data/cards/PaymentDetailsCard';
import { PayoutDetailsCard } from '@components/data/cards/PayoutDetailsCard';
import { PostalAddressCard } from '@components/data/cards/PostalAddressCard';
import { AuditTable } from '@components/data/tables/AuditTable';

type Props = {
  item: IVoiceMessageListItem;
}

type TabContentProps = {
  record: IVoiceMessageRecord;
}

const TabContent = ({ record }: TabContentProps) => {
  const { data: help, isLoading: isHelpLoading } = useOne<IHelpRecord>({
    resource: 'help', id: record.request?.help?.id, queryOptions: { enabled: !!record.request?.help?.id }
  });

  const { data: refund, isLoading: isRefundLoading } = useOne<IRefundRecord>({
    resource: 'refund', id: record.request?.refund?.id, queryOptions: { enabled: !!record.request?.refund?.id }
  });

  return <Tabs variant="outline" defaultValue="details">
    <Tabs.List>
      <Tabs.Tab value="details">
        <Text size="sm" fw="500">Details</Text>
      </Tabs.Tab>

      { record.customer && <Tabs.Tab value="caller">
        <Text size="sm" fw="500">Caller</Text>
      </Tabs.Tab>}

      { record.request?.help && <Tabs.Tab value="help">
        <Group gap="xs">
          <Text size="sm" fw="500">Help</Text>
          { isHelpLoading && <Loader size={16} /> }
        </Group>
      </Tabs.Tab>}

      { record.request?.refund && <Tabs.Tab value="refund">
        <Group gap="xs">
          <Text size="sm" fw="500">Refund</Text>
          { isRefundLoading && <Loader size={16} /> }
        </Group>
      </Tabs.Tab>}
    </Tabs.List>

    <Tabs.Panel value="details" pt="xs">
      <Grid>
        <Grid.Col span={{ lg: 6 }}>
          <Stack>
            <VoiceMessageSummaryCard message={record} />
          </Stack>
        </Grid.Col>
        <Grid.Col span={{ lg: 6 }}>
          <Stack>
            {record.tag && <EquipmentCard tag={record.tag} />}
          </Stack>
        </Grid.Col>
      </Grid>
    </Tabs.Panel>

    { record.customer && <Tabs.Panel value="caller" pt="xs">
      <Grid>
        <Grid.Col span={{ lg: 6 }}>
          <Stack>
            <CustomerCard customer={record.customer} />
          </Stack>
        </Grid.Col>
      </Grid>
    </Tabs.Panel>}

    <Tabs.Panel value="help" pt="xs">
      { help?.data &&
        <Grid>
          <Grid.Col span={{ lg: 6 }}>
            <Stack>
              <ReasonCard help={help.data} />
              <EquipmentCard machine_type={help.data.machine_type}
                             machine_code={help.data.machine_code}
                             tag={help.data.tag} />
              <LocationCard address={help.data.request.address} />
            </Stack>
          </Grid.Col>

          <Grid.Col span={{ lg: 6 }}>
            <NotesCard request={help.data.request} />
          </Grid.Col>
        </Grid>
      }
    </Tabs.Panel>

    { refund?.data && <>
      <Tabs.Panel value="refund" pt="xs">
        <Grid>
          <Grid.Col span={{ lg: 6 }}>
            <Stack>
              <RefundRequestCard refund={refund.data} />
              <RefundHistoryCard refund={refund.data} />
            </Stack>
          </Grid.Col>

          <Grid.Col span={{ lg: 6 }}>
            <Stack>
              { refund.data.status === 'Draft' && refund.data.meta.follow_up_scheduled_at && <PreAuthFollowUpCard refund={refund.data} /> }
              { refund.data.payment_method && <PaymentDetailsCard refund={refund.data} /> }
              { refund.data.status !== 'Draft' && refund.data.payout && <PayoutDetailsCard refund={refund.data} /> }
              { refund.data.address && <>
                <PostalAddressCard address={refund.data.address}/>
                <LocationCard address={refund.data.address?.address} label="Check address"/>
              </>}
              <NotesCard request={refund.data.request} />
            </Stack>
          </Grid.Col>
        </Grid>
      </Tabs.Panel>
      <Tabs.Panel value="refund_logs" pt="xs">
        <AuditTable audits={refund.data.audits} />
      </Tabs.Panel>
    </>}
  </Tabs>;
}

export default ({ item }: Props) => {
  return <TableRow<IVoiceMessageRecord> resource="voice_message" id={item.id}>
    {(record) => <TabContent record={record} />}
  </TableRow>;
}
