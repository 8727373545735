import { Button } from '@mantine/core';
import { FilterProvider, useFilter } from '@components/filters/Filter.context';

type Props = {
  label: string;
};

type FilterProps = {
  name: string;
  value?: boolean;
} & Props;

const Filter = ({ label }: Props) => {
  const { value, setValue } = useFilter<boolean>();

  const variant = value ? 'filled' : 'default';
  return <Button variant={variant}
                 styles={{
                   root: { height: 38 },
                   label: { fontSize: 14, fontWeight: 500 },
                 }}
                 onClick={() => {
                   setValue(!value ? true : null)
                 }}
  >{ label }</Button>;
}

export const FilterToggle = ({ label, name, value = null }: FilterProps) => {
  return <FilterProvider name={name} defaultValue={value}>
    <Filter label={label} />
  </FilterProvider>
};
