import { IResourceComponentsProps, useNavigation } from "@refinedev/core";
import { EuiBasicTableColumn } from '@elastic/eui';
import { IWebhookListItem, IWebhookListResponse } from '@interfaces';
import { ResourceTable } from '@components/table/ResourceTable';
import { Badge, Button, Menu } from '@mantine/core';
import { IconPlus } from '@tabler/icons-react';
import { FilterSelect } from '@components/filters/FilterSelect';
import { FilterBar } from '@components/filters/FilterBar';
import { TableFilterProvider } from '@components/table/TableFilter.context';
import { useFeature } from 'flagged';
import { DateTime } from '@components/ui/DateTime';
import { EditAction } from '@components/table/actions/EditAction';
import { StatusIndicator } from '@components/ui/StatusIndicator';
import { DeleteAction } from '@components/table/actions/DeleteAction';
import { ActionMenu } from '@components/table/actions/ActionMenu';

export function WebhookList({ initialData }: IResourceComponentsProps<IWebhookListResponse>) {
  const { create, list } = useNavigation();
  const isAdmin = useFeature('admin');

  const columns = [
    {
      field: 'owner_name',
      name: 'Owner',
      width: '150',
      sortable: true,
      truncateText: true,
      permissions: ['admin']
    },
    {
      field: 'name',
      name: 'Name',
      sortable: true,
      truncateText: true,
    },
    {
      field: 'event_type',
      name: 'Event Type',
      sortable: true,
      truncateText: true,
      render: (event_type) => <Badge style={{ textTransform: 'none' }} p="xs">{event_type}</Badge>,
    },
    // {
    //   field: 'version',
    //   name: 'Version',
    //   sortable: true,
    //   truncateText: true,
    // },
    {
      field: 'status',
      name: 'Status',
      sortable: true,
      truncateText: true,
      render: (status) => <StatusIndicator status={status} />,
    },
    {
      field: 'created_at',
      name: 'Created',
      width: '165',
      sortable: true,
      dataType: 'date',
      render: (date) => <DateTime value={date} />
    },
    {
      name: '',
      width: '40',
      actions: [
        {
          render: (record) => <ActionMenu id={record.id}>
            <EditAction />
            <Menu.Divider />
            <DeleteAction target={record.name} />
          </ActionMenu>
        },
      ]
    },
  ] as EuiBasicTableColumn<IWebhookListItem>[];

  const actions = <>
    <Button leftSection={<IconPlus size={16} />}
            onClick={() => create('webhook')}>
      Add webhook
    </Button>

    <Button onClick={() => list('webhook_logs')}>
      View logs
    </Button>
  </>;

  const filters = isAdmin ? <FilterSelect name="owners" label="Owner" route="owners" /> : null;

  return <TableFilterProvider>
    <FilterBar actions={actions} filters={filters} />
    <ResourceTable resource="webhook"
                   initialData={initialData}
                   columns={columns}
                   allowExports={false}
                   syncWithLocation={true} />
  </TableFilterProvider>;
}
