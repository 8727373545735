import { RichTextEditor, useRichTextEditorContext } from '@mantine/tiptap';
import { Menu } from '@mantine/core';
import { IconTags } from '@tabler/icons-react';

export function TokensControl({ tokens }: { tokens : string[] }) {
  const { editor } = useRichTextEditorContext();

  const handleClick = (token) => {
    editor.commands.insertContent(token);
  };

  return (
    <Menu shadow="md" width={220} offset={6}>
      <Menu.Target>
        <RichTextEditor.Control>
          <IconTags strokeWidth={1} size={18} />
        </RichTextEditor.Control>
      </Menu.Target>

      <Menu.Dropdown mah={210} style={{ overflowY: 'auto' }}>
        { tokens.map(((t, i) => (
          <Menu.Item key={i} onClick={() => handleClick(t)}>{ t }</Menu.Item>
        )))}
      </Menu.Dropdown>
    </Menu>
  );
}
