import { Badge, Group, Loader, Text } from '@mantine/core';
import {
  ITagListItem,
  ITagRecord,
  IFeedbackListItem,
  IHelpListItem,
  IRefundListItem,
  IVoiceMessageListItem
} from '@interfaces';
import { LogicalFilter, useList } from '@refinedev/core';
import { HelpTable } from '../../help/table/HelpTable';
import { FeedbackTable } from '../../feedback/table/FeedbackTable';
import { RefundTable } from '../../refund/table/RefundTable';
import { Tabs } from '@components/ui/tabs/Tabs';
import { ErrorBoundary } from '@components/ui/ErrorBoundary';
import { TableFilterContext, useTableFilters } from '@components/table/TableFilter.context';
import { VoiceMessageTable } from '../../voice_message/table/VoiceMessageTable';

type Props = {
  item: ITagListItem;
}

type TabContentProps = {
  record: ITagRecord;
}

const TabContent = ({ record }: TabContentProps) => {
  const { filters: parentFilters } = useTableFilters();

  const createdAtFilter = parentFilters.filter((item) => item.field === 'created_at');

  const filters = [{
    field: `tag_id`,
    value: record['tag_id_list'],
  }, ...createdAtFilter] as LogicalFilter[];

  const voiceMessageFilters = [{ field: 'is_archived', value: '*' }, ...filters]  as LogicalFilter[];

  const { data: helpResults, isLoading: isHelpLoading } = useList<IHelpListItem>({
    resource: 'help',
    filters
  });
  const { data: feedbackResults, isLoading: isFeedbackLoading } = useList<IFeedbackListItem>({
    resource: 'feedback',
    filters
  });
  const { data: refundResults, isLoading: isRefundLoading } = useList<IRefundListItem>({
    resource: 'refund',
    filters
  });
  const { data: voiceResults, isLoading: isVoiceLoading } = useList<IVoiceMessageListItem>({
    resource: 'voice_message',
    filters: voiceMessageFilters,
  });

  return <Tabs variant="outline" defaultValue="help">
    <Tabs.List>
      <Tabs.Tab value="help">
        <Group gap="xs">
          <Text size="sm" fw="500">Help</Text>
          { isHelpLoading ? <Loader size={16} /> : <Badge>{helpResults.total}</Badge>}
        </Group>
      </Tabs.Tab>

      <Tabs.Tab value="voice_messages">
        <Group gap="xs">
          <Text size="sm" fw="500">Calls</Text>
          { isVoiceLoading ? <Loader size={16} /> : <Badge>{voiceResults.total}</Badge>}
        </Group>
      </Tabs.Tab>

      <Tabs.Tab value="feedback">
        <Group gap="xs">
          <Text size="sm" fw="500">Feedback</Text>
          { isFeedbackLoading ? <Loader size={16} /> : <Badge>{feedbackResults.total}</Badge>}
        </Group>
      </Tabs.Tab>

      <Tabs.Tab value="refunds">
        <Group gap="xs">
          <Text size="sm" fw="500">Refunds</Text>
          { isRefundLoading ? <Loader size={16} /> : <Badge>{refundResults.total}</Badge>}
        </Group>
      </Tabs.Tab>
    </Tabs.List>

    <Tabs.Panel value="help" pt="xs">
      { helpResults && <TableFilterContext.Provider value={{ filters, excludeDefaultFilters: true }}>
        <HelpTable initialData={helpResults} excludeColumns={['owner_name', 'id']} />
      </TableFilterContext.Provider>}
    </Tabs.Panel>

    <Tabs.Panel value="feedback" pt="xs">
      { feedbackResults && <TableFilterContext.Provider value={{ filters, excludeDefaultFilters: true }}>
        <FeedbackTable initialData={feedbackResults} excludeColumns={['owner_name', 'id']} />
      </TableFilterContext.Provider>}
    </Tabs.Panel>

    <Tabs.Panel value="refunds" pt="xs">
      { refundResults && <TableFilterContext.Provider value={{ filters, excludeDefaultFilters: true }}>
        <RefundTable initialData={refundResults} excludeColumns={['owner_name', 'id']} />
      </TableFilterContext.Provider>}
    </Tabs.Panel>

    <Tabs.Panel value="voice_messages" pt="xs">
      { voiceResults && <TableFilterContext.Provider value={{ filters: voiceMessageFilters, excludeDefaultFilters: true }}>
        <VoiceMessageTable initialData={voiceResults} excludeColumns={['owner_name']} />
      </TableFilterContext.Provider>}
    </Tabs.Panel>
  </Tabs>;
}

export default ({ item }: Props) => {
  return <ErrorBoundary>
    <TabContent record={item as any} />
  </ErrorBoundary>;
}
