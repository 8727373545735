import dayjs from 'dayjs';
import ReactTimeAgo from 'react-time-ago';
import { Text, Tooltip } from '@mantine/core';

interface Props {
  date?: string;
}

export const TimeAgoTooltip = ({ date }: Props) => {
  if (!date) return <Text c="dimmed">&mdash;</Text>;

  const datetime = dayjs(date);

  return <Tooltip label={datetime.format('ddd, MMM D YYYY [at] h:mm A')} openDelay={300} withArrow>
    <div><ReactTimeAgo date={datetime.toDate()} tooltip={false} /></div>
  </Tooltip>;
}
