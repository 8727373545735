import { Context, createContext, PropsWithChildren, useContext } from 'react';
import type { UseFormReturnType } from '@mantine/form/lib/types';

type ResourceFormContextProps<T = unknown> = {
  form: UseFormReturnType<T>;
  submit?: (values: T) => T;
  isSaving?: boolean;
};

export const ResourceFormContext = createContext<ResourceFormContextProps>(null);

export const ResourceFormProvider = ({form, isSaving = false, submit, children }: PropsWithChildren<ResourceFormContextProps>) => {
  return <ResourceFormContext.Provider value={{ form, isSaving, submit }}>
    { children }
  </ResourceFormContext.Provider>
};

export function useResourceForm<T>() {
  return useContext<ResourceFormContextProps<T>>(ResourceFormContext as Context<ResourceFormContextProps<T>>);
}
