import { useApiUrl, useCustomMutation, useInvalidate } from '@refinedev/core';

export const useMergeTag = () => {
  const apiUrl = useApiUrl();
  const mutation = useCustomMutation();

  const invalidate = useInvalidate();

  const mergeTag = (from_tag_id: number, to_tag_id: number) => mutation.mutate({
    method: 'put',
    url: `${apiUrl}/tag/merge`,
    values: { from_tag_id, to_tag_id },
  }, {
    onSuccess: () => invalidate({ resource: 'tag', invalidates: ['all'] }),
  });

  return {
    ...mutation,
    mergeTag
  };
};
