
import { Button, Text } from '@mantine/core'
import { useApiUrl, useCustom, useInvalidate } from '@refinedev/core';
import { useFeature } from 'flagged';
import { useTableFilters } from '@components/table/TableFilter.context';
import { confirmModal } from '@components/ui/modals/ConfirmModal';

type Props = {
  label: string;
};

export const BulkHelpCloseAction = ({ label }: Props) => {
  const { filters } = useTableFilters();

  const isAdmin = useFeature('admin');

  const apiUrl = useApiUrl();
  const { refetch, isFetching } = useCustom({
    url: `${apiUrl}/help/bulk_close`,
    method: 'put',
    config:{ filters },
    queryOptions: { enabled: false, retry: false }
  });
  const invalidate = useInvalidate();

  const handleConfirm = async () => {
    await refetch();
    await invalidate({ resource: 'help', invalidates: ['all'] });
  };

  const triggerConfirm = () => {
    confirmModal({
      children: <Text>Only filtered requests will be marked Closed if filters are applied.</Text>,
      onConfirm: handleConfirm
    });
  }

  if (isAdmin) {
    return <></>;
  }

  return <>
    <Button size="xs" variant="light" onClick={triggerConfirm} loading={isFetching}>
      { label }
    </Button>
  </>;
};
