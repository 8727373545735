import { useApiUrl, useCustomMutation } from '@refinedev/core';
import { useState } from 'react';
import { useResourceForm } from '@components/ui/form/ResourceForm.context';
import { IIntegrationRecord } from '@interfaces';

export const useConnectionGuard = () => {
  const apiUrl = useApiUrl();
  const { mutate: checkConnection, isLoading: isCheckingConnection } = useCustomMutation();
  const { form: parentForm } = useResourceForm<IIntegrationRecord>();
  const [ hasConnection, setHasConnection ] = useState<boolean>(!!parentForm.values.credentials.api_key && !!parentForm.values.credentials.api_token);

  const handleCheckConnection = (values) => {
    checkConnection({
      url: `${apiUrl}/integration/check_connection`,
      method: 'post',
      values: {
        ...values,
        platform: parentForm.values.platform,
        owner_id: parentForm.values.owner_id,
      },
      successNotification: { type: 'success', message: 'Connection is working.' },
      errorNotification: { type: 'error', message: 'Connection is not working.' }
    }, {
      onSuccess: () => setHasConnection(true),
      onError: () => setHasConnection(false),
    });
  };

  return {
    checkConnection: handleCheckConnection,
    isCheckingConnection,
    hasConnection,
  }
};
