import { IResourceComponentsProps, useNavigation } from '@refinedev/core';
import { EuiBasicTableColumn } from '@elastic/eui';
import { IPaymentMethodListItem, IPaymentMethodListResponse } from '@interfaces';
import { ResourceTable } from '@components/table/ResourceTable';
import { Button, Drawer, Menu } from '@mantine/core';
import { IconPlus, IconArrowsSort } from '@tabler/icons-react';
import { FilterSelect } from '@components/filters/FilterSelect';
import { FilterBar } from '@components/filters/FilterBar';
import { TableFilterProvider } from '@components/table/TableFilter.context';
import { Feature, useFeatures } from 'flagged';
import { DateTime } from '@components/ui/DateTime';
import { EditAction } from '@components/table/actions/EditAction';
import { StatusIndicator } from '@components/ui/StatusIndicator';
import { useDisclosure } from '@mantine/hooks';
import { ResourceSortable } from '@components/ui/ResourceSortable';
import { PreAuthSettingsForm } from './form/PreAuthSettingsForm';
import { DeleteAction } from '@components/table/actions/DeleteAction';
import { ActionMenu } from '@components/table/actions/ActionMenu';

export function PaymentMethodList({ initialData }: IResourceComponentsProps<IPaymentMethodListResponse>) {
  const { create } = useNavigation();
  const [opened, { open, close }] = useDisclosure(false);
  const features = useFeatures();

  const columns = [
    {
      field: 'owner_name',
      name: 'Owner',
      width: '150',
      sortable: true,
      truncateText: true,
      permissions: ['admin']
    },
    {
      field: 'name',
      name: 'Name',
      width: '180',
      render: (name, record) => name || record.type
    },
    {
      field: 'pre_auth_refund_method_type',
      name: 'Refund Type',
      width: '180',
      render: (refund_type) => refund_type
    },
    {
      field: 'created_at',
      name: 'Created',
      width: '165',
      sortable: true,
      dataType: 'date',
      render: (date) => <DateTime value={date} />
    },
    {
      field: 'is_active',
      name: 'Status',
      width: '60',
      sortable: true,
      truncateText: true,
      render: (status) => <StatusIndicator status={status ? 'Enabled' : 'Disabled'} />,
    },
    {
      name: '',
      width: '40',
      actions: [
        {
          render: (record) => <ActionMenu id={record.id}>
            <EditAction />
            <Menu.Divider />
            <DeleteAction target={record.name} />
          </ActionMenu>
        },
      ]
    },
  ] as EuiBasicTableColumn<IPaymentMethodListItem>[];

  const actions = <>
    <Button leftSection={<IconPlus size={16} />}
            onClick={() => create('payment_method')}>
      Add payment method
    </Button>

    <Feature name="owner">
      <Button leftSection={<IconArrowsSort size={16} />}
              onClick={open}>
        Sort
      </Button>
    </Feature>
  </>;

  const filters = features.admin ? <FilterSelect name="owners" label="Owner" route="owners" /> : null;

  return <TableFilterProvider>
    <FilterBar actions={actions} filters={filters} />

    { features['settings.refunds'] && <>
      <PreAuthSettingsForm />
    </>}

    <ResourceTable resource="payment_method"
                   initialData={initialData}
                   columns={columns}
                   allowExports={false}
                   syncWithLocation={true} />

    <Drawer
      opened={opened}
      onClose={close}
      withCloseButton={false}
      position="left"
    >
      <ResourceSortable closeDrawer={close} />
    </Drawer>
  </TableFilterProvider>;
}
