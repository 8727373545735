import dayjs from 'dayjs';
import { Text } from '@mantine/core';

interface Props {
  value?: string;
}

export const Date = ({ value }: Props) => {
  if (!value) return <Text c="dimmed">&mdash;</Text>;

  const datetime = dayjs(value);

  return <>{datetime.format('D MMM \'YY')}</>
}
