import React from 'react';
import { Group, Box, Stack, Text } from '@mantine/core';
import cx from 'clsx';
import { ColorSwatch } from './ColorSwatchInput';

import classes from './ColorPreview.module.css';

interface ComponentsPreviewProps {
  colors: ColorSwatch[];
}

export function ColorPreview({ colors }: ComponentsPreviewProps) {
  return (
    <>
      <Group gap={0} grow className={classes.wrapper}>
        {
          colors.map((s, i) => {
            return <Box key={i}
                        style={{ background: s.hex, color: s.color }}
                        py={20}
                        className={cx({[classes.active]: s.isActive })}
            >
              <Stack align="center" gap={0} visibleFrom="sm">
                <Text size="lg" fw={700}>{ s.title }</Text>
                <Text size="xs" fw={600}>{ s.hex }</Text>
              </Stack>
            </Box>
          })
        }
      </Group>
    </>
  );
}
