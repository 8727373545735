import { useEffect, useMemo, useState } from 'react';
import {
  EuiButtonEmpty, EuiCommonlyUsedTimeRanges, EuiDatePicker, EuiDatePickerRange, EuiFormControlLayout, EuiIcon, EuiPopover
} from '@elastic/eui';
import moment, { Moment } from 'moment-timezone';

interface Props {
  onDateChange: (values: Moment[] | string[] | null) => void;
  defaultStartDate?: Moment | string;
  defaultEndDate?: Moment | string;
  hideClearButton?: boolean;
}

export const DatePicker = ({ onDateChange, defaultStartDate, defaultEndDate, hideClearButton = false }: Props) => {
  const [allowDateEntry, setAllowDateEntry] = useState<boolean>(Boolean(defaultStartDate && defaultEndDate));
  const [quickSelectOpened, setQuickSelectOpened] = useState(false);
  const [startDate, setStartDate] = useState(defaultStartDate ? moment(defaultStartDate) : null);
  const [endDate, setEndDate] = useState(defaultEndDate ? moment(defaultEndDate) : null);

  const minDate = moment('2020-01-01').startOf('day');
  const maxDate = moment().endOf('day');

  useEffect(() => {
    if (!defaultStartDate && !defaultEndDate && startDate && endDate) {
      setTimeRange({ start: null, end: null });
    }
  }, [defaultStartDate, defaultEndDate]);

  const setTimeRange = ({ start, end }) => {
    setQuickSelectOpened(false);

    let startDate = null;
    let endDate = null;

    if (start && end) {
      startDate = moment(start).startOf('day');
      endDate = moment(end).endOf('day');
      setAllowDateEntry(true);
    } else {
      setAllowDateEntry(false);
    }

    setStartDate(startDate);
    setEndDate(endDate);

    if (startDate && endDate) {
      onDateChange([startDate, endDate]);
    } else {
      onDateChange(null);
    }
  };

  const isInvalid = startDate > endDate || startDate < minDate || endDate > maxDate;

  const commonlyUsedRanges = useMemo(() => {
    const now = moment();

    return [
      {
        start: now.format(),
        end: now.format(),
        label: 'Today'
      },
      {
        start: now.clone().subtract(1, 'day').format(),
        end: now.clone().subtract(1, 'day').format(),
        label: 'Yesterday'
      },
      {
        start: now.clone().startOf('isoWeek').format(),
        end: now.format(),
        label: 'This week'
      },
      {
        start: now.clone().startOf('month').format(),
        end: now.format(),
        label: 'This month'
      },
      {
        start: now.clone().subtract(1, 'week').startOf('isoWeek').format(),
        end: now.clone().subtract(1, 'week').endOf('isoWeek').format(),
        label: 'Last week'
      },
      {
        start: now.clone().subtract(6, 'days').format(),
        end: now.format(),
        label: 'Last 7 days'
      },
      {
        start: now.clone().subtract(1, 'month').startOf('month').format(),
        end: now.clone().subtract(1, 'month').endOf('month').format(),
        label: 'Last month'
      },
      {
        start: now.clone().subtract(30, 'day').format(),
        end: now.format(),
        label: 'Last 30 days'
      },
      {
        start: now.clone().subtract(3, 'months').format(),
        end: now.format(),
        label: 'Last 3 months'
      },
      {
        start: now.clone().subtract(12, 'months').format(),
        end: now.format(),
        label: 'Last year'
      },
    ]
  }, []);

  const renderDatePickerRange = () => {
    if (!allowDateEntry) {
      return <EuiDatePickerRange
        className="euiDatePickerRange--inGroup"
        iconType={false}
        isCustom
        startDateControl={<div />}
        endDateControl={<div />}
      >
        <button
          className="euiSuperDatePicker__prettyFormat"
          onClick={() => {
            setAllowDateEntry(true);
            setTimeRange({
              start: commonlyUsedRanges[3].start,
              end: commonlyUsedRanges[3].end
            });
          }}
        >
          Select date
        </button>
      </EuiDatePickerRange>;
    }

    return <EuiDatePickerRange
      iconType={false}
      isInvalid={isInvalid}
      startDateControl={
        <EuiDatePicker
          selected={startDate}
          minDate={minDate}
          maxDate={maxDate}
          onChange={(date) => setTimeRange({ start: date.startOf('day'), end: endDate })}
          dateFormat="DD MMM yyyy"
        />
      }
      endDateControl={
        <EuiDatePicker
          selected={endDate}
          minDate={minDate}
          maxDate={maxDate}
          onChange={(date) => setTimeRange({ start: startDate, end: date.endOf('day') })}
          dateFormat="DD MMM yyyy"
        />
      }
    />;
  }

  const renderClearDateButton = () => {
    if (!allowDateEntry || hideClearButton) {
      return <></>;
    }

    return <EuiButtonEmpty
      onClick={() => setTimeRange({ start: null, end: null })}
      size="xs"
    >
      <EuiIcon type="cross" />
    </EuiButtonEmpty>;
  }

  return <EuiFormControlLayout
    className="euiSuperDatePicker"
    prepend={
      <EuiPopover
        button={
          <EuiButtonEmpty
            onClick={() => setQuickSelectOpened(!quickSelectOpened)}
            size="xs"
            iconType="arrowDown"
            iconSide="right"
          >
            <EuiIcon type="calendar" />
          </EuiButtonEmpty>
        }
        isOpen={quickSelectOpened}
        closePopover={() => setQuickSelectOpened(false)}
        anchorPosition="downLeft"
      >
        <div className="euiQuickSelectPopover__content">
          <EuiCommonlyUsedTimeRanges
            applyTime={setTimeRange}
            commonlyUsedRanges={commonlyUsedRanges}
          />
        </div>
      </EuiPopover>
    }
    append={renderClearDateButton()}
  >
    { renderDatePickerRange() }
  </EuiFormControlLayout>;
}
