import { Button, Group, PasswordInput, Stack, Text } from '@mantine/core';
import { ContextModalProps } from '@mantine/modals';
import * as Yup from 'yup';
import { useForm, yupResolver } from '@mantine/form';
import { useApiUrl, useCustomMutation } from '@refinedev/core';

type Props = {
  success: () => void;
  error: (message: string) => void;
}

const schema = Yup.object().shape({
  password: Yup.string().required('Enter a password'),
});

export const ConfirmPasswordModal = ({ context, id, innerProps }: ContextModalProps<Props>) => {
  const apiUrl = useApiUrl();
  const { mutate: confirmPassword, isLoading: isConfirmingPassword } = useCustomMutation();

  const form = useForm({
    validate: yupResolver(schema),
    initialValues: {
      password: ''
    },
  });

  const handleSubmit = async (values) => {
    confirmPassword({ url: `${apiUrl}/user/confirm-password`, method: 'post', values }, {
      onSuccess: () => {
        context.closeModal(id);
        innerProps.success();
      },
      onError: ({ response }) => {
        form.setFieldError('password', response.data?.errors?.password);
      },
    });
  }

  const handleCancel = () => {
    context.closeModal(id);
    innerProps.error('Cancelled password confirmation');
  }

  return <>
    <form onSubmit={form.onSubmit(handleSubmit)}>
      <Stack>
        <Text size="sm">For your security, please confirm your password to continue.</Text>

        <PasswordInput label="Password"
                       placeholder="Your password"
                       {...form.getInputProps('password')} />

        <Group justify="right">
          <Button variant="light" color="gray" onClick={() => handleCancel()}>
            Cancel
          </Button>
          <Button type="submit" variant="light" loading={isConfirmingPassword}>
            Confirm
          </Button>
        </Group>
      </Stack>
    </form>
  </>;
};
