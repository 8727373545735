import { Button, Checkbox, Divider, Grid, Group, Select, Stack } from '@mantine/core';
import { useForm, yupResolver } from '@mantine/form';
import * as Yup from 'yup';
import { useInvalidate } from '@refinedev/core';
import { IHelpRecord } from '@interfaces';
import { CustomerCard } from '@components/data/cards/CustomerCard';
import { TextareaAutosize } from '@components/ui/TextareaAutosize';
import { useRequestTypeOptions, useUpdateOrCreate } from '@components/hooks';
import { ImageSelectorInput } from '@components/ui/imageSelectorInput/ImageSelectorInput';
import { useRequestState } from '@components/ui/drawers/Requests/RequestState.context';
import { useDrawers } from '@components/ui/drawerManager';
import { TagAlert, TagDetailsCard, TagSelector } from '@components/ui/drawers/Requests/TagComponents';

export const HelpRequestForm = () => {
  const { closeAll } = useDrawers();
  const invalidate = useInvalidate();
  const { customer, tag, reset, voiceMessage, request, help, refund, setRequest, setHelp, setType } = useRequestState();
  const { mutate, isLoading: isSaving } = useUpdateOrCreate<IHelpRecord>();
  const { data: requestTypes } = useRequestTypeOptions();

  const schema = Yup.object().shape({
    request_type_id: Yup.string().required('Select a request type'),
    issue: Yup.string().required('Enter a description'),
    photo: Yup.string().optional(),
  });

  const form = useForm({
    validate: yupResolver(schema),
    initialValues: {
      customer_id: customer.id,
      request_type_id: help?.request_type?.id ? `${help?.request_type?.id}` : null,
      photo: help?.photo_path ?? '',
      issue: !help?.id && voiceMessage?.transcribed_text ? [
        `SUMMARY: ${voiceMessage?.description}`,
        `TRANSCRIPT: ${voiceMessage?.transcribed_text}`,
        ...(voiceMessage?.translated_text ? [`TRANSLATION: ${voiceMessage?.translated_text}`] : [help?.issue])
      ].filter(v => !!v).join('\n\n') : help?.issue ?? '',
      next_action: false,
      next_action_type: 'refund',
    }
  });

  const handleSubmit = (values) => {
    values.request_id = request?.id ?? null;
    values.tag_id = tag?.id ?? null;

    mutate({ resource: 'help', id: help?.id, values }, {
      onSuccess: async ({ data }) => {
        if (values.next_action) {
          setHelp(data);
          setRequest(data.request);
          setType(values.next_action_type);
        } else {
          closeAll();
        }

        await invalidate({ resource: 'help', invalidates: ['all'] });
        await invalidate({ resource: 'refund', invalidates: ['all'] });
        await invalidate({ resource: 'voice_message', invalidates: ['all'] });
      }
    });
  };

  return <>
    <form onSubmit={form.onSubmit(handleSubmit)}>
      <Grid>
        <Grid.Col span={{ lg: 6 }}>
          <Stack>
            <TagSelector />

            <TagAlert />

            <Select label="Request Type"
                    data={requestTypes?.data || []}
                    {...form.getInputProps('request_type_id')}
            />

            <TextareaAutosize label="Description" {...form.getInputProps('issue')} minRows={3} />

            <ImageSelectorInput label="Image (optional)" {...form.getInputProps('photo')} imageProps={{ mah: 250 }} />
          </Stack>
        </Grid.Col>

        <Grid.Col span={{ lg: 6 }}>
          <Stack mt="sm">
            <CustomerCard customer={customer} />
            <TagDetailsCard />
          </Stack>
        </Grid.Col>
      </Grid>

      <Divider my="md" />

      { !refund && <>
        <Group justify="right" mb="md">
          <Select leftSection={<Checkbox {...form.getInputProps('next_action', { type: 'checkbox' })} />}
                  data={[
                    { value: 'refund', label: 'Create refund request' },
                    { value: 'link', label: 'Send refund link' },
                  ]}
                  {...form.getInputProps('next_action_type')}
          />
        </Group>
      </>}

      <Group wrap="nowrap" gap="xs" justify="right">
        <Button variant="light" color="gray.8" onClick={() => help ? closeAll() : reset()}>Cancel</Button>
        <Button variant="filled" type="submit" loading={isSaving}>
          { help?.id ? 'Update' : 'Save' }
        </Button>
      </Group>
    </form>
  </>;
};
