import { EuiFieldSearch } from '@elastic/eui';
import { FilterProvider, useFilter } from '@components/filters/Filter.context';
import { DebounceInput } from 'react-debounce-input';

type Props = {
  placeholder?: string;
};

type FilterProps = {
  name: string;
  value?: string;
  placeholder?: string;
} & Props;

const Filter = ({ placeholder }: Props) => {
  const { value, setValue } = useFilter<string>();

  return <DebounceInput
    // @ts-ignore
    element={EuiFieldSearch}
    debounceTimeout={400}
    placeholder={placeholder || 'Search...'}
    isClearable={true}
    value={value || ''}
    onChange={(e) => {
      setValue(e.target.value);
    }}
  />;
}

export const FilterSearch = ({ name, value = null, placeholder }: FilterProps) => {
  return <FilterProvider name={name} defaultValue={value}>
    <Filter placeholder={placeholder} />
  </FilterProvider>
};
