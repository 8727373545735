import { createContext, useContext } from 'react';

type TableExpandedRowResourceContextProps<T = unknown> = {
  resource: string,
  record: T,
  parent?: Partial<{
    resource: string,
    record: unknown,
  }>
};

export const TableExpandedRowResourceContext = createContext<TableExpandedRowResourceContextProps>(null);

export function useTableExpandedRowResource<T>() {
  const context = useContext(TableExpandedRowResourceContext);

  return context as TableExpandedRowResourceContextProps<T>;
}
