import { ReactNode } from 'react';
import { useDelete, useUpdate, useCreate } from "@refinedev/core";

type OnUpdateProps = {
  onCreate: (values: unknown) => void;
  onUpdate: (values: unknown) => void;
  onDelete: () => void;
}

type Props = {
  id?: string | number;
  resource: string;
  children: (props: OnUpdateProps) => ReactNode;
}

export const ResourceItem = ({ id, resource, children }: Props) => {
  const { mutate: create } = useCreate();
  const { mutate: update } = useUpdate();
  const { mutate: destroy } = useDelete();

  const onCreate = async (values: unknown) => {
    await create({ resource, values });
  }

  const onUpdate = async (values: unknown) => {
    await update({ resource, id, values });
  }

  const onDelete = async () => {
    await destroy({ resource, id });
  }

  return <>{ children({ onCreate, onUpdate, onDelete }) }</>;
}