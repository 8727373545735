import { modals } from '@mantine/modals';
import { Text } from '@mantine/core';

export const confirmDeletionModal = (onConfirm: () => unknown, target?: string) => modals.openConfirmModal({
    title: 'Confirm Deletion',
    centered: true,
    children: (
      <Text size="sm">
          Are you sure you want to delete {target ? <b>{target}</b> : 'this'}? This action is destructive and you will have
          to contact support to restore your data.
      </Text>
    ),
    labels: { confirm: 'Delete', cancel: "No, don't delete it" },
    confirmProps: { color: 'red' },
    onConfirm,
});
