import { Stack, Title, Group, SimpleGrid, MultiSelect, Divider, Text } from '@mantine/core';
import { Translatable } from '@components/ui';
import { useResourceForm } from '@components/ui/form/ResourceForm.context';
import { IOwnerRecord } from '@interfaces';
import translatableFields from './translatable-content-fields';
import { Accordion } from '@components/ui/accordion/Accordion';

export const AppContentTranslations = () => {
  const { form } = useResourceForm<IOwnerRecord>();

  const supportedLanguages = form.values.supported_languages;

  return <>
    <MultiSelect label="Supported Languages"
                 data={[
                   { value: 'en', label: 'English' },
                   { value: 'es', label: 'Español' },
                   { value: 'fr', label: 'French' },
                 ]}
                 {...form.getInputProps('supported_languages')}
    />

    <Divider my="xs" />

    <Accordion variant="separated" multiple={true as any}>
      { translatableFields.map((section, i) => <Accordion.Item value={section.key} key={i}>
        <Accordion.Control>
          <Title order={5} fw={500}>{ section.title }</Title>
        </Accordion.Control>
        <Accordion.Panel>
          <Divider my="sm" mt={-5} />
          <SimpleGrid cols={{ sm: 1, md: 2 }}>
            { section.fields.map((field, i) => <div key={i}>
              <Group justify="space-between" wrap="nowrap">
                <Stack gap={0}>
                  <Title order={6} fw={500}>{ field.title }</Title>
                  { field.description && <Text size="xs" c="dimmed">{ field.description }</Text>}
                </Stack>
              </Group>

              <Translatable field={`translations._lang_.${field.name}`}
                              languages={supportedLanguages}
                              tokens={field.tokens}
              />
            </div>)}
          </SimpleGrid>
        </Accordion.Panel>
      </Accordion.Item>)}
    </Accordion>
  </>;
}
