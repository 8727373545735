import { IProfileRecord } from '@interfaces';

type FeatureFlagFnReturnType = (
  profile?: IProfileRecord
) => { [key: string]: boolean };

export const featureFlagControlProvider: FeatureFlagFnReturnType = (profile) => {
  const flags = {
    'refunds.has_vopay': false,
    'refunds.has_automation': false,
    wallet: false,
    is_dev: process.env.NEXT_PUBLIC_ENV === 'development' || process.env.NEXT_PUBLIC_ENV === 'staging',
  };

  if (profile) {
    flags['admin'] = profile.roles.includes('Admin');
    flags['owner'] = profile.roles.includes('Owner');

    if (!flags['admin']) {
      flags['refunds.has_vopay'] = profile.owner.settings.refunds_allow_vopay;
      flags['refunds.has_automation'] = profile.owner.settings.refunds_allow_automation;

      flags.wallet = !!profile.owner.stripe_id;
      flags['wallet.has_credit'] = Boolean(flags.wallet && profile.owner?.wallet?.meta?.credit > 0);
      flags['wallet.has_no_credit'] =  flags.wallet && !flags['wallet.has_credit'];
      flags['wallet.has_transactions'] = flags.wallet
    }

    flags['help.create'] = profile.permissions.includes('help.create');
    flags['refund.create'] = profile.permissions.includes('refund.create')
    flags['refund.update'] = profile.permissions.includes('refund.update');
    flags['refund.disbursement'] = profile.permissions.includes('refund.disbursement');
    flags['request.update'] = profile.permissions.includes('request.update');
    flags['settings.refunds'] = profile.permissions.includes('settings.refunds');
    flags['wallet.update'] = profile.permissions.includes('wallet.update');

    flags['demo'] = profile.meta.can_trigger_demo_data ?? false;
    flags['has_vms_sync'] = profile.meta.can_trigger_vms_sync ?? false;
  }

  flags['can_upload'] = flags.is_dev || flags['admin'];

  return flags;
};
