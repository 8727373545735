import { Grid, SimpleGrid, Text, TextInput } from '@mantine/core';
import React from 'react';

interface Props {
  fields: {
    label: string;
    value?: string | number;
    icon?: React.ReactNode;
  }[];
  columns?: [number, number];
}

export function SimpleReadOnlyForm({ fields, columns = [3, 9] }: Props) {
  const rows = fields.map((field, index) => (
    <Grid gutter="xs" align="center" key={index}>
      <Grid.Col span={{ xs: columns[0] }}>
        <Text size="sm" fw={500}>{field.label}</Text>
      </Grid.Col>
      <Grid.Col span={{ xs: columns[1] }}>
        <TextInput value={field.value} rightSection={field.icon} readOnly />
      </Grid.Col>
    </Grid>
  ));

  return (
    <SimpleGrid spacing="sm">
      {rows}
    </SimpleGrid>
  );
}
