import { ReactNode, useState } from 'react';
import NoSSR from 'react-no-ssr';
import { ActionIcon, Box, Button, Drawer, useMantineTheme } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import { IconCheck, IconChevronRight } from '@tabler/icons-react';
import { useFeatures } from 'flagged';
import { EuiBasicTableProps, EuiBasicTableColumn, EuiTableFieldDataColumnType } from '@elastic/eui';

import classes from './Table.module.css';

type Props<T> = {
  onRenderExpanded?: ({item}) => ReactNode;
  excludeColumns?: Array<keyof T | string>;
  children: (record: any) => any;
  onSelection?: (item: T) => void
} & Pick<EuiBasicTableProps<T>, 'items' | 'columns' | 'isSelectable'>;

export function WrapperTable<T>({
  items = [], columns, excludeColumns = [], onRenderExpanded, isSelectable, onSelection, children, ...props
}: Props<T>) {
  const theme = useMantineTheme();
  const isMobile = useMediaQuery(`(max-width: ${theme.breakpoints.sm})`, false);
  const [itemIdToExpandedRowMap, setItemIdToExpandedRowMap] = useState({});
  const [actionMenu, setActionMenu] = useState<ReactNode>(null);
  const [drawContent, setDrawContent] = useState<ReactNode>(null);
  const features = useFeatures();

  const expander: EuiBasicTableColumn<T> = {
    width: '35px',
    isExpander: true,
    className: classes.expander,
    mobileOptions: {
      width: '40px',
    },
    render: (item) => {
      const isExpanded = !!itemIdToExpandedRowMap[item.id];

      return <ActionIcon variant="light"
                         color="gray.6"
                         size="md"
                         onClick={() => toggleDetails(item)}>
        <IconChevronRight style={{
          transition: 'transform 200ms ease',
          transform: isExpanded ? `rotate(90deg)` : 'none',
        }} />
      </ActionIcon>
    },
  };

  const selector: EuiBasicTableColumn<T> = {
    name: '',
    width: '85px',
    mobileOptions: {
      width: '40px',
    },
    actions: [{
      render: (item: T) => {
        return !isMobile ?
          <Button variant="light" px="xs" onClick={() => onSelection?.(item)}>Select</Button> :
          <ActionIcon variant="light"
                      color="blue.6"
                      size="md"
                      onClick={() => onSelection?.(item)}>
            <IconCheck size={16} />
          </ActionIcon>;
      },
    }]
  };

  const toggleDetails = (item) => {
    if (isMobile) {
      const menu = filteredColumns.find(c => c['actions']);

      if (menu?.['actions']?.[0]) {
        setActionMenu(menu['actions'][0].render(item));
      }

      return setDrawContent(onRenderExpanded({ item }));
    }

    const itemIdToExpandedRowMapValues = { ...itemIdToExpandedRowMap };
    if (itemIdToExpandedRowMapValues[item.id]) {
      delete itemIdToExpandedRowMapValues[item.id];
    } else {
      itemIdToExpandedRowMapValues[item.id] = onRenderExpanded({ item });
    }

    setItemIdToExpandedRowMap(itemIdToExpandedRowMapValues);
  };

  const filteredColumns = columns.filter((column: EuiTableFieldDataColumnType<T>) => {
    const isExcluded = excludeColumns.includes(column.field);

    let hasPermission = true;
    if (column['permissions']) {
      hasPermission = column['permissions'].filter(p => features[p]).length > 0;
    }

    return !isExcluded && hasPermission;
  });

  return (
    <NoSSR>
      {
        children({
          className: classes.table,
          items: items,
          itemId: 'id',
          itemIdToExpandedRowMap: itemIdToExpandedRowMap,
          columns: [
            ...(!!onRenderExpanded ? [expander] : []),
            ...filteredColumns,
            ...(isSelectable ? [selector] : [])
          ],
          isExpandable: onRenderExpanded && isMobile,
          hasActions: !!onRenderExpanded || !!filteredColumns.find(c => c['actions']),
        })
      }

      <Drawer.Root opened={!!drawContent}
                   onClose={() => {
                     setActionMenu(null);
                     setDrawContent(null);
                   }}
                   position="right"
                   padding="sm"
                   size="100%"
      >
        <Drawer.Overlay />
        <Drawer.Content>
          <Drawer.Header>
            { actionMenu }
            <Drawer.CloseButton />
          </Drawer.Header>
          <Drawer.Body>
            <Box pb={75}>
              { drawContent }
            </Box>
          </Drawer.Body>
        </Drawer.Content>
      </Drawer.Root>
    </NoSSR>
  );
}
