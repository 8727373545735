import { Box, Button, Title, Stack } from '@mantine/core';
import { PropsWithChildren } from 'react';
import { ErrorBoundary as BaseErrorBoundary } from 'react-error-boundary';
import { useIntercom } from 'react-use-intercom';

const ErrorFallback = () => {
  const { show } = useIntercom();

  return <Box
    p="lg"
    style={(theme) => ({
      display: 'flex',
      flex: 1,
      flexDirection: 'column',
      alignItems: 'center',
      borderRadius: theme.radius.sm,
      backgroundColor: theme.colors.red[1],
    })}
  >
    <Stack align="center">
      <Title order={3}>Oops, something isn't right here...</Title>

      <Title order={6}>We have been notified of this issue and will resolve it as soon as we can.</Title>

      <Button onClick={show} color="dark" variant="filled">Send us a message</Button>
    </Stack>
  </Box>
}

export const ErrorBoundary = ({ children }: PropsWithChildren) => {
  const errorhandler = (error: Error) => {
    console.error(error);
  };

  return <BaseErrorBoundary FallbackComponent={ErrorFallback} onError={errorhandler}>
    { children }
  </BaseErrorBoundary>;
}
