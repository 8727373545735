import {
  Box,
  Button,
  Group,
  Stack,
  Text,
  TextInput,
  Title,
} from '@mantine/core';
import { ContextModalProps } from '@mantine/modals';
import { IApiKeyRecord } from '@interfaces';
import { CopyValue } from '@components/ui/CopyValue';

type Props = {
  details: IApiKeyRecord
}

export const ApiKeyDetailsModals = ({ context, id, innerProps }: ContextModalProps<Props>) => {
  const handleCancel = () => {
    context.closeModal(id);
  };

  return <>
    <Box p="sm" bg="blue.0" style={{ borderRadius: 4 }}>
      <Stack mb="sm">
        <TextInput
          label="Key"
          value={innerProps.details.api_key}
          rightSection={<CopyValue value={innerProps.details.api_key} />}
          readOnly
        />

        <Box>
          <Title order={5}>Keep your secret safe</Title>
          <Text mb="sm">Save and store this new secret to a secure place, such as a password manager or secret store. You won’t be able to see it again.</Text>

          <TextInput
            label="Secret"
            value={innerProps.details.api_secret}
            rightSection={<CopyValue value={innerProps.details.api_secret} />}
            readOnly
          />
        </Box>
      </Stack>

      <Group justify="right">
        <Button onClick={handleCancel} variant="outline">Okay</Button>
      </Group>
    </Box>
  </>;
};
