import { IResourceComponentsProps } from '@refinedev/core';
import { IApiKeyResponse } from '@interfaces';
import {
  Card,
  Group,
  Stack,
  TextInput,
  Title,
  Select,
  Loader,
  Switch,
} from '@mantine/core';
import * as Yup from 'yup';
import { Feature } from 'flagged';
import { useForm, yupResolver } from '@mantine/form';
import { ResourceForm } from '@components/ui/form/ResourceForm';
import { useIdentity } from '@components/data/Identity.context';
import { useOwnerOptions } from '@components/hooks';
import { modals } from '@mantine/modals';
import { CopyValue } from '@components/ui/CopyValue';

export function ApiKeyEdit({ initialData: record }: IResourceComponentsProps<IApiKeyResponse>) {
  const { identity } = useIdentity();
  const { data: owners, isLoading: isOwnersLoading } = useOwnerOptions();

  const schema = Yup.object().shape({
    roles: Yup.array(),
    owner_id: Yup.mixed().nullable().when('roles', {
      is: (roles) => roles.includes('Admin'),
      then: (schema) => schema.required('Assign an owner')
    }),
    name: Yup.string().required('Enter a name'),
    is_active: Yup.bool(),
  });

  const form = useForm({
    validate: yupResolver(schema),
    initialValues: {
      roles: identity.roles,
      owner_id: record?.data.owner_id.toString() ?? null,
      name: record?.data.name ?? '',
      api_key: record?.data.api_key ?? '',
      is_active: record?.data.is_active ?? true,
    },
  });

  return <ResourceForm form={form} onAfterSave={(data) => {
    modals.openContextModal({
      modal: 'apiKeyDetails',
      title: 'Your new API key',
      innerProps: {
        details: data,
      },
      size: 'lg',
      closeOnEscape: false,
    })
  }}>
    <Group justify="space-between" mb="lg">
      <Title order={2}>API Key</Title>
    </Group>

    <Card shadow="sm" radius="sm">
      <Card.Section withBorder p="md">
        <Stack>
          <Feature name="admin">
            <Select
              label="Assign to owner"
              placeholder="Select an owner..."
              searchable
              leftSection={isOwnersLoading && <Loader size="xs" />}
              data={owners?.data || []}
              { ...form.getInputProps('owner_id') }
              />
          </Feature>

          <TextInput label="Name" { ...form.getInputProps('name') } />

          {form.values.api_key && <>
            <TextInput
              label="API Key"
              { ...form.getInputProps('api_key') }
              rightSection={<CopyValue value={form.values.api_key} />}
              readOnly
            />
          </>}

          <Switch label="Enabled"
                  description="Marks this key as enabled or disabled"
                  offLabel="No" onLabel="Yes"
                  { ...form.getInputProps('is_active', { type: 'checkbox' }) }
          />
        </Stack>
      </Card.Section>

      <Card.Section px="md" py="sm">
        <Group justify="right">
          <ResourceForm.CancelButton />
          <ResourceForm.SubmitButton />
        </Group>
      </Card.Section>
    </Card>
  </ResourceForm>
}
