import { IFeedbackListItem } from '@interfaces';
import { ResourceTable, ResourceTableProps } from '@components/table/ResourceTable';

import FeedbackTableColumns from './FeedbackTableColumns';
import FeedbackTableExpandedRow from './FeedbackTableExpandedRow';

export const FeedbackTable = (props: ResourceTableProps<IFeedbackListItem>) => {
  return <ResourceTable<IFeedbackListItem>
    resource="feedback"
    columns={FeedbackTableColumns}
    onRenderExpanded={FeedbackTableExpandedRow}
    excludeColumns={['id']}
    {...props}
  />;
}