export function resolveAssetUrl(url: string) {
  return process.env.NEXT_PUBLIC_API_URL + '/asset/' + url;
}

export function resolveBillingPortalUrl() {
  return process.env.NEXT_PUBLIC_API_URL + '/billing/portal';
}

export function resolveCustomerAppUrl(slug: string) {
  return process.env.NEXT_PUBLIC_APP_URL + `/${slug}`;
}
