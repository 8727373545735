import { useCreate, useUpdate } from "@refinedev/core";

export const useUpdateOrCreate = <T>() => {
  const { mutate: update, isLoading: isUpdateLoading } = useUpdate<T>();
  const { mutate: create, isLoading: isCreateLoading } = useCreate<T>();

  const mutate = ({ resource, id, values }, options?) => {
    if (id) {
      return update({ resource, id, values, meta: { method: 'put' } }, options);
    } else {
      return create({ resource, values }, options);
    }
  };

  return {
    mutate,
    isLoading: isUpdateLoading || isCreateLoading
  }
};
