import { RichTextEditor, useRichTextEditorContext } from '@mantine/tiptap';
import { IconRestore, IconCheck, IconX } from '@tabler/icons-react';
import { Text, Popover, Box, useMantineTheme, Group, Input } from '@mantine/core';
import { clamp, useDisclosure, useViewportSize } from '@mantine/hooks';

export function RestoreControl({ text }: { text: string }) {
  const { editor } = useRichTextEditorContext();
  const [opened, { close, toggle }] = useDisclosure(false);
  const theme = useMantineTheme();
  const { width } = useViewportSize();

  return (
    <Popover width={clamp(width - 20, 0, 320)} withArrow shadow="md" opened={opened} onChange={toggle} closeOnClickOutside>
      <Popover.Target>
        <RichTextEditor.Control title="Restore Original Text" onClick={toggle}>
          <IconRestore strokeWidth={1} size={18} />
        </RichTextEditor.Control>
      </Popover.Target>
      <Popover.Dropdown p="xs">
        <Input.Label size="sm">Restore default system text?</Input.Label>

        <Box style={{
          border: `1px solid ${theme.colors.gray[3]}`,
          background: theme.colors.gray[0],
          borderRadius: theme.radius.sm,
        }} p={6}>
          <Text dangerouslySetInnerHTML={{ __html: text }} size="sm"></Text>
        </Box>

        <Group justify="right" mt="xs">
          <RichTextEditor.ControlsGroup>
            <RichTextEditor.Control>
              <IconX strokeWidth={1} size={18} onClick={close} />
            </RichTextEditor.Control>
            <RichTextEditor.Control>
              <IconCheck strokeWidth={1} size={18} onClick={() => {
                editor.commands.setContent(text, true);
                close();
              }} />
            </RichTextEditor.Control>
          </RichTextEditor.ControlsGroup>
        </Group>
      </Popover.Dropdown>
    </Popover>
  );
}
