import ct from 'countries-and-timezones';

const timezones = Object.values(ct.getAllTimezones())
  .filter((t) => {
    return t.name.includes('/') && !t.name.includes('Etc');
  })
  .sort((a, b) => a.utcOffset - b.utcOffset);

export const ALL_TIMEZONES = timezones.map((t) => {
  return {
    value: t.name,
    label: `(${t.utcOffsetStr}) ${t.name}`
  };
});

export const US_TIMEZONES = timezones.filter((t) => t.countries.includes('US'));
export const CA_TIMEZONES = timezones.filter((t) => t.countries.includes('CA'));
