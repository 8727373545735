import { EquipmentSelector } from './EquipmentSelector';
import { Box, Popover } from '@mantine/core';
import { useState } from 'react';
import { ITagListItem } from '@interfaces';

type Props = {
  onChange: (tag: ITagListItem) => void;
};

export const EquipmentSelectorPopover = ({ onChange }: Props) => {
  const [opened, setOpened] = useState(false);

  const handleOnChange = (tag: ITagListItem) => {
    setTimeout(() => setOpened(false), 100);
    onChange(tag);
  };

  return <>
    <EquipmentSelector onChange={handleOnChange}>
      {(list, search) => <>
        <Popover width="target" position="bottom" withArrow shadow="md" opened={opened} onChange={setOpened}>
          <Popover.Target>
            <Box onFocusCapture={() => setOpened((o) => !o)}>{search}</Box>
          </Popover.Target>
          <Popover.Dropdown p={0}>
            {list}
          </Popover.Dropdown>
        </Popover>
      </>}
    </EquipmentSelector>
  </>;
}
