import { CollapsableCard } from '@components/ui';
import { IAddressObject } from '@interfaces';
import { Text } from '@mantine/core';

interface Props {
  address: IAddressObject;
  label?: string;
}

export const LocationCard = ({ address, label = 'Location' }: Props) => {
  let content = null;

  if (!address) {
    return <></>;
  }

  if (address) {
    const addressName = address.business_status ? `${address.name}, ${address.formatted_address}` : address.formatted_address;

    if (address.geometry?.location.lat && address.geometry?.location.lng) {
      content = <>
        <Text mb="sm">{addressName}</Text>
        <iframe
          height="350"
          style={{ border: 0, width: '100%' }}
          loading="lazy"
          src={`https://www.google.com/maps/embed/v1/place?key=AIzaSyD0Q-mkCsf8OMlVtChy11v7WIMXnNH03Jg&q=${address.geometry.location.lat},${address.geometry.location.lng}`}>
        </iframe>
      </>;
    } else if (addressName) {
      content = <Text>{addressName}</Text>;
    }
  }

  return <CollapsableCard label={label} collapsed={false}>
    {content}
  </CollapsableCard>;
}
