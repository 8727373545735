import { ReactNode } from 'react';
import { EuiBasicTable, EuiBasicTableProps, EuiTableSortingType, Criteria, EuiBasicTableColumn } from '@elastic/eui';
import { Group, Pagination, Select, Text } from '@mantine/core';
import { GetListResponse, useResource } from '@refinedev/core';
import { useDidUpdate } from '@mantine/hooks';
import { QueryObserverOptions } from '@tanstack/query-core';
import { unionBy } from 'lodash';
import { WrapperTable } from '@components/table/WrapperTable';
import { useTableFilters } from '@components/table/TableFilter.context';
import { useTableConfiguration } from '@components/table/TableConfiguration.context';
import { ExportAction } from '@components/actions/ExportAction';
import { useTable } from '@components/table/useTable';
import { TableResourceContext } from '@components/table/TableResource.context';

export interface ResourceTableProps<T> {
  resource?: string;
  initialData?: GetListResponse<T>;
  excludeColumns?: Array<keyof T | string>;
  allowExports?: boolean;
  actions?: ReactNode;
  syncWithLocation?: boolean;
  columns?: Array<EuiBasicTableColumn<T>>
  tableProps?: {
    onSelection?: (item: T) => void;
  } & Partial<EuiBasicTableProps<T>>;
  queryOptions?: QueryObserverOptions<GetListResponse<T>>;
}

type Props<T> = {
  resource: string;
  onRenderExpanded?: ({ item }) => ReactNode;
} & ResourceTableProps<T> & Omit<EuiBasicTableProps<T>, 'items'>;

export function ResourceTable<T>({
  initialData, resource, columns, allowExports = true, syncWithLocation = false, actions, queryOptions = {}, ...props
}: Props<T>) {
  const { filters: appliedFilters, excludeDefaultFilters } = useTableFilters();
  const { resource: { meta } } = useResource(resource);
  const { initialPageSize } = useTableConfiguration();

  const defaultFilters = excludeDefaultFilters ? [] : [...(meta?.defaultFilters ?? [])];

  const { isSelectable, onSelection, ...tableProps } = props?.tableProps ?? {};

  const {
    tableQueryResult,
    sorters, setSorters,
    current, setCurrent,
    pageSize, setPageSize,
    setFilters
  } = useTable({
    resource,
    queryOptions: {
      initialData: {
        data: initialData?.data,
        total: initialData?.total,
      },
      refetchOnMount: !initialData,
      refetchInterval: 60 * 1000 * 5,
      refetchIntervalInBackground: true,
      ...queryOptions,
    },
    syncWithLocation,
    pagination: {
      pageSize: initialPageSize
    },
    filters: {
      initial: unionBy([...appliedFilters], [...defaultFilters], 'field'),
      defaultBehavior: 'replace'
    }
  });

  const onTableChange = (criteria: Criteria<T>) => {
    if (criteria.sort) {
      setSorters([{
        field: String(criteria.sort.field),
        order: criteria.sort.direction
      }]);
    }
  };

  const onPageChange = (index: number) => {
    setCurrent(index);
  };

  const pagination = {
    pageIndex: current || 1,
    pageSize: pageSize,
    pageCount: Math.ceil(tableQueryResult.data.total / pageSize),
    totalItemCount: tableQueryResult.data.total,
  };

  useDidUpdate(() => {
    setFilters(unionBy([...appliedFilters], [...defaultFilters], 'field'));
  }, [appliedFilters]);

  useDidUpdate(() => {
    // Always show the user a page with results
    if (pagination.pageCount < current) {
      onPageChange(pagination.pageCount);
    }
  }, [tableQueryResult?.data?.total]);

  const sorting: EuiTableSortingType<any> = {
    ...(sorters[0] && {
      sort: {
        field: sorters[0]?.field,
        direction: sorters[0]?.order,
      },
    }),
    enableAllColumns: true,
  };

  const resultsCount = pagination.pageCount <= 1 ?
    <strong>All</strong> :
    <strong>{(pageSize * pagination.pageIndex) - (pageSize - 1)}-{pageSize * pagination.pageIndex}</strong>;

  return <TableResourceContext.Provider value={{ resource }}>
    <WrapperTable items={tableQueryResult.data.data}
                  columns={columns}
                  isSelectable={!!isSelectable}
                  onSelection={onSelection}
                  {...props}
    >
      {(options) => <>
        <EuiBasicTable
          { ...options }
          loading={tableQueryResult.isRefetching}
          sorting={sorting}
          onChange={onTableChange}
          { ...tableProps }
        />

        <Group mt="sm" justify="space-between">
          <Group gap="xs">
            { actions }
            { allowExports && tableQueryResult.data.total > 0 && <ExportAction resource={resource} /> }
          </Group>
          {
            pagination.totalItemCount > 0 &&
            <Text size="sm" mr="xs">
              Showing {resultsCount} of <strong>{pagination.totalItemCount}</strong>
            </Text>
          }
        </Group>

        { pagination.pageCount > 1 &&
          <Group justify="space-between" my="sm">
            <Group>
              <Select
                data={[
                  { value: '5', label: '5' },
                  { value: '15', label: '15' },
                  { value: '25', label: '25' },
                  { value: '50', label: '50' },
                  { value: '100', label: '100' },
                ]}
                onChange={(size) => {
                  onPageChange(1);
                  setPageSize(Number(size));
                }}
                value={`${pagination.pageSize}`}
                style={{ width: 65 }}
                styles={{ input: { paddingRight: 20, textAlign: 'center' } }}
              />
              <Text size="sm">Entries per page</Text>
            </Group>

            <Pagination total={pagination.pageCount} value={pagination.pageIndex} size="sm" onChange={onPageChange}/>
          </Group>
        }
      </>
      }
    </WrapperTable>
  </TableResourceContext.Provider>;
}
