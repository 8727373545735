import { EuiBasicTableColumn } from '@elastic/eui';
import { IRefundListItem } from '@interfaces';
import { StatusIndicator } from '@components/ui/StatusIndicator';
import { DateTime } from '@components/ui/DateTime';
import { Group, Text } from '@mantine/core';
import { IconWand } from '@tabler/icons-react';
import { CustomerPanelTrigger } from '@components/ui/CustomerPanelTrigger';
import { ActionMenu } from '@components/table/actions/ActionMenu';
import { CreateRequestAction } from '@components/table/actions/CreateRequestAction';
import { EditRequestAction } from '@components/table/actions/EditRequestAction';
import { DeleteRequestLinkAction } from '@components/table/actions/DeleteRequestLinkAction';

export default [
  {
    field: 'owner_name',
    name: 'Owner',
    width: '150',
    sortable: true,
    truncateText: true,
    permissions: ['admin']
  },
  {
    field: 'customer_name',
    name: 'Name',
    width: '220',
    sortable: true,
    truncateText: true,
    render: (_name, record) => <CustomerPanelTrigger customer={record} />,
  },
  {
    field: 'reference',
    name: 'Reference',
    sortable: true,
    truncateText: true,
  },
  {
    field: 'refund_name',
    name: 'Method',
    sortable: true,
    truncateText: true,
    render: (value) => value || <Text c="dimmed">&mdash;</Text>
  },
  {
    field: 'amount',
    name: 'Amount',
    sortable: true,
    truncateText: true,
    render: (amount) => `$${amount || 0}`
  },
  {
    field: 'completed_or_created_at',
    name: 'Submitted',
    sortable: true,
    width: '165',
    dataType: 'date',
    render: (date) => <DateTime value={date} />
  },
  {
    field: 'decision_at',
    name: 'Decision',
    sortable: true,
    width: '165',
    dataType: 'date',
    render: (date) => <DateTime value={date} />
  },
  {
    field: 'disbursed_at',
    name: 'Disbursed',
    sortable: true,
    width: '165',
    dataType: 'date',
    render: (date) => <DateTime value={date} />
  },
  {
    field: 'status',
    name: 'Status',
    width: '155',
    sortable: true,
    render: (status, record) => {
      return <Group gap={5} wrap="nowrap">
        <StatusIndicator status={status} />
        { record.automated_decision && <IconWand size={18} stroke={1.5} /> }
      </Group>
    },
  },
  {
    name: '',
    width: '40',
    actions: [
      {
        render: (record) => <ActionMenu id={record.id}>
          <EditRequestAction type="refund" label="Edit refund" id={record.id} disabled={!['Pending', 'Draft', 'Failed'].includes(record.status)} />

          { record.help_id && <EditRequestAction type="help" label="Edit help" id={record.help_id} />}
          { !record.help_id && <CreateRequestAction type="help" label="Create help" />}

          { !record.help_id && !record.request_link_id && <CreateRequestAction type="link" label="Send request link" />}
          { !record.help_id && record.request_link_id && <DeleteRequestLinkAction id={record.request_link_id} />}
        </ActionMenu>
      },
    ]
  },
] as EuiBasicTableColumn<IRefundListItem>[];
