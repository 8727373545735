import { Text } from '@mantine/core';
import { IRequestRecord } from '@interfaces';
import { CollapsableCard } from '@components/ui';
import { Notes } from '@components/ui/notes/Notes';

interface Props {
  request: IRequestRecord;
}

export const NotesCard = ({ request }: Props) => {
  const label = <>
    Notes
    <Text component="span" size="xs" fw="lighter" c="dimmed" ml="xs">(Internal use only, not seen by customers)</Text>
  </>

  return <CollapsableCard variant="light" label={label} collapsable={false}>
    <Notes notes={request.notes} requestId={request.id} />
  </CollapsableCard>;
}
