import { CollapsableCard } from '@components/ui';
import { IFeedbackRecord } from '@interfaces';
import { Grid } from '@mantine/core';
import { EuiImage } from '@elastic/eui';
import { TextareaAutosize } from '@components/ui/TextareaAutosize';

interface Props {
  feedback: IFeedbackRecord;
}

export const DescriptionCard = ({ feedback }: Props) => {
  return <CollapsableCard label="Description">
    <Grid>
      <Grid.Col span={feedback.photo_path ? 8 : 12}>
        <TextareaAutosize value={feedback.reason} readOnly />
      </Grid.Col>

      {feedback.photo_path &&
        <Grid.Col span={4}>
          <EuiImage allowFullScreen alt="" src={feedback.photo_path} />
        </Grid.Col>
      }
    </Grid>
  </CollapsableCard>;
}
