import { CollapsableCard } from '@components/ui';
import { IRefundAddressRecord } from '@interfaces';
import { Grid, TextInput } from '@mantine/core';

type Props = {
  address: IRefundAddressRecord;
}

export const PostalAddressCard = ({ address }: Props) => {
  return <CollapsableCard label="Postal address">
    <Grid grow gutter="xs">
      <Grid.Col span={12}><TextInput label="Name" value={address.name} readOnly /></Grid.Col>
      <Grid.Col span={6}><TextInput label="Address line 1" value={address.address_line_1} readOnly /></Grid.Col>
      <Grid.Col span={6}><TextInput label="Address line 2" value={address.address_line_2} readOnly /></Grid.Col>
      <Grid.Col span={4}><TextInput label="City" value={address.city} readOnly /></Grid.Col>
      <Grid.Col span={4}><TextInput label="State" value={address.state} readOnly /></Grid.Col>
      <Grid.Col span={4}><TextInput label="Zip code" value={address.zip_code} readOnly /></Grid.Col>
    </Grid>
  </CollapsableCard>;
}
