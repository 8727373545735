import { useState } from 'react';
import { Space, Text } from '@mantine/core';
import { LogicalFilter, CrudSort, useList } from '@refinedev/core';
import { useDebouncedState, useDidUpdate } from '@mantine/hooks';
import { EuiHighlight, EuiSelectable, EuiSelectableOption, EuiSelectableProps } from '@elastic/eui';
import { ITagListItem } from '@interfaces';

type Props = {
  onChange?: (tag: ITagListItem) => void;
} & Pick<EuiSelectableProps, 'children' | 'autoFocus'>

export const EquipmentSelector = ({ onChange, autoFocus, children }: Props) => {
  const [value, setValue] = useDebouncedState(null, 400);
  const [inputValue, setInputValue] = useState('');
  const [options, setOptions] = useState<Array<EuiSelectableOption>>([]);
  const [selectedTag, setSelectedTag] = useState<ITagListItem>();

  const { data, isFetching } = useList<ITagListItem>({
    resource: 'tag',
    filters: [{
      field: 'identifier',
      value: value
    }] as LogicalFilter[],
    pagination: {
      pageSize: 50,
    },
    sorters: [{
      field: 'identifier',
      order: 'asc'
    }] as CrudSort[],
    queryOptions: {
      enabled: !!value,
    },
  })

  useDidUpdate(() => {
    if (selectedTag) {
      onChange?.(selectedTag);
    }
  }, [selectedTag]);

  const setFormattedOptions = () => {
    setOptions([
      ...data.data?.map((tag) => ({
        label: `(ID: ${tag.identifier}) ${tag.name}`,
        searchableLabel: tag.identifier,
        data: {
          id: tag.id,
          name: tag.name,
          identifier: tag.identifier,
          address: tag.address
        },
      })),
    ]);
  };

  useDidUpdate(() => {
    if (data) {
      setFormattedOptions();
    }
  }, [data]);

  const renderOption = (option: EuiSelectableOption, search: string) => {
    return <>
      <Text size="sm">
        <EuiHighlight search={search}>{option.label}</EuiHighlight>
      </Text>
      <Text size="xs" c="dimmed">
        <EuiHighlight search={search}>{option?.['address'] || ''}</EuiHighlight>
      </Text>
    </>;
  };

  return <>
    <EuiSelectable
      searchable
      searchProps={{
        placeholder: 'Search by Tag ID or enter equipment ID',
        inputRef: (node) => {
          autoFocus && setTimeout(() => node?.focus(), 10);
        },
        onChange: (term) => {
          setValue(term);
          setInputValue(term);
        },
        value: inputValue,
      }}
      listProps={{
        rowHeight: 50,
      }}
      isPreFiltered={false}
      renderOption={renderOption}
      isLoading={isFetching}
      singleSelection="always"
      options={options}
      onChange={(options, event, changedOption) => {
        setSelectedTag(changedOption.data as ITagListItem);
        setOptions(options);
      }}
    >
      {(list, search) => <>
        { children && children(list, search) }
        { !children && <>
          {search}
          <Space h={10} />
          {list}
        </>}
      </>}
    </EuiSelectable>
  </>;
}
