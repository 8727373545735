import { MultiSelect, SimpleGrid, Stack, Switch, Title } from '@mantine/core';
import { IUserRecord } from '@interfaces';
import { useResourceForm } from '@components/ui/form/ResourceForm.context';

export const UserNotificationSettings = () => {
  const { form } = useResourceForm<IUserRecord>();

  return <>
    <Title order={5} mb="xs" fw={500}>Email Notifications</Title>

    <SimpleGrid cols={{ md: 2 }} spacing="xl">
      <Switch label="Receive feedback notifications"
              description="This will send an email notification when a feedback request is received"
              offLabel="No" onLabel="Yes"
              {...form.getInputProps('settings.notification_feedback', { type: 'checkbox' })}
      />

      <Switch label="Receive help notifications"
              description="This will send an email notification when a help request is received"
              offLabel="No" onLabel="Yes"
              {...form.getInputProps('settings.notification_help', { type: 'checkbox' })}
      />

      <Switch label="Receive help closed notifications"
              description="This will send an email notification when a help request is closed"
              offLabel="No" onLabel="Yes"
              {...form.getInputProps('settings.notification_help_closed', { type: 'checkbox' })}
      />

      <Stack gap="sm">
        <Switch label="Receive refund notifications"
                description="This will send an email notification when a refund request is received, failed or returned"
                offLabel="No" onLabel="Yes"
                {...form.getInputProps('settings.notification_refund', { type: 'checkbox' })}
        />

        { form.values.settings.notification_refund && <>
          <MultiSelect
            placeholder={ !form.values.settings.notification_refund_types.length ? 'Select types...' : null }
            clearable
            data={[
              { label: 'Received', value: 'created' },
              { label: 'Failed', value: 'canceled' },
              { label: 'Returned', value: 'unclaimed' },
            ]}
            {...form.getInputProps('settings.notification_refund_types')}
          />
        </>}
      </Stack>

      <Switch label="Receive voice message notifications"
              description="This will send an email notification when a voice message is received"
              offLabel="No" onLabel="Yes"
              {...form.getInputProps('settings.notification_voice-message', { type: 'checkbox' })}
      />
    </SimpleGrid>
  </>;
}
