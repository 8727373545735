import { createContext, PropsWithChildren, useContext, useEffect, useMemo, useState } from 'react';
import { useTableFilters } from '@components/table/TableFilter.context';

type FilterContextProps<T = unknown> = {
  defaultValue: T;
  value: T;
  setValue: (value: T) => void;
  reset?: () => void;
  clear?: () => void;
};

export const FilterContext = createContext<FilterContextProps>(null);

type Props = {
  name: string;
  defaultValue: unknown;
  onChange?: (value) => unknown;
};

export function FilterProvider({ name, defaultValue = null, onChange, children }: PropsWithChildren<Props>) {
  const { initialFilters, defaultFilters, setFilter, setFilterReset } = useTableFilters();
  const initialValue = initialFilters.find(f => f.field === name)?.value;
  const defaultFilterValue = defaultFilters.find(f => f.field === name)?.value;

  const [value, setValue] = useState(defaultFilterValue ?? initialValue ?? defaultValue);

  useEffect(() => {
    setFilter(name, value);
  }, [value]);

  const onHandleReset = () => {
    onHandleChange(defaultFilterValue ?? defaultValue);
  };

  const onHandleClear = () => {
    onHandleChange(defaultValue);
  };

  const onHandleChange = (value) => {
    if (typeof onChange === 'function') {
      value = onChange(value);
    }

    setValue(value);
  };

  useMemo(() => {
    // Registers the reset handler, allows filters that were created externally (via URL, Cookie) to be cleared/reset
    // back to their default value
    setFilterReset(name, onHandleReset);
  }, []);

  return <FilterContext.Provider value={{
    defaultValue,
    value,
    setValue: onHandleChange,
    reset: onHandleReset,
    clear: onHandleClear,
  }}>
    { children }
  </FilterContext.Provider>;
}

export function useFilter<T>() {
  const context = useContext(FilterContext);

  if (!context) {
    throw new Error(
      'useFilter hook was called outside of FilterProvider context'
    );
  }

  return context as FilterContextProps<T>;
}
