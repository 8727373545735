import { Avatar, Box, Group, Indicator, Text } from '@mantine/core';
import { IconCheck } from '@tabler/icons-react';

interface Props {
  customer: {
    name: string;
    confirmed_at: string;
  };
  compact?: boolean;
}

const COLOR_MAP = {
  'blue': ['a', 'k', 'u'],
  'red': ['b', 'l', 'v'],
  'indigo': ['c', 'm', 'w'],
  'purple': ['d', 'n', 'x'],
  'pink': ['e', 'o', 'y'],
  'orange': ['f', 'p', 'z'],
  'yellow': ['g', 'q'],
  'green': ['h', 'r'],
  'teal': ['i', 's'],
  'cyan': ['j', 't'],
};

export const CustomerAvatar = ({ customer, compact = false }: Props) => {
  let color = '#467FD0';

  const name = customer?.name ?? '';
  const firstLetter = name?.toLowerCase().substring(0, 1) ?? '';

  for (const [hex, letters] of Object.entries(COLOR_MAP)) {
    if (letters.includes(firstLetter)) {
      color = hex;
      break;
    }
  }

  return <Group gap={4} wrap="nowrap" w={!compact ? '100%' : null}>
    <Indicator color={color}
               disabled={!customer.confirmed_at}
               label={<IconCheck strokeWidth={4} size={10} />}
               size="lg"
               offset={3}
               withBorder
               styles={{ indicator: { padding: 1 } }}
               title={customer.confirmed_at ? 'Verified' : ''}
    >
      <Avatar color={color} radius="xl" size={35}>{firstLetter.toUpperCase()}</Avatar>
    </Indicator>
    { !compact && <Text truncate="end" flex={1} ml={5}>{ name }</Text> }
  </Group>;
}
