import { PropsWithChildren, useEffect, useMemo, useState } from 'react';
import { LayoutProps, useMenu } from '@refinedev/core';
import { AppShell } from '@mantine/core';
import * as Sentry from '@sentry/browser';
import { Sidebar } from '@components/layout/Sidebar';
import { useRouter } from 'next/router';
import Head from 'next/head';
import { useIntercom } from 'react-use-intercom';
import { useIdentity } from '@components/data/Identity.context';
import { ErrorBoundary } from '@components/ui/ErrorBoundary';
import { LiveNotifications } from '@components/ui/notifications/LiveNotifications';
import { pusherConfigProvider } from '../../providers';
import { PusherProvider } from '@harelpls/use-pusher';

import classes from './Layout.module.css';
import { MobileHeader } from '@components/layout/MobileHeader';

export const Layout = ({ children }: PropsWithChildren<LayoutProps>) => {
  const router = useRouter();
  const { menuItems, selectedKey } = useMenu();
  const { identity } = useIdentity();
  const { boot } = useIntercom();
  const [opened, setOpened] = useState(false);

  const selectedPage = useMemo(() => {
    return menuItems
      .flatMap((value) => [value, ...value?.children])
      .sort((a, b) => b.route.length - a.route.length)
      .find(i => selectedKey.startsWith(i.route));
  }, [menuItems, selectedKey]);

  useEffect(() => {
    const handleRouteChange = () => setOpened(false);

    router.events.on('routeChangeStart', handleRouteChange);

    return () => router.events.off('routeChangeStart', handleRouteChange);
  }, []);

  useEffect(() => {
    if (identity) {
      boot({
        userId: `${identity.id}`,
        userHash: identity.user_hash,
        name: identity.name,
        email: identity.email,
        phone: identity.phone_number,
        customAttributes: {
          zippyassist_role: identity.roles[0]?.toLowerCase(),
        },
        createdAt: `${Date.parse(identity.created_at)}`,
      });

      Sentry.setUser({ email: identity.email });
    }
  }, [identity]);

  useEffect(() => {
    if (process.env.NEXT_PUBLIC_ENV !== 'development') {
      import('@openreplay/tracker').then((lib) => {
        const OpenReplay = lib.default;
        const openReplay = new OpenReplay({
          projectKey: 'XvYxlnffShOP4pDHgxuU',
          ingestPoint: 'https://replay.zippyassist.com/ingest',
          obscureInputEmails: false,
          obscureInputNumbers: false,
          obscureTextEmails: false,
          obscureTextNumbers: false,
          defaultInputMode: 0,
          network: {
            sessionTokenHeader: false,
            captureInIframes: false,
            capturePayload: true,
            failuresOnly: false,
            ignoreHeaders: false,
          },
        });

        openReplay.start();
      });
    }
  }, []);

  return <>
    <Head>
      <title>{`${selectedPage?.meta.label ?? 'Dashboard'} | ZippyAssist`}</title>
    </Head>
    <AppShell
      classNames={{
        root: classes.root,
        main: classes.main,
      }}
      navbar={{ width: 280, breakpoint: 'lg', collapsed: { mobile: !opened } }}
      padding="md"
      header={{ height: { base: 53, lg: 0 } }}
    >
      <AppShell.Header h={{ base: 53, lg: 0 }} p="sm" hiddenFrom="lg">
        <MobileHeader opened={opened} setOpened={setOpened} />
      </AppShell.Header>

      <Sidebar />

      <AppShell.Main>
        <PusherProvider {...pusherConfigProvider()}>
          <LiveNotifications />
          <ErrorBoundary>
            { children }
          </ErrorBoundary>
        </PusherProvider>
      </AppShell.Main>
    </AppShell>
  </>;
};
