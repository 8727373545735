import { useOwnerOptions } from '@components/hooks';
import { Divider, Grid, Group, Input, Loader, Select, Stack, Switch, TextInput } from '@mantine/core';
import { Feature } from 'flagged';
import { useResourceForm } from '@components/ui/form/ResourceForm.context';
import { ResourceForm } from '@components/ui/form/ResourceForm';
import { Translatable } from '@components/ui';
import { IMachineTypeRecord } from '@interfaces';
import { useIdentity } from '@components/data/Identity.context';

export const DetailsForm = () => {
  const { identity } = useIdentity();
  const { form } = useResourceForm<IMachineTypeRecord>();
  const { data: owners, isLoading: isOwnersLoading } = useOwnerOptions();

  const languages = identity?.owner?.supported_languages ?? [];

  const setTranslation = () => {
    const language = languages[0];

    if (form.values.type && !form.values.translations[language]) {
      form.setFieldValue('translations', { ...form.values.translations, [language]: form.values.type });
    }
  };

  return <>
    <Stack>
      <Grid>
        <Feature name="admin">
          <Grid.Col span={{ md: 12 }}>
            <Select
              label="Assign to owner"
              placeholder="Select an owner..."
              searchable
              leftSection={isOwnersLoading && <Loader size="xs" />}
              data={owners?.data || []}
              { ...form.getInputProps('owner_id') }
            />
          </Grid.Col>
        </Feature>

        <Grid.Col span={{ md: 12 }}>
          <TextInput label="Name" { ...form.getInputProps('type') } onBlur={setTranslation} maxLength={35} />
        </Grid.Col>

        { languages.length > 1 && <>
          <Grid.Col span={{ md: 12 }}>
            <Input.Label mb={0}>Name Translations</Input.Label>
            <Translatable field="translations" limit={35} />
          </Grid.Col>
        </>}
      </Grid>

      <Divider />

      <Switch label="Enabled"
              description="Marks this type as enabled or disabled"
              offLabel="No" onLabel="Yes"
              { ...form.getInputProps('is_active', { type: 'checkbox' }) }
      />

      <Divider mx="-md" />

      <Group justify="right">
        <ResourceForm.CancelButton />
        <ResourceForm.SubmitButton />
      </Group>
    </Stack>
  </>;
}
