import { openRequestDrawer } from '@components/ui/drawers/Requests/RequestDrawer';
import { RequestAction } from '@components/table/actions/RequestAction';
import { IconEdit } from '@tabler/icons-react';
import { useActionMenu } from '@components/table/actions/ActionMenu.context';
import { useOne } from '@refinedev/core';
import { hashQueryKey } from '@tanstack/query-core';

type Props = {
  id: number;
  type: 'help' | 'refund';
  label: string;
  disabled?: boolean;
};

export const EditRequestAction = ({ id, type, label, disabled }: Props) => {
  const { closeMenu } = useActionMenu();

  const { isFetching, refetch } = useOne({
    resource: type, id, queryOptions: {
      enabled: false,
      queryKeyHashFn: (queryKey) => hashQueryKey(queryKey.concat([id, label])), // Stops the button from spinning when the row is opened
    }
  });

  const handleClick = async () => {
    const { data } = await refetch();

    openRequestDrawer({
      type,
      request: data?.data.request,
      help: data?.data.request?.help,
      refund: data?.data.request?.refund,
      [type]: data?.data,
    });
    closeMenu();
  };

  return <RequestAction label={label} disabled={disabled} onClick={handleClick} isLoading={isFetching}
                        icon={<IconEdit size={18} stroke={1.5} />}
  />;
}
