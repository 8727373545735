import { createSafeContext } from '@mantine/utils';

interface MenuContext {
  id: number | string;
  closeMenu: () => void;
  openMenu: () => void;
}

export const [ActionMenuProvider, useActionMenu] = createSafeContext<MenuContext>(
  'ActionMenu component was not found in the tree'
);
