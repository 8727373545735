import { ActionIcon, CopyButton, Tooltip } from '@mantine/core';
import { IconCheck, IconCopy } from '@tabler/icons-react';

interface Props {
  value: any;
}

export const CopyValue = ({ value }: Props) => {
  return <CopyButton value={value} timeout={2000}>
    {({ copied, copy }) => (
      <Tooltip label={copied ? 'Copied' : 'Copy'} withArrow position="right">
        <ActionIcon color={copied ? 'teal' : 'gray'} variant="subtle" onClick={copy}>
          {copied ? <IconCheck size="1rem" />: <IconCopy size="1rem" />}
        </ActionIcon>
      </Tooltip>
    )}
  </CopyButton>;
}
