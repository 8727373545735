import { useDelete } from '@refinedev/core';
import { Menu } from '@mantine/core';
import { IconTrash } from '@tabler/icons-react';
import { confirmDeletionModal } from '@components/ui/modals/ConfirmDeletionModal';
import { useActionMenu } from '@components/table/actions/ActionMenu.context';
import { useTableResource } from '@components/table/TableResource.context';

interface Props {
  target?: string;
  disabled?: boolean;
}

export const DeleteAction = ({ target, disabled = false }: Props) => {
  const { mutate: destroy } = useDelete();
  const { id } = useActionMenu();
  const { resource } = useTableResource();

  return <Menu.Item
    color="red"
    disabled={disabled}
    onClick={() => confirmDeletionModal(() => destroy({ resource, id }), target)}
    leftSection={<IconTrash size={18} stroke={1.5} />}
    closeMenuOnClick={true}
  >
    Delete
  </Menu.Item>
}
