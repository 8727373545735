export { DrawersProvider } from './DrawersProvider';
export { useDrawers } from '@components/ui/drawerManager/use-drawers/use-drawers';
export {
  openDrawer,
  closeDrawer,
  closeAllDrawers,
  openContextDrawer,
  drawers,
} from './events';

export type { DrawersProviderProps } from './DrawersProvider';
export type {
  ContextDrawerProps,
  MantineDrawersOverride,
  MantineDrawers,
  MantineDrawer,
} from './context';
