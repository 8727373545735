import { PropsWithChildren } from 'react';
import { useIdentity } from '@components/data/Identity.context';
import { ICustomerRecord } from '@interfaces';
import { NotificationPreview } from '@components/ui/notificationPreviewers/NotificationPreview';

interface Props {
  customer: ICustomerRecord;
  type: 'Refund' | 'Help' | 'Feedback';
  message: string;
}

export const RequestLinkNotificationPreview = ({ customer, type, message: _message, children }: PropsWithChildren<Props>) => {
  const { identity } = useIdentity();

  const translations = identity.owner?.translations?.[customer.locale]?.sms;

  let message = '';
  if (type === 'Help') {
      message = translations?.help?.request_link;
  } else if (type === 'Refund') {
    message = translations?.refund?.request_link;
  }

  message = (message || '')
    .replace(':customer_name', customer.name)
    .replace(':owner_name', identity.owner?.name)
    .replace(':message', _message ?? '')

  return <>
    <NotificationPreview message={message}>
      {children}
    </NotificationPreview>
  </>;
}
