import { IPayoutEventLogRecord } from '@interfaces';
import { JsonCard } from '@components/data/cards/JsonCard';
import { Alert, Group } from '@mantine/core';
import { IconAlertCircle } from '@tabler/icons-react';

type Props = {
  item: IPayoutEventLogRecord;
}

export default ({ item }: Props) => {
  const errorMessage = item.payload.resource?.errors?.message || item.payload.FailureReason;

  return <Group style={{ width: '100%' }}>
    { errorMessage && <>
      <Alert style={{ width: '100%' }}
             icon={<IconAlertCircle size="1rem" />}
             variant="filled"
             color="red">
        { errorMessage }
      </Alert>
    </>}

    <JsonCard json={JSON.stringify(item.payload, undefined, 2)} />
  </Group>;
}
