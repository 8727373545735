import { forwardRef, ReactNode } from 'react';
import {
  createPolymorphicComponent,
  useProps,
  AlertProps,
  Alert,
  Group,
  ThemeIcon,
  Title, darken,
} from '@mantine/core';
import { IconLifebuoy } from '@tabler/icons-react';

export interface CallOutProps extends AlertProps {
  children?: ReactNode;
  type?: 'help',
}

const defaultProps: Partial<CallOutProps> = {
  p: 'sm',
  type: 'help',
  variant: 'light',
};

export const _CallOut = forwardRef<HTMLDivElement, CallOutProps>((_props, ref) => {
  const props = useProps('Alert', defaultProps, _props);

  const {
    title,
    icon: _icon,
    type,
    children,
    ...others
  } = props;

  const icon = type === 'help' ? <IconLifebuoy stroke={1.5} /> : null;

  return <Alert
    ref={ref}
    {...others}
  >
    { title && <Group mb={ children ? 'xs' : 0} gap="xs" wrap="nowrap">
      { icon && <ThemeIcon color={others.color} variant="transparent" style={{ alignSelf: 'start' }}>
        { icon }
      </ThemeIcon> }

      <Title size={children ? 16 : 14} lh={1.6} fw={500} c={darken('var(--alert-color)', 0.2)}>{ title }</Title>
    </Group>}

    { children }
  </Alert>;
}) as any;

export const CallOut = createPolymorphicComponent<'div', CallOutProps>(_CallOut);
