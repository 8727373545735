import { ITagListItem } from '@interfaces';
import { ResourceTable, ResourceTableProps } from '@components/table/ResourceTable';
import TagByAccountTableExpandedRow from './TagByAccountTableExpandedRow';
import TagByAccountTableColumns from './TagByAccountTableColumns';

export const TagByAccountTable = (props: ResourceTableProps<ITagListItem>) => {
  return <>
    <ResourceTable<ITagListItem>
      resource="tag_account"
      columns={[...TagByAccountTableColumns]}
      onRenderExpanded={TagByAccountTableExpandedRow}
      {...props}
    />
  </>;
}