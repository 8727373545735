import { useEffect, useRef, useState } from 'react';
import { ForwardRefWithStaticComponents } from '@mantine/utils';
import { TabsProps, Tabs as MantineTabs, TabsPanelProps, TabsTabProps, useMantineTheme } from '@mantine/core';
import { TabsContextProvider, useTabsContext } from './Tabs.context';
import { useResourceForm } from '@components/ui/form/ResourceForm.context';
import { useHash, useSetState } from '@mantine/hooks';
import { IconAlertTriangle } from '@tabler/icons-react';

interface ExtendedTabsProps extends TabsProps {
  withHash?: boolean;
}

type TabsComponent = ForwardRefWithStaticComponents<ExtendedTabsProps, {
  List: typeof MantineTabs.List;
  Tab: typeof MantineTabs.Tab;
  Panel: typeof MantineTabs.Panel;
}>;

export const Tabs: TabsComponent = (({ children, defaultValue, withHash = false, ...props }: ExtendedTabsProps) => {
  const [hash, setHash] = useHash({ getInitialValueInEffect: false });
  const [activeTab, setActiveTab] = useState(hash && withHash ? hash.replaceAll('#', '') : (defaultValue || props.value));
  const [errorTabs, setErrorTabs] = useSetState({});

  const onTabChange = (tab: string) => {
    setActiveTab(tab);
  };

  useEffect(() => {
    if (hash !== activeTab && withHash) {
      setHash(`#${activeTab}`);
    }
  }, [activeTab]);

  return (
    <TabsContextProvider value={{ onTabChange, errorTabs, setErrorTabs }}>
      <MantineTabs {...props} value={activeTab} onChange={setActiveTab}>
        {children}
      </MantineTabs>
    </TabsContextProvider>
  );
}) as any;

const Tab = (({ children, ...props }: TabsTabProps) => {
  const { errorTabs } = useTabsContext();
  const theme = useMantineTheme();

  const hasError = errorTabs[props.value];

  return (
    <MantineTabs.Tab {...props}
                     leftSection={hasError ? <IconAlertTriangle size={14} color={theme.colors.red[8]}/> : props.leftSection}
                     aria-invalid={hasError}>
      {children}
    </MantineTabs.Tab>
  );
}) as any;

const Panel = (({ children, ...props }: TabsPanelProps) => {
  const resourceForm = useResourceForm();
  const { setErrorTabs } = useTabsContext();
  const ref = useRef<HTMLDivElement>();

  useEffect(() => {
    const containsErrors = ref.current.querySelectorAll('[data-invalid="true"], [data-error="true"]').length > 0;
    setErrorTabs({ [props.value]: containsErrors });
  }, [resourceForm?.form?.errors]);

  return (
    <MantineTabs.Panel {...props} ref={ref}>
      {children}
    </MantineTabs.Panel>
  );
}) as any;

Tabs.List = MantineTabs.List;
Tabs.Tab = Tab;
Tabs.Panel = Panel;
