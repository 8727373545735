import { Box, Divider, Group, MultiSelect, SegmentedControl, Stack, Switch, Text, Title } from '@mantine/core';
import { useList } from '@refinedev/core';
import { IFilterOptionItem, ITagGroupRecord } from '@interfaces';
import { useResourceForm } from '@components/ui/form/ResourceForm.context';

export const TagGroupSettings = () => {
  const { form } = useResourceForm<ITagGroupRecord>();

  const { data: refundMethods } = useList<IFilterOptionItem>({
    resource: `filter/options/refund_methods`,
  });

  const { data: paymentMethods } = useList<IFilterOptionItem>({
    resource: `filter/options/payment_methods`,
  });

  const { data: requestTypes } = useList<IFilterOptionItem>({
    resource: `filter/options/request_types`,
  });

  return <Stack>
    <Group justify="space-between" wrap="nowrap">
      <Stack gap={0}>
        <Title order={5} fw={600}>Exclude equipment</Title>
        <Text size="xs" c="dimmed">Prevent request submissions for equipment in this group. Enable this option to exclude certain equipment from being eligible for requests, making them unavailable to customers.</Text>
      </Stack>

      <Switch offLabel="No" onLabel="Yes" size="md"
              {...form.getInputProps('settings.exclude_tags_active', { type: 'checkbox' })}
      />
    </Group>

    <Divider />

    <Group justify="space-between" wrap="nowrap">
      <Stack gap={0}>
        <Title order={5} fw={600}>Disable refunds</Title>
        <Text size="xs" c="dimmed">Prevent refunds for items in this group. Activating this setting will stop any refund requests from being collected for the equipment in this group.</Text>
      </Stack>

      <Switch offLabel="No" onLabel="Yes" size="md"
              {...form.getInputProps('settings.refunds_disabled', { type: 'checkbox' })}
      />
    </Group>

    <Divider />

    <Group justify="space-between" wrap="nowrap">
      <Stack gap={0}>
        <Title order={5} fw={600}>Disable refunds pre-auth period</Title>
        <Text size="xs" c="dimmed">Ignore the pre-authorization holding period. Use this setting to allow customers to immediately submit a refund request without needing to wait for the pre-authorization period to end.</Text>
      </Stack>

      <Switch offLabel="No" onLabel="Yes" size="md"
              {...form.getInputProps('settings.refunds_pre-auth_disabled', { type: 'checkbox' })}
      />
    </Group>

    <Divider />

    <Stack gap="sm">
      <Group justify="space-between" wrap="nowrap">
        <Stack gap={0}>
          <Title order={5} fw={600}>Override photo requirement</Title>
          <Text size="xs" c="dimmed">Set custom photo submission rules. Enable this to override the default requirement for photo uploads for equipment associated with this group.</Text>
        </Stack>

        <Switch offLabel="No" onLabel="Yes" size="md"
                {...form.getInputProps('settings.override_photo-requirement_active', { type: 'checkbox' })}
        />
      </Group>

      { form.values.settings['override_photo-requirement_active'] && <Box>
        <SegmentedControl
          w={300}
          data={[
            { label: 'Required', value: 'true' as any },
            { label: 'Optional', value: 'false' as any },
          ]}
          {...form.getInputProps('settings.override_photo-requirement')}
        />
      </Box>}
    </Stack>

    <Divider />

    <Stack gap="sm">
      <Group justify="space-between" wrap="nowrap">
        <Stack gap={0}>
          <Title order={5} fw={600}>Override request types</Title>
          <Text size="xs" c="dimmed">Customize request types for this group. Specify different request types (e.g., product requests, restocking, refunds) that are applicable only to this equipment group.</Text>
        </Stack>

        <Switch offLabel="No" onLabel="Yes" size="md"
                {...form.getInputProps('settings.override_request-types_active', { type: 'checkbox' })}
        />
      </Group>

      { form.values.settings['override_request-types_active'] && <>
        <MultiSelect
          placeholder="Pick value"
          data={requestTypes?.data ?? []}
          {...form.getInputProps('settings.override_request-types')}
        />
      </>}
    </Stack>

    <Divider />

    <Stack gap="sm">
      <Group justify="space-between" wrap="nowrap">
        <Stack gap={0}>
          <Title order={5} fw={600}>Override payment methods</Title>
          <Text size="xs" c="dimmed">Set custom payment methods for equipment in this group. Select specific payment methods (e.g., cash, card) that are allowed when handling transactions for this group.</Text>
        </Stack>

        <Switch offLabel="No" onLabel="Yes" size="md"
                {...form.getInputProps('settings.override_payment-methods_active', { type: 'checkbox' })}
        />
      </Group>

      { form.values.settings['override_payment-methods_active'] && <>
        <MultiSelect
          placeholder="Pick value"
          data={paymentMethods?.data ?? []}
          {...form.getInputProps('settings.override_payment-methods')}
        />
      </>}
    </Stack>

    <Divider />

    <Stack gap="sm">
      <Group justify="space-between" wrap="nowrap">
        <Stack gap={0}>
          <Title order={5} fw={600}>Override refund methods</Title>
          <Text size="xs" c="dimmed">Specify how refunds are processed for this group. Choose particular refund methods (e.g., Venmo, PayPal) that will be used for equipment under this group.</Text>
        </Stack>

        <Switch offLabel="No" onLabel="Yes" size="md"
                {...form.getInputProps('settings.override_refund-methods_active', { type: 'checkbox' })}
        />
      </Group>

      { form.values.settings['override_refund-methods_active'] && <>
        <MultiSelect
          placeholder="Pick value"
          data={refundMethods?.data ?? []}
          {...form.getInputProps('settings.override_refund-methods')}
        />
      </>}
    </Stack>
  </Stack>
}
