import { Loader, Menu } from '@mantine/core';
import { IconArchive } from '@tabler/icons-react';
import { useActionMenu } from '@components/table/actions/ActionMenu.context';
import { ConfirmAction } from '@components/actions/ConfirmAction';
import { useTableResource } from '@components/table/TableResource.context';
import { useUnarchive } from '@components/hooks/useUnarchive';

interface Props {
  disabled?: boolean;
}

export const UnarchiveAction = ({ disabled = false }: Props) => {
  const { closeMenu, id } = useActionMenu();
  const { resource } = useTableResource();
  const { unarchive, isLoading } = useUnarchive(resource);

  return <ConfirmAction onConfirm={async () =>{
    await unarchive(id);
    closeMenu();
  }}>
    {(onConfirm) => <Menu.Item
      color="dark.4"
      disabled={disabled}
      onClick={onConfirm}
      leftSection={isLoading ? <Loader size={16} /> : <IconArchive size={18} stroke={1.5} />}
    >
      Unarchive
    </Menu.Item>}
  </ConfirmAction>
}
