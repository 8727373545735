import { Loader, Menu } from '@mantine/core';
import { IconRefresh } from '@tabler/icons-react';
import { useApiUrl, useCustom } from '@refinedev/core';
import { ConfirmAction } from '@components/actions/ConfirmAction';
import { useActionMenu } from '@components/table/actions/ActionMenu.context';

type Props = {
  disabled?: boolean;
};

export const ReprocessVoiceMessageAction = ({ disabled }: Props) => {
  const { closeMenu, id } = useActionMenu();
  const apiUrl = useApiUrl();

  const { refetch, isFetching } = useCustom({
    url: `${apiUrl}/voice_message/${id}/reprocess`,
    method: 'get',
    queryOptions: { enabled: false, retry: false }
  });

  return <ConfirmAction onConfirm={async () =>{
    await refetch();
    closeMenu();
  }}>
    {(onConfirm) => <Menu.Item
      color="blue"
      disabled={disabled}
      onClick={onConfirm}
      leftSection={isFetching ? <Loader size={16} /> : <IconRefresh size={18} stroke={1.5} />}
    >
      Reprocess voice message
    </Menu.Item>}
  </ConfirmAction>;
}
