import { useEffect, useState } from 'react';
import { EuiRange, EuiPopoverTitle } from '@elastic/eui';
import { FilterPopover } from '@components/filters/FilterPopover';
import { FilterProvider, useFilter } from '@components/filters/Filter.context';
import { useDebouncedValue, useDidUpdate } from '@mantine/hooks';

type Props = {
  label: string;
  min: number;
  max: number;
};

type FilterProps = {
  name: string;
  value?: number;
} & Props;

const Filter = ({ label, min, max }: Props) => {
  const { value: filterValue, setValue: setFilterValue } = useFilter<number>();
  const [ value, setValue ] = useState(filterValue);
  const [ delayedValue ] = useDebouncedValue(value, 400);

  useDidUpdate(() => {
    setFilterValue(value);
  }, [delayedValue])

  useEffect(() => {
    setValue(filterValue);
  }, [filterValue]);

  const isActive = value !== min;

  return <FilterPopover
    label={label}
    buttonProps={{
      hasActiveFilters: isActive
    }}
  >
    <EuiPopoverTitle paddingSize="s">
        <EuiRange
          value={value}
          onChange={(e) => {
            const value = e.target['value'];
            setValue(value);
          }}
          min={min}
          max={max}
          showInput
          showLabels
          showValue
          valueAppend="+"
        />
      </EuiPopoverTitle>
  </FilterPopover>;
}

export const FilterSingleRange = ({ label, name, value = 0, min, max }: FilterProps) => {
  return <FilterProvider name={name} defaultValue={value}>
    <Filter label={label} min={min} max={max} />
  </FilterProvider>
};