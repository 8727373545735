import { useEffect, useRef } from 'react';
import { ForwardRefWithStaticComponents } from '@mantine/utils';
import { Accordion as MantineAccordion, AccordionItemProps, AccordionProps } from '@mantine/core';
import { useResourceForm } from '@components/ui/form/ResourceForm.context';
import { useSetState } from '@mantine/hooks';

type AccordionComponent = ForwardRefWithStaticComponents<AccordionProps, {
  Item: typeof MantineAccordion.Item,
  Control:  typeof MantineAccordion.Control,
  Panel:  typeof MantineAccordion.Panel,
}>;

export const Accordion: AccordionComponent = (({ children, ...props }: AccordionProps) => {
  return <MantineAccordion {...props}>{children}</MantineAccordion>;
}) as any;

const Item = (({ children, ...props }: AccordionItemProps) => {
  const { form } = useResourceForm();
  const [errorTabs, setErrorTabs] = useSetState({});
  const ref = useRef<HTMLDivElement>();

  useEffect(() => {
    const containsErrors = ref.current.querySelectorAll('[data-invalid="true"]').length > 0;
    setErrorTabs({ [props.value]: containsErrors });
  }, [form.errors]);

  const hasError = errorTabs[props.value];

  return (
    <MantineAccordion.Item {...props} ref={ref} aria-invalid={hasError}>
      {children}
    </MantineAccordion.Item>
  );
}) as any;

Accordion.Item = Item;
Accordion.Control = MantineAccordion.Control;
Accordion.Panel = MantineAccordion.Panel;
