import { ReactNode, useState } from 'react';
import { Box, Button, Divider, Group, Modal } from '@mantine/core';
import { EquipmentSelector } from '@components/ui/EquipmentSelector';
import { ITagListItem } from '@interfaces';

interface Props {
  title: string | ReactNode;
  opened: boolean;
  close: () => void,
  onChange: (tag: ITagListItem) => void,
}

export const EquipmentLookupModal = ({ title, opened, close, onChange }: Props) => {
  const [selectedTag, setSelectedTag] = useState<ITagListItem>();

  return <Modal.Root opened={opened} onClose={close} withinPortal>
    <Modal.Overlay />
    <Modal.Content>
      <Modal.Header>
        <Modal.Title lh={1.7}>{ title }</Modal.Title>
        <Modal.CloseButton />
      </Modal.Header>
      <Modal.Body p={0}>
        <Box px="md">
          <EquipmentSelector onChange={setSelectedTag} autoFocus />
        </Box>

        <Divider mt="md" />

        <Group justify="right" px="md" py="sm">
          <Button variant="light"
                  color="gray"
                  onClick={() => {
                    close();
                    setSelectedTag(null);
                  }}
          >Cancel</Button>
          <Button type="submit"
                  variant="light"
                  disabled={!selectedTag}
                  onClick={() => {
                    onChange(selectedTag);
                    close();
                  }}
          >Confirm</Button>
        </Group>

      </Modal.Body>
    </Modal.Content>
  </Modal.Root>;
}
