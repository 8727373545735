import { Stack, Switch, Text, Title, Group, Divider, MultiSelect, Box } from '@mantine/core';
import { Translatable } from '@components/ui';
import { useResourceForm } from '@components/ui/form/ResourceForm.context';
import { IOwnerRecord } from '@interfaces';

export const SMSNotificationSettings = () => {
  const { form } = useResourceForm<IOwnerRecord>();

  const supportedLanguages = form.values.supported_languages;
  const enabledLanguages = [
    { value: 'en', label: 'English' },
    { value: 'es', label: 'Español' },
    { value: 'fr', label: 'French' },
  ].filter(v => supportedLanguages.includes(v.value) || form.values.sms_languages.includes(v.value));

  return <Stack gap="sm">
    <Box>
      <Stack gap={0}>
        <Title order={5} fw={600}>Initial SMS languages</Title>
        <Text size="xs" c="dimmed">Includes the initial message in all selected supported languages</Text>
      </Stack>

      <MultiSelect mt="xs" data={enabledLanguages}
                   {...form.getInputProps('sms_languages')}
      />
    </Box>

    <Divider />

    <Box>
      <Group justify="space-between" wrap="nowrap">
        <Stack gap={0}>
          <Title order={5} fw={600}>Help request received</Title>
          <Text size="xs" c="dimmed">Sends a notification to the customer when their help request is created</Text>
        </Stack>

        <Switch offLabel="No" onLabel="Yes" size="md"
                {...form.getInputProps('settings.request_sms_send-help-request-received', { type: 'checkbox' })}
        />
      </Group>

      <Translatable field="translations._lang_.sms.help.received"
                    languages={supportedLanguages}
                    tokens={[':customer_name', ':owner_name']} />
    </Box>

    <Box>
      <Group justify="space-between" wrap="nowrap">
        <Stack gap={0}>
          <Title order={5} fw={600}>Help request closed</Title>
          <Text size="xs" c="dimmed">Sends a notification to the customer when their help request is closed</Text>
        </Stack>

        <Switch offLabel="No" onLabel="Yes" size="md"
                {...form.getInputProps('settings.request_sms_send-help-request-closed', { type: 'checkbox' })}
        />
      </Group>

      <Translatable field="translations._lang_.sms.help.closed"
                    languages={supportedLanguages}
                    tokens={[':customer_name', ':owner_name']} />
    </Box>

    <Divider />

    <Box>
      <Group justify="space-between" wrap="nowrap">
        <Stack gap={0}>
          <Title order={5} fw={600}>Feedback request received</Title>
          <Text size="xs" c="dimmed">Sends a notification to the customer when their feedback is created</Text>
        </Stack>

        <Switch offLabel="No" onLabel="Yes" size="md"
                {...form.getInputProps('settings.request_sms_send-feedback-request-received', { type: 'checkbox' })}
        />
      </Group>

      <Translatable field="translations._lang_.sms.feedback.received"
                    languages={supportedLanguages}
                    tokens={[':customer_name', ':owner_name']} />
    </Box>

    <Divider />

    <Box>
      <Group justify="space-between" wrap="nowrap">
        <Stack gap={0}>
          <Title order={5} fw={600}>Refund approved</Title>
          <Text size="xs" c="dimmed">Sends a notification to the customer when their refund is approved</Text>
        </Stack>

        <Switch offLabel="No" onLabel="Yes" size="md"
                {...form.getInputProps('settings.request_sms_send-refund-approved', { type: 'checkbox' })}
        />
      </Group>

      <Translatable field="translations._lang_.sms.refund.approved_online_method"
                    languages={supportedLanguages}
                    label="Online methods - Venmo, PayPal, Zelle, market cards"
                    tokens={[':customer_name', ':owner_name', ':refund_method', ':additional_note']} />

      <Translatable field="translations._lang_.sms.refund.approved_cash_method"
                    languages={supportedLanguages}
                    label="Cash/Check methods"
                    tokens={[':customer_name', ':owner_name', ':additional_note']} />

      <Translatable field="translations._lang_.sms.refund.approved_reversal_method"
                    languages={supportedLanguages}
                    label="Reversal method"
                    tokens={[':customer_name', ':owner_name', ':additional_note']} />
    </Box>

    <Divider />

    <Box>
      <Group justify="space-between" wrap="nowrap">
        <Stack gap={0}>
          <Title order={5} fw={600}>Refund declined</Title>
          <Text size="xs" c="dimmed">Sends a notification to the customer when their refund is declined</Text>
        </Stack>

        <Switch offLabel="No" onLabel="Yes" size="md"
                {...form.getInputProps('settings.request_sms_send-refund-declined', { type: 'checkbox' })}
        />
      </Group>

      <Translatable field="translations._lang_.sms.refund.declined"
                    languages={supportedLanguages}
                    tokens={[':customer_name', ':owner_name', ':additional_note']} />
    </Box>

    <Divider />

    <Title order={4} fw={600}>Pre Auth</Title>

    <Divider />

    <Box>
      <Group justify="space-between" wrap="nowrap">
        <Stack gap={0}>
          <Title order={5} fw={600}>Refund details received</Title>
          <Text size="xs" c="dimmed">Sends a notification to the customer when their refund details are provided</Text>
        </Stack>

        <Switch offLabel="No" onLabel="Yes" size="md"
                {...form.getInputProps('settings.request_sms_send-refund-details-received', { type: 'checkbox' })}
        />
      </Group>

      <Translatable field="translations._lang_.sms.refund.received"
                    languages={supportedLanguages}
                    tokens={[':customer_name', ':owner_name']} />
    </Box>

    <Divider />

    <Box>
      <Group justify="space-between" wrap="nowrap">
        <Stack gap={0}>
          <Title order={5} fw={600}>Delayed refund follow-up message</Title>
        </Stack>
      </Group>

      <Translatable field="translations._lang_.sms.refund.delayed_follow_up"
                    languages={supportedLanguages}
                    tokens={[':customer_name', ':owner_name', ':url']} />
    </Box>

    <Divider />

    <Box>
      <Group justify="space-between" wrap="nowrap">
        <Stack gap={0}>
          <Title order={5} fw={600}>Instant refund follow-up message</Title>
        </Stack>
      </Group>

      <Translatable field="translations._lang_.sms.refund.instant_follow_up"
                    languages={supportedLanguages}
                    tokens={[':customer_name', ':owner_name', ':url']} />

    </Box>

    <Divider />

    <Title order={4} fw={600}>Request Link</Title>

    <Divider />

    <Box>
      <Translatable field="translations._lang_.sms.help.request_link"
                    languages={supportedLanguages}
                    label="Request for Help Details"
                    tokens={[':customer_name', ':owner_name', ':url', ':message']} />

      <Translatable field="translations._lang_.sms.refund.request_link"
                    languages={supportedLanguages}
                    label="Request for Refund Details"
                    tokens={[':customer_name', ':owner_name', ':url', ':message']} />
    </Box>
  </Stack>;
}
