import { EuiBasicTableColumn } from '@elastic/eui';
import { ITransactionListItem } from '@interfaces';
import { DateTime } from '@components/ui/DateTime';
import { Badge } from '@mantine/core';

const money = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});

export default [
  {
    field: 'type',
    name: 'Type',
    sortable: true,
    truncateText: true,
    width: '150',
    render: (type) => {
      const color = type === 'withdraw' ? 'red' : type === 'credit' ? 'blue' : 'green';
      return <Badge style={{ textTransform: 'none' }} p="xs" color={color}>{type}</Badge>
    },
  },
  {
    field: 'amount',
    name: 'Amount',
    sortable: true,
    truncateText: true,
    render: (amount) => `${money.format(amount / 100)}`,
  },
  {
    field: 'completed_at',
    name: 'Completed',
    width: '165',
    sortable: true,
    dataType: 'date',
    render: (date) => <DateTime value={date} />
  },
  {
    field: 'created_at',
    name: 'Created',
    width: '165',
    sortable: true,
    dataType: 'date',
    render: (date) => <DateTime value={date} />
  },
] as EuiBasicTableColumn<ITransactionListItem>[];
