import { PropsWithChildren, useState } from 'react';
import { Popover, Text } from '@mantine/core';

interface Props {
  message: string;
}

export const NotificationPreview = ({ message, children }: PropsWithChildren<Props>) => {
  const [popoverOpened, setPopoverOpened] = useState(false);

  return <>
    <Popover opened={popoverOpened}
             position="bottom"
             width="target"
             offset={3}
             shadow="md"
             disabled={!message}
             withArrow withinPortal
             transitionProps={{ transition: 'pop' }}
    >
      <Popover.Target>
        <div
          onFocusCapture={() => setPopoverOpened(true)}
          onBlurCapture={() => setPopoverOpened(false)}
        >
          { children }
        </div>
      </Popover.Target>
      <Popover.Dropdown>
        <Text size="sm" fw={600}>Notification message</Text>
        <Text size="sm">{ message }</Text>
      </Popover.Dropdown>
    </Popover>
  </>;
}
