import { useIdentity } from '@components/data/Identity.context';
import { useChannel, useEvent, } from '@harelpls/use-pusher';
import { NotificationData, notifications } from '@mantine/notifications';
import { notificationMessageOptions } from '@components/ui/notifications/messages';

export type Payload = {
  id: string | number;
  verb: string;
  type: string;
  progress?: number;
  total?: number;
}

export function LiveNotifications() {
  const { identity } = useIdentity();

  const trigger = (notification: NotificationData & { id: string }) => {
    if (document.getElementById(notification.id)) {
      notifications.update(notification)
    } else {
      notifications.show(notification)
    }
  }

  const userChannel = useChannel(`private-activity.user.${identity?.id}`);
  const ownerChannel = useChannel(`private-activity.owner.${identity?.owner?.id ?? 0}`);

  useEvent(userChannel, 'activity', (data: Payload) => {
    trigger({id: `activity-user-${data.type}-${data.id}`, ...notificationMessageOptions(data) });
  });

  useEvent(ownerChannel, 'activity', (data: Payload) => {
    trigger({id: `activity-owner-${data.type}-${data.id}`, ...notificationMessageOptions(data) });
  });

  return <></>;
}
