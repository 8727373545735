import { CollapsableCard } from '@components/ui';
import { Box, Table, Text, Title } from '@mantine/core';
import { IRefundRecord } from '@interfaces';
import { Currency } from '@components/ui/Currency';

interface Props {
  refund: IRefundRecord;
}

export const RefundHistoryCard = ({ refund }: Props) => {
  const refund_history = refund.refund_history;

  return <CollapsableCard label="History">
    <Text size="sm">Customer refunds history (including this request).</Text>

    <Box mt="sm" mb="lg" style={{ overflow: 'hidden', overflowX: 'auto' }}>
      <Table horizontalSpacing="sm" verticalSpacing="sm">
        <Table.Thead>
          <Table.Tr>
            <Table.Th style={{ width: '50%' }}>Period</Table.Th>
            <Table.Th style={{ textAlign: 'center' }}>Requests</Table.Th>
            <Table.Th style={{ textAlign: 'right' }}>Totalling</Table.Th>
            <Table.Th style={{ textAlign: 'right' }}>Approved</Table.Th>
          </Table.Tr>
        </Table.Thead>
        <Table.Tbody>
          <Table.Tr>
            <Table.Td>Last 24 hours</Table.Td>
            <Table.Td style={{ textAlign: 'center' }}>{refund_history.stats['1_day'].requests}</Table.Td>
            <Table.Td style={{ textAlign: 'right' }}><Currency value={refund_history.stats['1_day'].total_amount} /></Table.Td>
            <Table.Td style={{ textAlign: 'right' }}><Currency value={refund_history.stats['1_day'].approved_amount} /></Table.Td>
          </Table.Tr>
          <Table.Tr>
            <Table.Td>Last 7 days</Table.Td>
            <Table.Td style={{ textAlign: 'center' }}>{refund_history.stats['7_days'].requests}</Table.Td>
            <Table.Td style={{ textAlign: 'right' }}><Currency value={refund_history.stats['7_days'].total_amount} /></Table.Td>
            <Table.Td style={{ textAlign: 'right' }}><Currency value={refund_history.stats['7_days'].approved_amount} /></Table.Td>
          </Table.Tr>
          <Table.Tr>
            <Table.Td>Last 30 days</Table.Td>
            <Table.Td style={{ textAlign: 'center' }}>{refund_history.stats['30_days'].requests}</Table.Td>
            <Table.Td style={{ textAlign: 'right' }}><Currency value={refund_history.stats['30_days'].total_amount} /></Table.Td>
            <Table.Td style={{ textAlign: 'right' }}><Currency value={refund_history.stats['30_days'].approved_amount} /></Table.Td>
          </Table.Tr>
          <Table.Tr>
            <Table.Td>All Time</Table.Td>
            <Table.Td style={{ textAlign: 'center' }}>{refund_history.stats['all_time'].requests}</Table.Td>
            <Table.Td style={{ textAlign: 'right' }}><Currency value={refund_history.stats['all_time'].total_amount} /></Table.Td>
            <Table.Td style={{ textAlign: 'right' }}><Currency value={refund_history.stats['all_time'].approved_amount} /></Table.Td>
          </Table.Tr>
        </Table.Tbody>
      </Table>
    </Box>

    <Title order={5}>Request amount</Title>

    <Box mt="sm">
      <Table horizontalSpacing="sm" verticalSpacing="sm">
        <Table.Tbody>
          <Table.Tr>
            <Table.Td style={{ width: '100%' }}>This request</Table.Td>
            <Table.Td style={{ textAlign: 'right' }}><Currency value={refund.amount} /></Table.Td>
          </Table.Tr>
          <Table.Tr>
            <Table.Td style={{ width: '100%' }}>Average company refund last 90 days</Table.Td>
            <Table.Td style={{ textAlign: 'right' }}><Currency value={refund_history.average_owner_amount} /></Table.Td>
          </Table.Tr>
          <Table.Tr>
            <Table.Td style={{ width: '100%' }}>Average industry refund last 90 days</Table.Td>
            <Table.Td style={{ textAlign: 'right' }}><Currency value={refund_history.average_industry_amount} /></Table.Td>
          </Table.Tr>
        </Table.Tbody>
      </Table>
    </Box>
  </CollapsableCard>;
}
