import { useApiUrl, useCustomMutation, useInvalidate } from '@refinedev/core';

export const useSendPreAuthFollowUpSMS = () => {
  const apiUrl = useApiUrl();
  const invalidate = useInvalidate();
  const mutation = useCustomMutation();

  const send = (refundId) => mutation.mutate({
    method: 'post',
    url: `${apiUrl}/refund/${refundId}/send_pre_auth_follow_up_sms`,
    values: {},
    successNotification: { type: 'success', message: 'Successfully sent notification message' }
  }, {
    onSuccess: () => {
      invalidate({ resource: 'refund', id: refundId, invalidates: ['detail'] });
    }
  });

  return {
    ...mutation,
    send
  };
};
