import { Flex, Text, Tooltip } from '@mantine/core';
import { EuiBasicTableColumn } from '@elastic/eui';
import { ITagListItem } from '@interfaces';
import { DateTime } from '@components/ui/DateTime';
import { IconLink } from '@tabler/icons-react';

export default [
  {
    field: 'owner_name',
    name: 'Owner',
    width: '150',
    sortable: true,
    truncateText: true,
    permissions: ['admin']
  },
  {
    field: 'account_name',
    name: 'Account',
    truncateText: true,
    sortable: true,
  },
  {
    field: 'help_requests_count',
    name: 'Help',
    width: '80',
    align: 'center',
    sortable: true,
  },
  {
    field: 'voice_messages_count',
    name: 'Calls',
    width: '85',
    align: 'center',
    sortable: true,
  },
  {
    field: 'feedback_requests_count',
    name: 'Feedback',
    width: '90',
    align: 'center',
    sortable: true,
  },
  {
    field: 'refunds_count',
    name: 'Refunds',
    width: '80',
    align: 'center',
    sortable: true,
  },
  {
    field: 'refunds_sum_amount',
    name: 'Requested',
    width: '110',
    align: 'center',
    sortable: true,
    render: (value) => `$${value || 0}`,
  },
] as EuiBasicTableColumn<ITagListItem>[];
