import { useApiUrl, useCustomMutation } from "@refinedev/core";

export const useResendUserInvitation = () => {
  const apiUrl = useApiUrl();
  const mutation = useCustomMutation();

  const sendInvitation = (userId) => mutation.mutate({
    method: 'post',
    url: `${apiUrl}/user/resend_invitation/${userId}`,
    values: {},
    successNotification: { type: 'success', message: 'Successfully resent invitational email.' }
  });

  return {
    ...mutation,
    sendInvitation
  };
};