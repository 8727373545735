import { IResourceComponentsProps, useNavigation } from "@refinedev/core";
import { EuiBasicTableColumn } from '@elastic/eui';
import { IApiKeyListItem, IApiKeyListResponse } from '@interfaces';
import { ResourceTable } from '@components/table/ResourceTable';
import { Button, Card, Group, Menu, Text, Title } from '@mantine/core';
import { IconPlus } from '@tabler/icons-react';
import { FilterSelect } from '@components/filters/FilterSelect';
import { FilterBar } from '@components/filters/FilterBar';
import { TableFilterProvider } from '@components/table/TableFilter.context';
import { useFeature } from 'flagged';
import { DateTime } from '@components/ui/DateTime';
import { EditAction } from '@components/table/actions/EditAction';
import { DeleteAction } from '@components/table/actions/DeleteAction';
import { StatusIndicator } from '@components/ui/StatusIndicator';
import { CopyValue } from '@components/ui/CopyValue';
import { ActionMenu } from '@components/table/actions/ActionMenu';

export function ApiKeyList({ initialData }: IResourceComponentsProps<IApiKeyListResponse>) {
  const { create } = useNavigation();
  const isAdmin = useFeature('admin');

  const columns = [
    {
      field: 'owner_name',
      name: 'Owner',
      width: '150',
      sortable: true,
      truncateText: true,
      permissions: ['admin']
    },
    {
      field: 'name',
      name: 'Name',
      sortable: true,
      truncateText: true,
    },
    {
      field: 'api_key',
      name: 'API Key',
      width: '280',
      truncateText: true,
      render: (value) => <Group gap="xs">
        <Text truncate="end" flex={1}>{value}</Text>
        <CopyValue value={value} />
      </Group>
    },
    {
      field: 'is_active',
      name: 'Status',
      sortable: true,
      truncateText: true,
      render: (status) => <StatusIndicator status={status ? 'Enabled' : 'Disabled'} />,
    },
    {
      field: 'created_at',
      name: 'Created',
      width: '165',
      sortable: true,
      dataType: 'date',
      render: (date) => <DateTime value={date} />
    },
    {
      name: '',
      width: '40',
      actions: [
        {
          render: (record) => <ActionMenu id={record.id}>
            <EditAction />
            <Menu.Divider />
            <DeleteAction target={record.name} />
          </ActionMenu>
        },
      ]
    },
  ] as EuiBasicTableColumn<IApiKeyListItem>[];

  const actions = <>
    <Button leftSection={<IconPlus size={16} />}
            onClick={() => create('api_key')}>
      Add Key
    </Button>
  </>;

  const filters = isAdmin ? <FilterSelect name="owners" label="Owner" route="owners" /> : null;

  return <TableFilterProvider>
    <FilterBar actions={actions} filters={filters} />

    <Card withBorder p="md" radius="sm" mb="md">
      <Card.Section p="md" withBorder>
        <Title order={5} fw="500">Documentation</Title>
      </Card.Section>

      <Card.Section withBorder p="md">
        <Text size="sm">
          View our available API documentation here — <a href="https://api.zippyassist.com/docs" target="_blank">Go to docs</a>.
        </Text>
      </Card.Section>
    </Card>

    <ResourceTable resource="api_key"
                   initialData={initialData}
                   columns={columns}
                   allowExports={false}
                   syncWithLocation={true} />
  </TableFilterProvider>;
}
