import { CollapsableCard } from '@components/ui';
import { IRefundRecord } from '@interfaces';
import { SimpleReadOnlyForm } from '@components/data/forms/SimpleReadOnlyForm';

type Props = {
  refund: IRefundRecord;
}

const METHOD_MAP = {
  Cash: 'Cash',
  Card: 'Credit or debit card',
  PayRange: 'PayRange',
  ApplePay: 'ApplePay®',
  AppleCard: 'Apple Card',
  GooglePay: 'GooglePay®',
  Other: 'Other',
};

export const PaymentDetailsCard = ({ refund }: Props) => {
  const additionalFields = [];

  if (refund?.payment_method_attributes?.first_six_digits) {
    additionalFields.push({ label: 'First Six Digits', value: refund.payment_method_attributes.first_six_digits });
  }

  if (refund?.payment_method_attributes?.last_four_digits) {
    additionalFields.push({ label: 'Last Four Digits', value: refund.payment_method_attributes.last_four_digits });
  }

  return <CollapsableCard label="Payment details">
    <SimpleReadOnlyForm fields={[
      {label: 'Type', value: refund.payment_method.name ?? METHOD_MAP[refund.payment_method.type]},
      ...additionalFields
    ]} />
  </CollapsableCard>;
}