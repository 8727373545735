import { CollapsableCard } from '@components/ui';
import { EuiImage } from '@elastic/eui';
import { IHelpRecord } from '@interfaces';
import { Grid } from '@mantine/core';
import { TextareaAutosize } from '@components/ui/TextareaAutosize';

interface Props {
  help: IHelpRecord;
}

export const ReasonCard = ({ help }: Props) => {

  return <CollapsableCard label={`${help.request_type.type} reason`}>
    <Grid>
      <Grid.Col span={help.photo_path ? 8 : 12}>
        <TextareaAutosize value={help.issue} readOnly />
      </Grid.Col>

      {help.photo_path &&
        <Grid.Col span={4}>
          <EuiImage allowFullScreen alt="" src={help.photo_path} />
        </Grid.Col>
      }
    </Grid>
  </CollapsableCard>;
}
