import { EuiBasicTableColumn } from '@elastic/eui';
import { ICustomerListItem } from '@interfaces';
import { CustomerAvatar } from '@components/ui/CustomerAvatar';

export default [
  {
    field: 'owner_name',
    name: 'Owner',
    width: '150',
    sortable: true,
    truncateText: true,
    permissions: ['admin']
  },
  {
    field: 'name',
    name: 'Name',
    sortable: true,
    width: '250',
    render: (_name, record) => <CustomerAvatar customer={record} />,
  },
  {
    field: 'phone_number',
    name: 'Mobile',
    truncateText: true,
    sortable: true,
  },
  {
    field: 'email',
    name: 'Email',
    truncateText: true,
    sortable: true,
  },
  {
    field: 'help_requests_count',
    name: 'Help',
    width: '100',
    align: 'center',
    sortable: true,
  },
  {
    field: 'voice_messages_count',
    name: 'Calls',
    width: '85',
    align: 'center',
    sortable: true,
  },
  {
    field: 'feedback_requests_count',
    name: 'Feedback',
    width: '100',
    align: 'center',
    sortable: true,
  },
  {
    field: 'refunds_count',
    name: 'Refunds',
    width: '100',
    align: 'center',
    sortable: true,
  },
  {
    field: 'refunds_sum_amount',
    name: 'Requested',
    width: '120',
    align: 'center',
    sortable: true,
    render: (amount) => `$${amount || 0}`,
  },
] as EuiBasicTableColumn<ICustomerListItem>[];
