import { modals } from '@mantine/modals';
import type { OpenConfirmModal } from '@mantine/modals/lib/context';

export const confirmModal = (payload: OpenConfirmModal = {}) => modals.openConfirmModal({
    title: payload.title || 'Please confirm this action',
    children: payload.children,
    labels: {
      confirm: payload.labels?.confirm ?? 'Confirm',
      cancel: payload.labels?.cancel ??'Cancel'
    },
    onCancel: payload.onCancel,
    onConfirm: payload.onConfirm,
});
