import { EuiBasicTableColumn } from '@elastic/eui';
import { IResourceComponentsProps, useNavigation } from "@refinedev/core";
import { IOwnerListItem, IOwnerListResponse } from '@interfaces';
import { ResourceTable } from '@components/table/ResourceTable';
import { Button, Group, Menu, Text } from '@mantine/core';
import { IconPlus } from '@tabler/icons-react';
import { FilterBar } from '@components/filters/FilterBar';
import { TableFilterProvider } from '@components/table/TableFilter.context';
import { DateTime } from '@components/ui/DateTime';
import { EditAction } from '@components/table/actions/EditAction';
import { DeleteAction } from '@components/table/actions/DeleteAction';
import { CopyValue } from '@components/ui/CopyValue';
import { ActionMenu } from '@components/table/actions/ActionMenu';

export function OwnerList({ initialData }: IResourceComponentsProps<IOwnerListResponse>) {
  const { create } = useNavigation();

  const money = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });

  const columns = [
    {
      field: 'name',
      name: 'Name',
      sortable: true,
      truncateText: true
    },
    {
      field: 'phone_number_list',
      name: 'Phone number',
      sortable: true,
      truncateText: true,
    },
    {
      field: 'stripe_id',
      name: 'Stripe ID',
      sortable: true,
      truncateText: true,
      render: (value) => value ? <Group gap="xs">
        <Text truncate="end" flex={1}>{value}</Text>
        <CopyValue value={value} />
      </Group> : 'N/A'
    },
    {
      field: 'wallet_balance',
      name: 'Balance',
      width: '100',
      sortable: true,
      render: (value) => value ? money.format(value / 100) : 'N/A'
    },
    {
      field: 'country_code',
      name: 'Country',
      width: '80',
      sortable: true,
    },
    {
      field: 'is_onboarding',
      name: 'Onboarding',
      width: '100',
      sortable: true,
      render: (value) => value ? 'Yes' : 'No'
    },
    {
      field: 'is_active',
      name: 'Active',
      width: '70',
      sortable: true,
      render: (value) => value ? 'Yes' : 'No'
    },
    {
      field: 'created_at',
      name: 'Created',
      width: '165',
      sortable: true,
      dataType: 'date',
      render: (date) => <DateTime value={date} />
    },
    {
      name: '',
      width: '40',
      actions: [
        {
          render: (record) => <ActionMenu id={record.id}>
            <EditAction />
            <Menu.Divider />
            <DeleteAction target={record.name} />
          </ActionMenu>
        },
      ]
    },
  ] as EuiBasicTableColumn<IOwnerListItem>[];

  const actions = <>
    <Button leftSection={<IconPlus size={16} />}
            onClick={() => create('owner')}>
      Add owner
    </Button>
  </>;

  return <TableFilterProvider>
    <FilterBar actions={actions} withDatePicker={false} />

    <ResourceTable<IOwnerListItem>
      resource="owner"
      initialData={initialData}
      columns={columns}
      allowExports={false}
      syncWithLocation={true}
    />
  </TableFilterProvider>;
}
