import { IWebhookLogListItem, IWebhookLogRecord } from '@interfaces';
import { TableRow } from '@components/table/TableRow';
import { Group } from '@mantine/core';
import { JsonCard } from '@components/data/cards/JsonCard';

type Props = {
  item: IWebhookLogListItem;
}

type TabContentProps = {
  record: IWebhookLogRecord;
}

const TabContent = ({ record }: TabContentProps) => {
  return <Group style={{ width: '100%' }}>
    <JsonCard json={JSON.stringify(record, undefined, 2)} />
  </Group>;
}

export default ({ item }: Props) => {
  return <TableRow<any> resource="webhook_log" id={item.id}>
    {(record) => <TabContent record={record} />}
  </TableRow>;
}
