import { Extension } from '@tiptap/core';
import { Plugin } from '@tiptap/pm/state';

import tokenMatcher from './TokenMatcher';

export type Options = {
  tokens: string[],
}

export const TokenIndicator = Extension.create<Options>({
  name: 'tokenIndicator',

  addOptions() {
    return {
      tokens: [],
    }
  },

  addProseMirrorPlugins() {
    const tokens = this.options.tokens;

    return [
      new Plugin({
        state: {
          init(_, { doc }) {
            return tokenMatcher(doc, tokens);
          },
          apply(tr, oldState) {
            return tr.docChanged ? tokenMatcher(tr.doc, tokens) : oldState;
          },
        },
        props: {
          decorations(state) {
            return this.getState(state);
          },
        },
      }),
    ];
  },
});
