import { IResourceComponentsProps } from "@refinedev/core";
import { IFeedbackListResponse } from '@interfaces';
import { FilterButtonGroup } from '@components/filters/FilterButtonGroup';
import { FilterSelect } from '@components/filters/FilterSelect';
import { FilterBar } from '@components/filters/FilterBar';
import { FeedbackTable } from './table/FeedbackTable';
import { TableFilterProvider } from '@components/table/TableFilter.context';
import { Feature } from 'flagged';
import { FilterText } from '@components/filters/FilterText';

export function FeedbackList({ initialData }: IResourceComponentsProps<IFeedbackListResponse>) {
  const options = [
    { label: 'Detractors', value: ['0', '6'] },
    { label: 'Neutral', value: ['7', '8'] },
    { label: 'Promoters', value: ['9', '10'] },
    { label: 'All', value: null }
  ];

  const quickFilters = <>
    <FilterButtonGroup name="rate" options={options} />
  </>;

  const filters = <>
    <Feature name="admin">
      <FilterSelect name="owners" label="Owners" route="owners" />
    </Feature>
    <FilterText name="tag_identifier" label="Tag ID" />
    {/* Doesn't really work that well with the FilterButterGroup of the same name */}
    {/*<FilterDualRange name="rate" label="Rating" min={0} max={10} />*/}
  </>;

  return <TableFilterProvider>
    <FilterBar quickFilters={quickFilters} filters={filters} />
    <FeedbackTable initialData={initialData} syncWithLocation={true} />
  </TableFilterProvider>;
}
