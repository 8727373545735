import { Box, Center, Drawer, Group, Loader, Title } from '@mantine/core';
import { CustomerSearchLayout } from '@components/ui/drawers/Requests/CustomerSearchLayout';
import { HelpRequestForm } from '@components/ui/drawers/Requests/HelpRequest/HelpRequestForm';
import { useRequestState } from '@components/ui/drawers/Requests/RequestState.context';
import { CreateCustomer } from '@components/ui/drawers/Requests/CreateCustomer';
import { RefundRequestForm } from '@components/ui/drawers/Requests/RefundRequest/RefundRequestForm';
import { useRefundMethods } from '@components/hooks/useRefundMethods';
import { usePaymentMethods } from '@components/hooks/usePaymentMethods';
import { LinkRequestForm } from '@components/ui/drawers/Requests/LinkRequest/LinkRequestForm';

export const RequestLayout = () => {
  const { customer, type } = useRequestState();

  const { data: refundMethods, isLoading: refundMethodsLoading } = useRefundMethods();
  const { data: paymentMethods, isLoading: paymentMethodsLoading } = usePaymentMethods();

  return <>
    <Group mb="xs" justify="space-between">
      { type === 'help' && <Title order={5}>New Help Request</Title>}
      { type === 'refund' && <Title order={5}>New Refund Request</Title>}
      { type === 'link' && <Title order={5}>New Request Link</Title>}

      <Drawer.CloseButton />
    </Group>

    <Box hidden={!!customer}>
      <CustomerSearchLayout />
    </Box>

    { customer && !customer?.id && <CreateCustomer />}

    { customer?.id && type === 'help' && <HelpRequestForm />}
    { customer?.id && type === 'link' && <LinkRequestForm />}
    { customer?.id && type === 'refund' && <>
      { (refundMethodsLoading || paymentMethodsLoading) && <>
        <Center><Loader /></Center>
      </>}

      { refundMethods?.data && paymentMethods?.data && <>
        <RefundRequestForm refundMethods={refundMethods?.data.filter(v => v.is_active) ?? []}
                           paymentMethods={paymentMethods?.data.filter(v => v.is_active && v.type !== 'PayRange') ?? []} />
      </>}
    </>}
  </>;
};
