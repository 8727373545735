import { RichTextEditor, useRichTextEditorContext } from '@mantine/tiptap';
import { IconArrowForwardUp } from '@tabler/icons-react';

export function RedoControl() {
  const { editor } = useRichTextEditorContext();

  return (
    <RichTextEditor.Control title="Redo">
      <IconArrowForwardUp strokeWidth={1} size={18} onClick={() => editor.commands.redo()} />
    </RichTextEditor.Control>
  );
}
