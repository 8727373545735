import { IResourceComponentsProps } from "@refinedev/core";
import { ITagResponse } from '@interfaces';
import {
  Card,
  Group,
  Loader,
  Select,
  Stack,
  TextInput,
  Text,
  Title,
  Grid,
  Box,
  Input,
  ActionIcon, Tooltip
} from '@mantine/core';
import { Feature } from 'flagged';
import * as Yup from 'yup';
import { useForm, yupResolver } from '@mantine/form';
import { useIdentity } from '@components/data/Identity.context';
import dayjs from 'dayjs';
import { useOwnerOptions, useTagTypeOptions } from '@components/hooks';
import { ResourceForm } from '@components/ui/form/ResourceForm';
import { TagTypePopover } from './form/TagTypePopover';
import { TextareaAutosize } from '@components/ui/TextareaAutosize';
import { useState } from 'react';
import { IconArrowsSplit2, IconX } from '@tabler/icons-react';
import { CallOut } from '@components/ui/CallOut';

export function TagEdit({ initialData: record }: IResourceComponentsProps<ITagResponse>) {
  const { identity } = useIdentity();
  const [hasAlias, setHasAlias] = useState(record?.data.asset_id !== record?.data.identifier);

  const { data: owners, isLoading: isOwnersLoading } = useOwnerOptions();
  const { data: tagTypes, refetch, isLoading: isTagTypesLoading } = useTagTypeOptions();

  const schema = Yup.object().shape({
    roles: Yup.array(),
    owner_id: Yup.mixed().nullable().when('roles', {
      is: (roles) => roles.includes('Admin'),
      then: (schema) => schema.required('Assign an owner')
    }),
    tag_type_id: Yup.string().required('Text is required'),
    name: Yup.string().required('Text is required'),
    account_name: Yup.string(),
    identifier: Yup.string().when({
      is: () => hasAlias,
      then: (schema) => schema.required('Text is required'),
    }),
    asset_id: Yup.string().required('Text is required'),
    location_id: Yup.string(),
    location_name: Yup.string(),
    address: Yup.string().required('Enter a location'),
    description: Yup.string(),
  });

  const form = useForm({
    validate: yupResolver(schema),
    initialValues: {
      roles: identity.roles,
      owner_id: record?.data.owner_id.toString() ?? null,
      tag_type_id: record?.data.tag_type?.id.toString() ?? '',
      name: record?.data.name ?? '',
      account_name: record?.data.account_name ?? '',
      identifier: record?.data.identifier ?? '',
      asset_id: record?.data.asset_id ?? '',
      location_id: record?.data.location_id ?? '',
      location_name: record?.data.location_name ?? '',
      address: record?.data.address.formatted_address || record?.data.address.name || '',
      description: record?.data.description ?? '',
    },
  });

  const onTagTypeCreated = async (id) => {
    await refetch();

    form.setFieldValue('tag_type_id', id);
  };

  return <ResourceForm form={form}>
    <Group mb="lg">
      <Title order={2}>Equipment</Title>
    </Group>

    <Card shadow="sm" radius="sm">
      <Card.Section withBorder p="md">
        <Stack>
          {record?.data.integration && <>
            <CallOut>
              <Text size="sm">
                The details for this equipment is managed by your <b>{record.data.integration.platform}</b> account.
                Any changes will be synced every 12 hours.
              </Text>
              <Text size="sm" mt="sm">
                <i>Last updated: {dayjs(record.data.updated_at).format('D MMM \'YY h:mma')}.</i>
              </Text>
            </CallOut>
          </>}

          <Grid gutter="sm">
            <Feature name="admin">
              <Grid.Col span={{ md: 6 }}>
                <Select
                  label="Assign to owner"
                  placeholder="Select an owner..."
                  searchable
                  leftSection={isOwnersLoading && <Loader size="xs" />}
                  data={owners?.data ?? []}
                  disabled={!!record?.data.integration}
                  { ...form.getInputProps('owner_id') }
                />
              </Grid.Col>
            </Feature>

            <Grid.Col span="auto">
              <Group align="end" gap="5">
                <Select
                  label="Type"
                  placeholder="Select a type..."
                  data={tagTypes?.data ?? []}
                  leftSection={isTagTypesLoading && <Loader size="xs" />}
                  disabled={!!record?.data.integration}
                  flex={1}
                  { ...form.getInputProps('tag_type_id') }
                />

                <Box mb={form.errors.tag_type_id ? 20 : 0}>
                  <TagTypePopover onCreated={onTagTypeCreated} />
                </Box>
              </Group>
            </Grid.Col>

            <Grid.Col span={{ md: 6 }}>
              <TextInput label="Name" { ...form.getInputProps('name') } disabled={!!record?.data.integration} />
            </Grid.Col>

            <Grid.Col span={{ md: 12 }}>
              <TextInput label="Account Name" { ...form.getInputProps('account_name') } disabled={!!record?.data.integration} />
            </Grid.Col>

            <Grid.Col span={{ xs: 6, sm: hasAlias ? 4 : 6, md: hasAlias ? 4 : 3 }}>
              <Group mt={3} align="top" justify="space-between">
                <Input.Label>{hasAlias ? 'Asset ID' : 'Asset / Tag ID'}</Input.Label>
                { !hasAlias && <>
                  <Tooltip label="Enter a different Tag ID" withArrow>
                    <ActionIcon variant="default" size="sm">
                      <IconArrowsSplit2 size={18} strokeWidth={1.5} onClick={() => setHasAlias(true)} />
                    </ActionIcon>
                  </Tooltip>
                </>}
              </Group>
              <TextInput { ...form.getInputProps('asset_id') } disabled={!!record?.data.integration} />
            </Grid.Col>

            { hasAlias && <>
              <Grid.Col span={{ xs: 6, sm: 4, md: 4 }}>
                <Group mt={3} align="top" justify="space-between">
                  <Input.Label>Tag ID</Input.Label>
                  { hasAlias && <>
                    <Tooltip label="Change back to Asset / Tag ID" withArrow>
                      <ActionIcon variant="default" size="sm">
                        <IconX size={18} strokeWidth={1.5} onClick={() => {
                          setHasAlias(false);
                          form.setFieldValue('identifier', form.values.asset_id);
                        }} />
                      </ActionIcon>
                    </Tooltip>
                  </>}
                </Group>
                <TextInput { ...form.getInputProps('identifier') } />
              </Grid.Col>
            </>}

            <Grid.Col span={{ xs: 6, sm: hasAlias ? 4 : 6, md: hasAlias ? 4 : 3 }}>
              <TextInput label="Location ID" { ...form.getInputProps('location_id') } disabled={!!record?.data.integration} />
            </Grid.Col>

            <Grid.Col span={{ md: 6 }}>
              <TextInput label="Location Name" { ...form.getInputProps('location_name') } disabled={!!record?.data.integration} />
            </Grid.Col>

            <Grid.Col>
              <TextInput label="Location" { ...form.getInputProps('address') } disabled={!!record?.data.integration} />
            </Grid.Col>

            <Grid.Col>
              <TextareaAutosize label="Description" { ...form.getInputProps('description') } disabled={!!record?.data.integration} />
            </Grid.Col>
          </Grid>
        </Stack>
      </Card.Section>

      <Card.Section px="md" py="sm">
        <Group justify="right">
          <ResourceForm.CancelButton />
          <ResourceForm.SubmitButton />
        </Group>
      </Card.Section>
    </Card>
  </ResourceForm>
}
