import { ReactNode } from 'react';
import { Loader, Menu } from '@mantine/core';

type Props = {
  label: string;
  disabled?: boolean;
  isLoading?: boolean;
  onClick: () => void;
  icon: ReactNode;
};

export const RequestAction = ({ label, disabled, isLoading, onClick, icon }: Props) => {
  return <Menu.Item
    color="blue"
    disabled={disabled}
    onClick={() => onClick()}
    leftSection={isLoading ? <Loader size={16} /> : icon}
  >
    { label }
  </Menu.Item>
}
