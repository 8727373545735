import { EuiBasicTableColumn } from '@elastic/eui';
import { IFeedbackListItem } from '@interfaces';
import { Text } from '@mantine/core';
import { RatingIndicator } from '@components/ui/RatingIndicator';
import { DateTime } from '@components/ui/DateTime';
import { CustomerPanelTrigger } from '@components/ui/CustomerPanelTrigger';

export default [
  {
    field: 'owner_name',
    name: 'Owner',
    width: '150',
    sortable: true,
    truncateText: true,
    permissions: ['admin']
  },
  {
    field: 'customer_name',
    name: 'Name',
    width: '220',
    sortable: true,
    truncateText: true,
    render: (_name, record) => <CustomerPanelTrigger customer={record} />,
  },
  {
    field: 'reason',
    name: 'Reason',
    truncateText: true,
    render: (reason) => reason ? <Text truncate="end">{ reason }</Text> : <Text c="dimmed">&mdash;</Text>
  },
  {
    field: 'rate',
    name: 'Rate',
    width: '155',
    sortable: true,
    render: (rate) => <RatingIndicator rating={rate} />
  },
  {
    field: 'created_at',
    name: 'Submitted',
    width: '165',
    sortable: true,
    dataType: 'date',
    render: (date) => <DateTime value={date} />
  },
] as EuiBasicTableColumn<IFeedbackListItem>[];
